import { Button } from '@theme-ui/components'
import styled, { css } from 'styled-components'

export const MintButton = styled(Button)<{
  primary?: boolean
  isConnected?: boolean
  backgroundColor: string
  color: string
}>`
  ${({ backgroundColor, color }) => css`
    background-color: ${backgroundColor};

    color: ${color};
  `}
  ${({ primary, isConnected }) => css`
    max-height: 3.725rem;
    width: 16.875rem !important;
    height: auto;
    box-sizing: border-box;
    font-size: 1.25rem;
    padding: 1rem 2.25rem;
    margin-top: 3rem;
    font-family: 'Prayuth Light';
    font-weight: normal;
    letter-spacing: 0.4px;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 16rem;
  `}
`

export const ActionButton = styled(Button)`
  width: 5.375rem !important;
  height: 5.125rem;
  background: rgba(24, 38, 43, 1);
  border-radius: 0 !important;
`
