import styled from 'styled-components'
import { Box } from '@theme-ui/components'

export const Marquee = styled(Box)`
  white-space: nowrap;
  position: absolute;
  animation: marquee 25s linear infinite;
  will-change: transform;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  overflow: visible !important;
  @keyframes marquee {
    0% {
      left: 1%;
    }
    100% {
      left: -1100px;
    }
  }
`
export const Wrapper = styled(Box)`
  font-size: 1.25rem;
  height: 5.143rem;
  width: 100%;
  margin: 20px auto;
  position: relative;
  flex-direction: row;
  display: flex;
  align-items: center;
`
export const MarqueeContainer = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #f6f7f1;
  align-items: start;
  position: relative;
  height: 100%;

  @media screen and (max-width: 40rem) {
    #abt-more {
      display: none;
    }
  }
`

export const SpanWrapper = styled.div`
  display: flex;
  span {
    margin-left: 2.5rem;
    font-family: Prayuth Regular;
  }
`
export const ImgContainer = styled(Box)`
  // width: 26vw;
  height: auto;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 64rem) {
    width: 100%;
    margin-bottom: 2rem;
  }
`
