import { useCallback, useState } from 'react'
import ReactPlayer from 'react-player'
import { Box, Flex, Text } from 'theme-ui'
import { ON_ERROR_IMAGES } from '../../../utils/constants/Passes'
import { PassInput, PassText, CounterButton, FounderBox } from '../Exchange.styles'

type PassItemProps = {
  id?: string | any
  video?: any
  title?: string | any
  total?: any
  number?: number | any
  onchangeNumber?: any
  setCollectorPassQty?: any
  setFounderPassQty?: any
}

const PassItem = ({
  id,
  video,
  title,
  total,
  setCollectorPassQty,
  setFounderPassQty,
}: PassItemProps) => {
  const [number, setNumber]: any = useState(0)
  const [onVideoError, setVideoError]: any = useState(false)

  const onchangeNumber = useCallback(
    number => {
      if (number <= total) {
        setNumber(number)
        if (id === 'founder') {
          setFounderPassQty(number)
        } else {
          setCollectorPassQty(number)
        }
      }
    },
    [id, setNumber, total, setFounderPassQty, setCollectorPassQty]
  )

  const handleDetectError = useCallback(() => setVideoError(true), [setVideoError])

  return (
    <Flex sx={{ width: '100%', flexDirection: 'column', marginBottom: '1.5rem' }}>
      <Flex>
        <FounderBox>
          <Box>
            {!onVideoError ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                id="video-id"
                className="react-player"
                width="100%"
                height="100%"
                onError={({ type }) => (type === 'error' ? handleDetectError() : null)}
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img alt={id} className="react-player" src={ON_ERROR_IMAGES[id]} />
            )}
          </Box>
          <PassText
            sx={{
              display: ['none!important', 'flex!important'],
              flexDirection: 'column',
              py: '1rem',
              pl: '1rem',
            }}
          >
            <Text variant="passTitle">{title}</Text>
            <Text variant="passTitle" sx={{ fontSize: 'clamp(0.725rem, 1.1vw, 1.125rem)' }}>
              {total} total, {number} selected
            </Text>
          </PassText>
        </FounderBox>
        <Box
          sx={{
            display: 'flex',
            minWidth: 'fit-content',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            marginX: ['1rem', '0'],
          }}
        >
          <CounterButton onClick={() => onchangeNumber(number > 0 && parseInt(number) - 1)}>
            -
          </CounterButton>
          <PassInput
            type="number"
            value={number}
            onChange={({ target }: any) => onchangeNumber(target.value)}
          />
          <CounterButton onClick={() => onchangeNumber(parseInt(number) + 1)}>+</CounterButton>
        </Box>
      </Flex>
      <PassText
        sx={{
          display: ['flex!important', 'none!important'],
          flexDirection: 'column',
          py: '1rem',
          pl: '1rem',
        }}
      >
        <Text variant="passTitle">{title}</Text>
        <Text variant="passTitle" sx={{ fontSize: 'clamp(0.725rem, 1.1vw, 1.125rem)' }}>
          {total} total, {number} selected
        </Text>
      </PassText>
    </Flex>
  )
}
export { PassItem }
