import styled, { css } from 'styled-components'

export const MintSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.813rem;
  & > :not(:last-child) {
    margin-right: 0.625rem;
  }
`

export const Amount = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.375rem;
  border: 3px solid #000000;
  box-sizing: border-box;
  color: #000;
  font-family: 'Prayuth';
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;

  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`

export const MintLabel = styled.button<{ disabled: boolean }>`
  ${({ disabled }) => css`
    cursor: ${!disabled ? 'pointer' : 'none'};
    background: ${!disabled ? '#91bbbe' : '#a5adad'};
  `}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.375rem;
  height: 3.875rem;

  font-family: 'Prayuth Light';
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;

  text-align: center;
  letter-spacing: 0.4px;

  color: #000000;
  border: none;
  margin-bottom: 1.688rem;
  text-transform: uppercase;
`

export const TotalETH = styled.label`
  font-family: 'Prayuth Light';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 37px;
  /* or 186% */

  text-align: center;
  text-transform: uppercase;

  color: #000000;
`
export const ConnectWallet = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17.188rem;
  height: 3.875rem;
  background: #91bbbe;

  font-family: 'Prayuth Light';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 106% */

  text-align: center;
  letter-spacing: 0.4px;

  color: #000000;

  border: none;
  cursor: pointer;

  margin: 2.188rem auto 8rem;
`

export const LinkProfile = styled.button`
  display: block;
  margin: 3rem auto;
  width: 17.375rem;
  height: 3.875rem;
  color: #000;
  padding: 1.25rem 3.875rem;

  font-family: 'Prayuth Light';
  font-size: 20px;
  line-height: 21px;
  cursor: pointer;
  /* Grey */

  background: #91bbbe;
  text-decoration: none;
  border: none;
  &:focus-visible {
    outline: none;
  }
`
export const FormSection = styled.form`
  height: 4rem;
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  input::placeholder {
    color: #000;
  }
  input:focus-visible {
    outline: 0px solid black;
  }
`
export const EmailInput = styled.input`
  width: 50%;
  border: 2px solid #000;
  font-family: 'Prayuth Regular';
  font-size: 1rem;
  text-indent: 2rem;
  @media only screen and (max-width: 40em) {
    text-indent: 0.5rem;
    width: 75%;
    &::placeholder {
      font-size: 0.825rem;
    }
  }
`
export const SubmitButton = styled.button`
  width: 25%;
  border: 2px solid #000;
  background: #000;
  color: #fff;
  font-family: 'Prayuth Regular';
  font-size: 1.25rem;
  @media only screen and (max-width: 40em) {
    font-size: 1rem;
  }
`
