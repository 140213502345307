import { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import styled, { css } from 'styled-components'
import { Button, Label } from 'theme-ui'
import avatar from '../../../assets/avatar.png'
import { ReactComponent as CloseIcon } from '../../../assets/nomad/header/close_icon.svg'
import { ReactComponent as Logo } from '../../../assets/nomad/header/header_logo.svg'
import { ReactComponent as MenuIcon } from '../../../assets/nomad/header/menu_icon.svg'
import useContract from '../../../hooks/useContract'
import UseWeb3Modal from '../../../hooks/UseWeb3Modal'
import theme from '../../../theme'
import { MENU_IMAGE_ITEMS, NAV_MENU_ITEMS_V2 } from '../../../utils/constants/navigation'
import {
  CloseButtonWrapper,
  IconItem,
  Item,
  Menu,
  MenuLink,
  MenuWrapper,
  MintButtonWrapper,
  Navbar,
  ScrollLinkWrapper,
  SocialsContainer,
  User,
} from './HeaderStyles'

const WalletButton = styled(Button)<{ isOnline: any }>`
  ${({ isOnline }) => css`
    transition: transform 0.1s ease, box-shadow 0.1s ease;

    span {
      display: flex;
      flex-direction: column;
    }
    sub {
      font-size: 0.625rem;
    }
    ${!isOnline &&
    ` &:hover {
        color: #F6F7F1 !important;
        box-shadow: 0.2rem 0.2rem 0 #F6F7F1;
        transform: translate(-0.2rem, -0.2rem);
      }`}
  `}
`

const Header = () => {
  const history = useHistory()
  let location = useLocation()

  const { user, isAuthenticated, resetAll } = useContract()
  const [isOpen, setIsOpen] = useState(false)

  const isOnline = isAuthenticated && user

  const { connect, disconnectWallet } = UseWeb3Modal()

  const handleOpen = useCallback(() => setIsOpen(prevState => !prevState), [setIsOpen])

  const handleConnect = useCallback(async () => {
    if (isOnline) {
      await resetAll!()
      return await disconnectWallet!()
    }

    setIsOpen(false)
    try {
      await connect!()
    } catch (e) {
      console.log('error', e)
    } finally {
      //console.log('finally')
    }
  }, [connect, isOnline, disconnectWallet, resetAll])

  return (
    <Navbar
      theme={theme}
      sx={{
        padding: ['1.75rem 1.5rem 0', '2.25rem 3rem 0'],
        justifyContent: ['space-between'],
        alignItems: 'center',
      }}
    >
      <Label
        sx={{
          width: 'fit-content',
          cursor: 'pointer',
        }}
        onClick={() => window.open('https://nomadblvd.io', '_blank')}
      >
        <Logo className="header-logo color-change" />
      </Label>
      {location.pathname !== '/profile' && (
        <WalletButton
          isOnline={isOnline}
          variant="nav"
          sx={{
            display: ['none', 'none', 'none', 'inline-block'],
            minWidth: '17rem',
            fontSize: 'clamp(1rem, 1.05vw, 1.25rem)',
            color: isOnline ? '#000' : '#F6F7F1',
            padding: '1.25rem 2.5rem',
            margin: '0 2.5rem 0 auto',
            alignSelf: ['flex-start', 'auto', 'auto'],
            fontFamily: 'Prayuth Light',
            border: '0.5px solid #F6F7F1',
            borderRadius: 0,
            backgroundColor: isOnline
              ? '#F6F7F1'
              : location.pathname !== '/'
              ? '#000'
              : 'transparent',
            cursor: 'pointer',
          }}
          onClick={handleConnect}
        >
          {!isOnline && <span>CONNECT WALLET</span>}

          {isOnline && (
            <span>
              CONNECTED <br /> <sub>(click to disconnect)</sub>
            </span>
          )}
        </WalletButton>
          )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {' '}
        {isOnline && (
          <User onClick={() => history.push('/profile')} src={avatar} />
        )}
        <Menu onClick={handleOpen}>
          <MenuIcon className="menu-icon color-change" />
        </Menu>
      </div>

      <MenuWrapper isOpen={isOpen}>
        <CloseButtonWrapper>
          <CloseIcon onClick={handleOpen} className="close" />
        </CloseButtonWrapper>

        {NAV_MENU_ITEMS_V2.map(({ label }) => (
          <Item key={`nav-item_${label}`}>
            <ScrollLinkWrapper
              className="link"
              activeClass="active"
              to={label}
              spy={true}
              smooth={true}
              offset={0}
              duration={750}
              style={{ height: '100%' }}
              onSetActive={() => setIsOpen(false)}
            >
              <div onClick={() => window.open(`https://nomadblvd.io/#${label}`, '_blank')}>
                <MenuLink
                  hasHover
                  theme={theme}
                  variant="nav"
                  id={`menu-item_${label}`}
                  onClick={handleOpen}
                >
                  {label}
                </MenuLink>
              </div>
            </ScrollLinkWrapper>
          </Item>
        ))}
        <Item>
          <ScrollLinkWrapper
            className="link"
            activeClass="active"
            to="/"
            spy={true}
            smooth={true}
            offset={0}
            duration={750}
            style={{ height: '100%' }}
            onSetActive={() => setIsOpen(false)}
          >
            <div onClick={() => window.open("https://shop.nomadblvd.io/", "_blank")}>
              <MenuLink
                hasHover
                theme={theme}
                variant="nav"
                onClick={handleOpen}
              >
                SHOP
              </MenuLink>
            </div>
          </ScrollLinkWrapper>
        </Item>
        <MintButtonWrapper>
          {location.pathname !== '/profile' && (
            <WalletButton
              isOnline={isOnline}
              variant="nav"
              sx={{
                display: ['inline-block', 'none', 'none', 'inline-block'],
                minWidth: ['15rem', '17rem'],
                fontSize: 'clamp(0.825rem, 1.05vw, 1.25rem)',
                color: isOnline ? '#000' : '#F6F7F1',
                padding: '1.25rem 2.5rem',
                alignSelf: ['flex-start', 'auto', 'auto'],
                fontFamily: 'Prayuth Light',
                border: '0.5px solid #F6F7F1',
                borderRadius: 0,
                backgroundColor: isOnline ? '#F6F7F1' : '#000',
                cursor: 'pointer',
              }}
              onClick={handleConnect}
            >
              {!isOnline ? 'CONNECT WALLET' : 'CONNECTED (click to disconnect)'}
            </WalletButton>
          )}
        </MintButtonWrapper>
        <SocialsContainer>
          {MENU_IMAGE_ITEMS.map(({ alt, url, link }, index) => (
            <MenuLink onClick={() => window.open(link, '_blank')} key={`nav-img${index}`}>
              <IconItem alt={alt} src={url} />
            </MenuLink>
          ))}
        </SocialsContainer>
      </MenuWrapper>
    </Navbar>
  )
}

export { Header }
