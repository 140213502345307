import styled from 'styled-components'

export const ModalBlock = styled.div`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  opacity: 1;
`

export const ModalOverlay = styled.a`
  background: #000;
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.9;
`

export const ModalClose = styled.a`
  float: right !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Prayuth';
`

export const ModalContainer = styled.div`
  background: #f6f7f4;
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  max-width: 850px;
  width: 100%;
  animation: slide-down 0.2s ease 1;
  z-index: 1;
  box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  padding: 15px 5px;
  background-color: #000;
  width: 100%;
`

export const ModalBody = styled.div`
  overflow-y: auto;
  position: relative;
  margin-bottom: 3rem;
  background-color: #f6f7f4;
`

export const ModalFooter = styled.div`
  text-align: center;
  color: #000;
`

export const Button = styled.button`
  background: #7b2cbf;
  color: white;
  font-size: 1em;
  margin: 10px;
  padding: 5px 10px;
  border: 2px solid #7b2cbf;
  border-radius: 3px;
  cursor: pointer;
`
