import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Flex } from 'theme-ui'
import { ScrollYAnimation } from '../../utils/scrollYAnimation'
import Artists from '../sectionsV2/Artists/Artists'
import Benefits from '../sectionsV2/Benefits/Benefits'
import Boulevard from '../sectionsV2/Boulevard/Boulevard'
import Collection from '../sectionsV2/Collection/Collection'
import FooterSection from '../sectionsV2/FooterSection/FooterSection'
import GivingBack from '../sectionsV2/GivingBack/GivingBack'
import HomeSection from '../sectionsV2/HomeSection/HomeSection'
import Nomads from '../sectionsV2/Nomads/Nomads'
import RoadmapSection from '../sectionsV2/RoadmapSection/RoadmapSection'
import TeamSection from '../sectionsV2/TeamSection/TeamSection'

const Container = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  background: #000;
  height: 5rem;
  @media screen and (max-width: 64em) {
    height: 14rem;
  }
`

const Login = () => {
  const toesImgRef = useRef<any>()
  useEffect(() => {
    AOS.init()
    AOS.refresh()
    ScrollYAnimation(toesImgRef)
  }, [])

  return (
    <Flex sx={{ justifyContent: 'center', flexDirection: 'column', width: '100%', height: '100%' }}>
      <HomeSection />
      <Boulevard />
      <Nomads />
      <Collection />
      <Artists />
      <Benefits />
      <RoadmapSection />
      <GivingBack />
      <TeamSection />
      <Container>
        <FooterSection />
      </Container>
    </Flex>
  )
}

export { Login }
