import { Box, Flex, Grid, Label } from '@theme-ui/components'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components'
import { URL_ADDRESS } from '../../../utils'
import { MENU_IMAGE_ITEMS } from '../../../utils/constants/navigation'
import { IconItem, MenuLink } from '../../sectionsV2/HeaderSection/HeaderStyles'

const kingTide = 'https://kingtide.com/'
const Container = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  max-height: 14rem;
  padding: 0 1rem;

  @media only screen and (min-width: 40em) {
    max-height: 8rem;
    padding: 0 2rem;
  }
  @media only screen and (min-width: 62em) {
    padding: 0 3rem;
  }
  @media only screen and (min-width: 80em) {
    padding: 0 3rem;
  }
`
const StyledGrid = styled(Grid)`
  height: 100%;
  min-height: 100%;
  align-content: center;
  place-items: center;
  border-top: 1px solid #000000;
`
const LinkLabel = styled.span<{ color: any }>`
  ${({ color }) => css`
    color: ${color ? color : '#9e9e9e'};
  `}
  font-size: 0.71rem;
  // color: #9e9e9e;
  &:hover {
    color: #e1602f;
    cursor: pointer;
  }
`
const FooterSection = ({ color = '#9e9e9e' }: any) => {
  const history = useHistory()

  return (
    <Container>
      <StyledGrid columns={[1, 1, 1, 3]} sx={{ display: ['none', 'grid'] }}>
        <Label
          sx={{
            placeContent: 'center',
            fontSize: '0.71rem',
            fontWeight: '500',
            color: color || '#9e9e9e',
            fontFamily: 'Prayuth Regular',
            whiteSpace: 'nowrap',
          }}
        >
          © Nomad Blvd. 2021 - By heromachine X{' '}
          <LinkLabel
            style={{ paddingLeft: '0.1rem', fontSize: ' 0.71rem', alignSelf: 'center' }}
            onClick={() => window.open(kingTide, '_blank')}
            color={color}
          >
            King Tide
          </LinkLabel>
        </Label>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          {MENU_IMAGE_ITEMS.map(({ alt, url, link }, index) => (
            <MenuLink
              sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
              onClick={() => window.open(link, '_blank')}
              key={`nav-img${index}`}
            >
              <IconItem alt={alt} src={url} />
            </MenuLink>
          ))}
        </Box>
        <Box
          sx={{
            placeContent: 'center',
            fontSize: '1rem',
            fontWeight: '500',
            color: '#9E9E9E',
            fontFamily: 'Prayuth Regular',
            whiteSpace: 'nowrap',
          }}
        >
          <LinkLabel
            style={{ borderRight: '1px solid #9e9e9e', padding: '0rem 0.3rem' }}
            onClick={() => window.open(URL_ADDRESS, '_blank')}
            color={color}
          >
            Smart Contract
          </LinkLabel>
          <LinkLabel
            style={{ borderRight: '1px solid #9e9e9e', padding: '0rem 0.3rem' }}
            onClick={() => history.push('/terms-of-service')}
            color={color}
          >
            Terms of Service{' '}
          </LinkLabel>
          <LinkLabel
            style={{ borderRight: '1px solid #9e9e9e', padding: '0rem 0.3rem' }}
            onClick={() => history.push('/terms-of-sale')}
            color={color}
          >
            Terms of Sale{' '}
          </LinkLabel>
          <LinkLabel
            style={{ padding: '0rem 0.3rem' }}
            onClick={() => history.push('/privacy-policy')}
            color={color}
          >
            Privacy Policy{' '}
          </LinkLabel>
        </Box>
      </StyledGrid>
      <Flex sx={{ display: ['flex', 'none'], flexDirection: 'column', paddingY: '2rem' }}>
        <StyledGrid columns={1}>
          <Box sx={{ display: 'flex', width: '100%', paddingBottom: '0.5rem' }}>
            {MENU_IMAGE_ITEMS.map(({ alt, url, link }, index) => (
              <MenuLink
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
                onClick={() => window.open(link, '_blank')}
                key={`nav-img${index}`}
              >
                <IconItem alt={alt} src={url} />
              </MenuLink>
            ))}
          </Box>

          <Box
            sx={{
              placeContent: 'center',
              fontSize: '1rem',
              fontWeight: '500',
              color: color || '#9E9E9E',
              fontFamily: 'Prayuth Regular',
              paddingBottom: '0.5rem',
              textAlign: 'center',
            }}
          >
            <LinkLabel
              style={{ borderRight: '1px solid #fff', padding: '0rem 0.3rem' }}
              onClick={() => window.open(URL_ADDRESS, '_blank')}
              color={color}
            >
              Smart Contract
            </LinkLabel>
            <LinkLabel
              style={{ borderRight: '1px solid #fff', padding: '0rem 0.3rem' }}
              onClick={() => history.push('/terms-of-service')}
              color={color}
            >
              Terms of service{' '}
            </LinkLabel>
            <LinkLabel
              style={{ borderRight: '1px solid #fff', padding: '0rem 0.3rem' }}
              onClick={() => history.push('/terms-of-sale')}
              color={color}
            >
              Terms of Sale{' '}
            </LinkLabel>
            <LinkLabel
              style={{ padding: '0rem 0.3rem' }}
              onClick={() => history.push('/privacy-policy')}
              color={color}
            >
              Privacy Policy{' '}
            </LinkLabel>
          </Box>
          <Label
            sx={{
              placeContent: 'center',
              fontSize: '0.875rem',
              fontWeight: '500',
              color: color || '#9E9E9E',
              fontFamily: 'Prayuth Regular',
              paddingBottom: '0.5rem',
            }}
          >
            © Nomad Blvd. 2021 - By heromachine X{' '}
            <LinkLabel
              style={{ paddingLeft: '0.1rem' }}
              onClick={() => window.open(kingTide, '_blank')}
              color={color}
            >
              King Tide
            </LinkLabel>
          </Label>
        </StyledGrid>
      </Flex>
    </Container>
  )
}

export default FooterSection
