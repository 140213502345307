import { Text } from '@theme-ui/components'
import { useEffect } from 'react'
import FooterSection from '../../components/sectionsV2/FooterSection/FooterSection'
import { Container, Title, Label, TextParagraph, Wrapper } from './TermsStyles'

const ServiceTerms = () => {
  useEffect(() => {
    const colorChange = document.querySelectorAll<HTMLElement>('.color-change')
    colorChange.forEach(function (el) {
      el.classList.add('black')
    })
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Container>
        <Title>TERMS OF SERVICE</Title>
        <Label>Last Modified: Dec. 8th 2021</Label>
        <TextParagraph>
          These Terms of Service, including any policies, rules and other terms that are expressly
          incorporated herein by reference (collectively, the “Terms”), set forth a legally binding
          agreement between you (“you” or “your”) and NOMAD BLVD, LLC (“we,” “us,” or “our”). These
          Terms govern your use of our website located at https://stage.nomadblvd.io/ (the “Site”),
          and any content, functionality or services made available on or through the Site,
          including, without limitation, the ability to sell, purchase, mint and display
          non-fungible tokens (each, an “NFT”) (collectively, the “Services”). Please read these
          Terms carefully before using the Site or any of the Services.
        </TextParagraph>
        <TextParagraph sx={{ fontWeight: 700 }}>
          ARBITRATION NOTICE: SECTION 22 (DISPUTE RESOLUTION) OF THESE TERMS CONTAINS A MUTUAL
          ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT WAIVES YOUR RIGHT TO A COURT HEARING
          AND JURY TRIAL. YOU AGREE THAT ANY AND ALL DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY
          BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
          LAWSUIT OR CLASS-WIDE ARBITRATION. PLEASE READ SECTION 22 (DISPUTE RESOLUTION) CAREFULLY.{' '}
        </TextParagraph>
        <Label bold>1. ACCEPTANCE OF TERMS</Label>
        <TextParagraph>
          By accessing or using the Site or the Services, or by clicking to accept these Terms when
          this option is made available to you, you accept and agree to be bound by these Terms
          (including, without limitation, our Terms of Sale, which shall govern all sales and
          purchases of NFTs via the Site, and which is hereby incorporated into and made a part of
          these Terms by this reference). In addition to these Terms, your access to and use of
          certain portions or aspects of the Site, or your ability to access and/or use certain
          Services, may require you to accept additional terms and conditions (collectively,
          “Additional Terms”). The Additional Terms are hereby incorporated and made a part of these
          Terms by this reference.
        </TextParagraph>
        <TextParagraph>
          THESE TERMS GOVERN YOUR ACCESS TO AND USE OF THE SITE AND THE SERVICES. BY USING THE SITE
          AND/OR ANY OF THE SERVICES, YOU EXPRESSLY AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT
          AGREE TO THESE TERMS, DO NOT ACCESS OR USE THE SITE OR THE SERVICES.
        </TextParagraph>
        <Label bold>2. AGE REQUIREMENTS</Label>
        <TextParagraph>
          You must be at least the age of majority in your state of residence, and fully able and
          competent to enter into and abide by the terms and conditions of these Terms, in order to
          access and use the Site and/or the Services. Individuals under the age of majority are not
          eligible to use the Site or the Services and may not submit any personal information to
          us. By accessing or using the Site and/or the Services, or by clicking to accept these
          Terms when this option is made available to you, you represent and warrant that you are at
          least the age of majority in your state of residence, are legally entitled to enter into
          these Terms, are legally able to enter into any and all purchase agreements with us and
          our partners, vendors, agents, and service providers, and have the right, authority and
          capacity to enter into and abide by the terms and conditions of these Terms.
        </TextParagraph>
        <Label bold>3. MODIFICATION TO THESE TERMS</Label>
        <TextParagraph>
          We will e-mail you or post a notification on the Site in the event of any material changes
          to these Terms. Such changes, whether in the form of modifications, additions, or
          deletions, shall be effective when specified in the relevant notification or, if the
          change is immaterial, immediately upon appearing on the Site. Please check these Terms
          periodically for changes. Your continued use of the Site and/or the Services following our
          posting of any changes to these Terms means that you accept and agree to those changes.{' '}
        </TextParagraph>
        <Label bold>4. PRIVACY POLICY</Label>
        <TextParagraph>
          Our Privacy Policy governs the processing of all personal data collected from you in
          connection with your use of the Site and/or the Services. The Privacy Policy and its terms
          and provisions are hereby incorporated and made a part of these Terms by this reference.
          You must agree to the Privacy Policy in order to use the Site and/or the Services.
        </TextParagraph>
        <Label bold>5. ACCESSIBILITY</Label>
        <TextParagraph>
          We are committed to helping those with disabilities access the Site and/or Services. We
          strive to provide an excellent online experience for all our guests – including those with
          sight, hearing, and other disabilities. If you have difficulty using or accessing any
          element of the Site or the Services or if you have any feedback regarding accessibility of
          the Site or the Services, please feel free to contact us at nft@nomadblvd.io.
        </TextParagraph>
        <Label bold>6. ACCOUNT ACCESS INFORMATION; ACCOUNTS</Label>
        <TextParagraph>
          In the event you are required to create an account to access any part of the Site and/or
          Services, you must treat your account information (including, without limitation, your
          username, password, and any other piece of information required as part of our security
          procedures) as confidential, and you must not disclose the foregoing to any other person
          or entity. You also acknowledge that your account is personal to you and agree not to
          provide any other person or entity with access to the Site and/or Services or portions of
          it using your username, password or other security information. Any unauthorized access to
          your account by third parties could result in the loss or theft of funds held in your
          account and any associated accounts, including your linked bank account(s) and credit
          card(s). You understand and agree that you will not hold us responsible for managing and
          maintaining the security of your account. You further understand and agree that we are not
          responsible (and you will not hold us responsible) for any funds lost in your account or
          any unauthorized access to or use of your account. You agree to notify us immediately of
          any unauthorized access to or use of your username or password or any other breach of
          security. You agree to be responsible for any use of the Site and/or Services or portions
          of it using your username, password, or other security information. We have the right to
          disable any account, username, password, or other identifier, whether chosen by you or
          provided by us, at any time, including if you have violated any provision of these Terms.
        </TextParagraph>
        <TextParagraph>
          To qualify and to be approved for certain types of accounts, you may be required to
          provide certain, sometimes personal, information, including, without limitation, as
          required to satisfy our applicable cryptocurrency and fiat KYC requirements. It is a
          condition of your use of an account that all such information you provide is correct,
          current, and complete.
        </TextParagraph>
        <Label bold>7. RESTRICTIONS ON USE</Label>
        <TextParagraph>
          You may use the Site, the Services and all associated content solely for your personal use
          and enjoyment. The Site, the Services, or any portion thereof, may not be reproduced,
          duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial
          purpose without our express written consent. You shall not attempt to modify,
          reverse-engineer, decompile, disassemble, or otherwise reduce or attempt to reduce to a
          human-perceivable form any of the source code used by us in providing the Site.
        </TextParagraph>
        <TextParagraph>
          To access parts of the Site or the Services, you may be asked to provide certain,
          sometimes personal, information. It is a condition of your use of the Site or the Services
          that all the information you provide on the Site or the Services is correct, current, and
          complete.
        </TextParagraph>
        <TextParagraph>
          You agree not to use any device, software, or routine to interfere with the proper
          functioning of the Site. In addition, when accessing or using the Site or the Services you
          may not:
        </TextParagraph>
        <TextParagraph>
          <li>
            Transmit any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar,
            pornographic, profane, or indecent information of any kind, including images and
            language;
          </li>
          <li>
            Transmit or solicit any information, software, or other material that violates or
            infringes upon the rights of others, including material that is an invasion of privacy
            or publicity rights; is protected by copyright, trademark, or other proprietary rights;
            or is a derivative work with respect thereto, without first obtaining permission from
            the owner or right holder;{' '}
          </li>
          <li>
            Transmit any information, software, or other material that contains a virus, trojan
            horse, time bomb, worm or other rogue programming or other harmful component;
          </li>
          <li>
            Use any software, tool, data, device, or other mechanism to navigate or search the Site,
            other than generally available browsers or a search engine provided by us;
          </li>
          <li>
            Use spiders, robots, data mining techniques or other automated devices or programs to
            catalog, download or otherwise reproduce, store or distribute any information or content
            available on the Site; or
          </li>
          <li>
            Frame or utilize framing techniques to enclose any aspect of the Site, including any
            trademark, logo, or other proprietary information (including, but not limited to,
            images, text, page layout, or form) without our express written consent.
          </li>
        </TextParagraph>
        <TextParagraph>
          Additionally, you are prohibited from violating or attempting to violate any security
          features of the Site, including, without limitation:{' '}
        </TextParagraph>
        <TextParagraph>
          <li>
            Accessing content or data not intended for you or logging onto a server that you are not
            authorized to access;
          </li>
          <li>
            Attempting to probe, scan, or test the vulnerability of the Site, or any associated
            system or network, or to breach security or authentication measures without proper
            authorization;
          </li>
          <li>
            Interfering or attempting to interfere with service to any visitor, host, or network,
            including, without limitation, by means of submitting a virus to the Site, overloading,
            “flooding,” “spamming,” “mail bombing,” or “crashing;”
          </li>
          <li>
            Violating security features that prevent or restrict use or copying of any content or
            enforce limitations on use of the Site or any content on the Site, including, without
            limitation, by use of any manual or automated software, devices, scripts bots, crawlers,
            spiders, data miners, scraping or other automatic access tools;{' '}
          </li>
          <li>
            Using the Site to send unsolicited e-mail, promotions or advertisements for products or
            services; or
          </li>
          <li>
            Forging any TCP/IP packet header or any part of the header information in any e-mail or
            posting.
          </li>
        </TextParagraph>
        <TextParagraph>
          Any violation of system or network security may subject you to civil and/or criminal
          liability.
        </TextParagraph>
        <Label bold>8. USER COVENANTS</Label>
        <TextParagraph>
          By accessing or using the Site and/or the Services, you agree to, acknowledge, and
          represent as follows:{' '}
        </TextParagraph>
        <TextParagraph>
          <li>
            You will comply with all applicable federal, state or local laws in using the Site
            and/or the Services, and you will not perform or fail to perform any act that you know
            or reasonably should know would place us or our affiliates in violation of any
            applicable law.{' '}
          </li>
          <li>
            You have the authority and capacity, under the laws of the state or jurisdiction in
            which you reside, to make the representations and warranties and be bound by the
            covenants provided herein.
          </li>
        </TextParagraph>
        <Label bold>9. COPYRIGHTS, TRADEMARKS, AND OTHER PROPRIETARY RIGHTS</Label>
        <TextParagraph>
          When accessing and using the Site and/or the Services, you agree to obey the law and to
          respect the intellectual property rights of others. You agree that you shall be solely
          responsible for any violations of any relevant laws and for any infringement of
          third-party rights caused by any Profile Content (as defined below) or related to any NFT
          that you provide or transmit to us or via the Site.
        </TextParagraph>
        <TextParagraph>
          As between you and us, all content on the Site and the Services (excluding the NFTs and
          Profile Content), including text, hidden text within our source code, trademarks,
          software, photos, video, images, graphics, music, audio-visual content, podcasts,
          recordings, sound, or any other digital media, is owned by us and/or our licensors and is
          subject to protection by patent, copyright, trademark, or other proprietary rights. In
          addition, the entire content of the Site is copyrighted as a collective work under the
          United States copyright laws, and we own the copyright in the selection, coordination,
          arrangement, and enhancement of such content. Any feedback you provide to us relating to
          the Site and/or the Services, shall be deemed to be non-confidential. We shall be free to
          use such information on an unrestricted basis.
        </TextParagraph>
        <TextParagraph>
          All trademarks, trade names, trade dress, logos, and service marks (collectively, the
          “Trademarks”) appearing on the Site and/or the Services are the property of their
          respective owners, including, in some instances, us and/or our partner companies. Nothing
          contained on the Site, the Services or these Terms serves to grant you, by implication or
          otherwise, a license or right to use any of the Trademarks or copyrights owned by us or by
          any third party.{' '}
        </TextParagraph>
        <TextParagraph>
          Except as expressly provided herein, you may not use, modify, create derivative works of,
          copy, redistribute, reproduce, publish, transmit, display, commercialize, or in any other
          way exploit any content or material from the Site or the Services without express written
          permission from us and, if applicable, the respective copyright owner. You acknowledge and
          agree that you do not acquire any ownership rights by accessing or using the Site and/or
          the Services.{' '}
        </TextParagraph>
        <Label bold>10. PROFILE CONTENT</Label>
        <TextParagraph>
          This Site allows you to create a profile where you can upload a profile picture or certain
          other content or information for your profile (collectively, “Profile Content”). You
          hereby grant to us and our affiliates, the royalty-free, perpetual, irrevocable,
          worldwide, transferable, non-exclusive right and license to use, reproduce, modify, adapt,
          publish, share, re-post, translate, create derivate works of, and display Profile Content,
          without payment, royalties, or other consideration to you, in connection with the
          operation of the Site, and providing you the Services.
        </TextParagraph>
        <TextParagraph>
          All Profile Content must comply with these Terms. You represent and warrant that you are
          the owner of or have the right to license Profile Content and that your Profile Content
          does not and will not infringe, misappropriate or violate the intellectual property or
          other proprietary rights of any third party. You must not send, upload, post or transmit
          to us any Profile Content to which you do not hold the necessary rights, or which violate
          or infringe upon the intellectual property or other proprietary rights of others.{' '}
        </TextParagraph>
        <Label bold>11. COPYRIGHTS, TRADEMARKS, AND OTHER PROPRIETARY RIGHTS</Label>
        <TextParagraph>
          You agree that we have the right, but not the obligation, to monitor, suspend, terminate,
          edit, disclose, refuse to post, or remove at any time, for any reason in our sole
          discretion, any material, content, and/or activity anywhere on the Site or the Services.
          In the event you post or provide content in violation of these Terms or to which you do
          not have adequate rights, we may suspend or terminate your access to or use of the Site or
          the Services. Notwithstanding this right, we do not and cannot review all materials
          submitted to the Site or the Services. If notified, we will investigate an allegation that
          content transmitted to us is in violation of these Terms and determine whether to have the
          communication removed. However, we assume no responsibility or liability arising from or
          relating to any actions or content transmitted by or between you or any third party within
          or outside of the Site or the Services, including, but not limited to, any error,
          defamation, libel, slander, omission, falsehood, obscenity, pornography, profanity,
          danger, or inaccuracy contained therein.{' '}
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ fontWeight: 700 }}>Digital Millennium Copyright Act</Text>
        </TextParagraph>
        <TextParagraph>
          We may, in appropriate circumstances, terminate the access of users who infringe or
          otherwise violate the rights of others. While we reserve the right to terminate the access
          of users who infringe or otherwise violate the rights of others in our sole discretion, if
          you are a Repeat Offender (as defined below), your access to the Site or the Services will
          be suspended or terminated. “Repeat Offender” shall mean any user of the Site or the
          Services against whom we receive three (3) or more Infringement Notifications (as defined
          below).{' '}
        </TextParagraph>
        <TextParagraph>
          If you believe that your work has been copied and is accessible on the Site or Services in
          a way that constitutes copyright infringement, you may notify us (each, an “Infringement
          Notification”) by providing the following information (as required by the Online Copyright
          Infringement Liability Limitation Act of the Digital Millennium Copyright Act (“DMCA”), 17
          U.S.C. sec. 512):{' '}
        </TextParagraph>
        <TextParagraph>
          <li>
            A physical or electronic signature of the person authorized to act on behalf of the
            owner of an exclusive right that is allegedly infringed;
          </li>
          <li>
            Identification of the copyrighted work claimed to have been infringed, or if multiple
            copyrighted works at the Site are covered by a single notification, a representative
            list of such works at the Site;
          </li>
          <li>
            Identification of the material that is claimed to be infringing or to be the subject of
            infringing activity and that is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit us to locate the material;
          </li>
          <li>
            Information reasonably sufficient to permit us to contact you, such as an address,
            telephone number, and if available, an e-mail address;
          </li>
          <li>
            A statement that you have a good faith belief that use of the material in the manner
            complained of is not authorized by you, your agent, or the law; and
          </li>
          <li>
            A statement that the information in the notification is accurate, and, under penalty of
            perjury, that you are authorized to act on behalf of the owner of an exclusive right
            that is allegedly infringed.{' '}
          </li>
        </TextParagraph>
        <TextParagraph>
          Please send the written communication to our copyright agent by e-mail and by mail to:
        </TextParagraph>
        <TextParagraph>
          Nomad Blvd
          <br />
          8748 Holloway Drive,
          <br />
          West Hollywood CA 90069 <br />
          Attn: DMCA Agent <br />
          Email: nft@nomadblvd.io
          <br />
          Email Subject: “DMCA Request”
        </TextParagraph>
        <Label bold>12. THIRD-PARTY SITES</Label>
        <TextParagraph>
          We may provide links and pointers to websites, goods, and/or services maintained, owned,
          or controlled by others (“Third-Party Sites”) that are not affiliated with us, that may be
          located in different countries and that may be subject to different regulatory and other
          legal requirements. We have not reviewed all of the Third-Party Sites linked to the Site
          or the Services and are not responsible for the content or services offered on such
          Third-Party Sites, including, but not limited to, any advertising, KYC processing, order
          processing and fulfilment, or payment terms related to such Third-Party Sites. Access to
          Third-Party Sites through the Site or the Services does not constitute an endorsement by
          us or any of our affiliates of any such Third-Party Sites, or the content or services
          offered by them. We have no responsibility or liability for these Third-Party Sites’
          independent policies or actions and are not responsible for the privacy practices of such
          Third-Party Sites. Complaints, claims, concerns, or questions regarding Third-Party Sites
          should be directed to the applicable third party.
        </TextParagraph>
        <TextParagraph>
          If we provide links to social media platforms, such as Facebook or Twitter, and if you
          choose to visit any such social media platforms through our links, please note that the
          personal information you post, transmit, or otherwise make available on or through such
          social media platforms may be viewed by the general public. We do not control any content
          or information made available on such social media platforms and we are not responsible
          for any third-party use of any such content or information, including, without limitation,
          personally identifiable information, that you have posted, transmitted, or otherwise made
          available on such social media platforms.
        </TextParagraph>
        <Label bold>13. UPDATES</Label>
        <TextParagraph>
          We will not be liable if, for any reason, all or part of the Site or the Services is ever
          unavailable. We reserve the right at any time and from time to time to modify or
          discontinue, temporarily or permanently, the Site or the Services, or any part thereof,
          with or without notice. We undertake no obligation to update, amend, or clarify
          information on the Site or the Services, except as required by law. No specified update or
          refresh date applied on the Site should be taken to indicate that all information on the
          Site or the Services has been modified or updated. Please remember when reviewing
          information on the Site or the Services that such information may not represent the
          complete information available on a subject. In addition, subsequent events or changes in
          circumstances may cause existing information on the Site or the Services to become
          inaccurate or incomplete.{' '}
        </TextParagraph>
        <TextParagraph>
          On occasion, information on the Site or the Services may contain errors. We reserve the
          right to, at any time without prior notice, correct any errors, inaccuracies, or
          omissions, and to change or update information.{' '}
        </TextParagraph>
        <Label bold>14. TERMS OF SALE</Label>
        <TextParagraph>
          Our Terms of Sale govern all sales and purchases of NFTs via the Site.
        </TextParagraph>
        <Label bold>15. RISKS</Label>
        <TextParagraph>
          Please note the following risks in accessing or using the Site and/or selling and
          purchasing NFTs:{' '}
        </TextParagraph>
        <TextParagraph>
          <li>
            The price and liquidity of blockchain assets, including NFTs, are extremely volatile and
            may be subject to large fluctuations. Fluctuations in the price of other digital assets
            could materially and adversely affect NFTs, which may also be subject to significant
            price volatility;{' '}
          </li>
          <li>
            Legislative and regulatory changes or actions at the state, federal, or international
            level may adversely affect the use, transfer, exchange, and value of NFTs;{' '}
          </li>
          <li>NFTs are not legal tender and are not backed by the government;</li>
          <li>
            Transactions in NFTs may be irreversible, and, accordingly, losses due to fraudulent or
            accidental transactions may not be recoverable;{' '}
          </li>
          <li>
            Some transactions in NFTs shall be deemed to be made when recorded on a public ledger,
            which is not necessarily the date or time that you initiated the transaction;{' '}
          </li>
          <li>
            The value of NFTs may be derived from the continued willingness of market participants
            to exchange fiat currency or digital assets for NFTs, which may result in the potential
            for permanent and total loss of value of a particular NFT should the market for that NFT
            disappear;{' '}
          </li>
          <li>
            The nature of NFTs may lead to an increased risk of fraud or cyber-attack, and may mean
            that technological difficulties may prevent the access to or use of your NFT; and
          </li>
          <li>
            Changes to Third-Party Sites (discussed in Section 12) may create a risk that your
            access to and use of the Site will suffer.
          </li>
        </TextParagraph>
        <TextParagraph>
          You agree and understand that you are solely responsible for determining the nature,
          potential value, suitability, and appropriateness of these risks for yourself, and that we
          does not give advice or recommendations regarding NFTs, including the suitability and
          appropriateness of, and investment strategies for, NFTs. You hereby assume all such risks
          and you hereby agree and understand that you access and use the Site and the Services at
          your own risk. For the avoidance of doubt, this brief Section does not disclose all of the
          risks associated with NFTs.
        </TextParagraph>
        <Label bold>16. DISCLAIMER OF WARRANTIES</Label>
        <TextParagraph>
          NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD
          MAINTAINED IN THE ETHEREUM NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY NFT
          OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT GUARANTEE THAT
          WE CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY NFT. WE FACILITATE TRANSACTIONS
          BETWEEN THE BUYER AND SELLER BUT ARE NOT A PARTY TO ANY AGREEMENT BETWEEN THE BUYER AND
          SELLER OF NFTS OR BETWEEN ANY USERS.
        </TextParagraph>
        <TextParagraph>
          WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR,
          ANY NFT, INCLUDING, BUT NOT LIMITED TO, ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I)
          ANY KIND OF FAILURE, ABNORMAL BEHAVIOR OF SOFTWARE (E.G., WALLET, CORRUPTED WALLET FILES,
          SMART CONTRACT), BLOCKCHAINS (INCLUDING THE ETHEREUM NETWORK) OR ANY OTHER FEATURES OF THE
          NFTS; (II) ANY COMMUNICATION FAILURES, DISRUPTIONS, ERRORS, DISTORTIONS OR DELAYS YOU MAY
          EXPERIENCE WHEN USING THE SITE, INCLUDING FAILURES TO EXECUTE BIDS, HOWEVER CAUSED; (III)
          USER ERROR, SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED
          ADDRESSES; (IV) CASUALTIES DUE TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO
          REPORT AT ALL) OF ANY ISSUES WITH THE ETHEREUM NETWORK INCLUDING FORKS, TECHNICAL NODE
          ISSUES OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT; (V) SERVER FAILURE, INTERNET
          FAILURE OR DATA LOSS; AND (VI) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING, WITHOUT
          LIMITATION, THE USE OF VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST
          THE SITE OR ANY NFTS.
        </TextParagraph>
        <TextParagraph>
          THE SITE AND THE SERVICES MAY PROVIDE YOU WITH CERTAIN INFORMATION, INCLUDING, WITHOUT
          LIMITATION, INFORMATION RELATED TO NFTS, CRYPTOCURRENCIES AND BLOCKCHAIN TECHNOLOGY. ALL
          SUCH INFORMATION IS PROVIDED FOR GENERAL INFORMATION PURPOSES ONLY AND ALL SUCH
          INFORMATION IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND YOU HEREBY EXPRESSLY
          ACKNOWLEDGE AND AGREE THAT ANY USE OF, OR RELIANCE ON, SUCH INFORMATION IS AT YOUR SOLE
          RISK.{' '}
        </TextParagraph>
        <TextParagraph>
          FURTHER, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SITE AND THE SERVICES IS
          AT YOUR SOLE RISK. THE SITE AND THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
          BASIS. WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE SITE OR THE
          SERVICES WILL MEET YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED, TIMELY, UNFAILINGLY SECURE,
          OR ERROR-FREE, THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE OR THE
          SERVICES WILL BE ACCURATE OR RELIABLE, THAT THE QUALITY OF ANY INFORMATION OR MATERIALS
          OBTAINED BY YOU THROUGH THE SITE OR THE SERVICES WILL MEET YOUR EXPECTATIONS, OR THAT ANY
          ERRORS IN THE SITE OR THE SERVICES WILL BE CORRECTED. YOUR SOLE REMEDY AGAINST US FOR
          DISSATISFACTION WITH THE SITE OR THE SERVICES IS TO STOP USING THE SITE OR THE SERVICES,
          AS APPLICABLE. THE FOREGOING LIMITATION OF RELIEF IS AN ESSENTIAL PART OF THE BARGAIN
          BETWEEN YOU AND US UNDER THESE TERMS. PLEASE NOTE THAT SOME JURISDICTIONS DO NOT ALLOW
          LIMITATIONS ON THE LENGTH OR SCOPE OF AN IMPLIED WARRANTY, SO THE ABOVE LIMITATIONS MAY
          NOT APPLY FULLY TO YOU.
        </TextParagraph>
        <Label bold>17. LIMITATION OF LIABILITY</Label>
        <TextParagraph>
          TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT SHALL WE AND/OR OUR
          SUCCESSORS, ASSIGNS, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, MEMBERS,
          EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, OR MANAGERS BE LIABLE FOR ANY INCIDENTAL, DIRECT,
          INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES,
          INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
          INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH
          DAMAGES ARE REASONABLY FORESEEABLE), RESULTING FROM (I) THE USE OR THE INABILITY TO USE
          THE SITE OR THE SERVICES; (II) ANY ACT OR OMISSION BY YOU THAT IS BASED (IN WHOLE OR IN
          PART) ON ANY INFORMATION, STATEMENT OR CONTENT THAT IS PROVIDED OR OTHERWISE MADE
          AVAILABLE TO YOU BY OR THROUGH THE SITE OR THE SERVICES; (III) UNAUTHORIZED ACCESS TO OR
          ALTERATION OF YOUR TRANSMISSIONS, PROFILE CONTENT OR OTHER DATA; OR (IV) STATEMENTS OR
          CONDUCT OF ANY THIRD PARTY ON THE SITE. IN NO EVENT SHALL THE COLLECTIVE LIABILITY OF US
          AND OUR SUCCESSORS, ASSIGNS, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS,
          MEMBERS, EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, AND MANAGERS TO YOU, FOR ANY AND ALL
          DAMAGES, LOSSES, AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE, EXCEED, IN
          THE AGGREGATE, ONE HUNDRED U.S. DOLLARS ($100). SOME JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OF CERTAIN WARRANTIES AND/OR THE LIMITATION OF LIABILITY FOR INCIDENTAL OR
          CONSEQUENTIAL DAMAGES. ACCORDINGLY, IN CERTAIN JURISDICTIONS, SOME OF THE ABOVE
          LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU; ALL OTHER PROVISIONS OF THESE TERMS REMAIN
          IN FULL FORCE AND EFFECT.
        </TextParagraph>
        <Label bold>18. INDEMNIFICATION</Label>
        <TextParagraph>
          You agree to indemnify, defend, and hold harmless us, our affiliates, our subsidiaries,
          and each of our and their respective shareholders, members, managers, directors, officers,
          employees, personnel, agents, successors and assigns from and against any and all claims,
          allegations, demands, actions, causes of action, lawsuits, investigations and proceedings
          (including any and all liability, damages, costs, expenses (including attorneys’ fees),
          settlements, fines, penalties and losses of any kind or nature whatsoever resulting from
          any of the foregoing) arising out of or in connection with: (i) your violation or breach
          of these Terms (including, without limitation, any violation or breach of the Terms of
          Sale); (ii) your use of the Site and/or the Services; (iii) your dispute with another
          user; (iv) your violation of any rights of any third party; (v) any claim related to your
          Profile Content or your NFT(s) (including related Underlying Material (as defined in the
          Terms of Sale) and Linked Digital Asset(s) (as defined in the Terms of Sale)); or (vi)
          your violation of applicable law. This indemnification obligation will continue after you
          stop using the Site and/or the Services. We reserve the right to assume the exclusive
          defense and control of any claim and matter otherwise subject to indemnification by you at
          your expense, and you shall not in any event settle or otherwise dispose of any such
          matter without our prior written consent.
        </TextParagraph>
        <Label bold>19. FORCE MAJEURE</Label>
        <TextParagraph>
          We will not be liable or responsible to you, nor be deemed to have defaulted or breached
          these Terms, for any failure or delay in our performance under these Terms when and to the
          extent such failure or delay is caused by or results from acts or circumstances beyond our
          reasonable control, including, without limitation, acts of God, flood, fire, earthquake,
          explosion, governmental actions, war, invasion or hostilities (whether war is declared or
          not), terrorist threats or acts, riot or other civil unrest, national emergency,
          revolution, insurrection, epidemic, pandemic, lockouts, strikes or other labor disputes
          (whether or not relating to our workforce), restraints or delays affecting carriers,
          inability to obtain or delay in obtaining adequate or suitable supplies, breakdown of
          materials or telecommunications, or power outage.{' '}
        </TextParagraph>
        <Label bold>20. NOTICES</Label>
        <TextParagraph>
          We may send you responses or notices by email, posting to the Site, or written
          communication sent by postal service. You agree that all agreements, notices, disclosures,
          and other communications that we provide to you electronically satisfy any legal
          requirement that such communications be in writing.{' '}
        </TextParagraph>
        <Label bold>21. GOVERNING LAW</Label>
        <TextParagraph>
          These Terms and your use of the Site and/or the Services shall be governed by and
          construed for both substantive and procedural purposes in accordance with the laws of the
          State of [Delaware], U.S.A., without giving effect to any principles of any choice or
          conflict of law provision or rule (whether of the State of [Delaware] or any other
          jurisdiction) that would cause the laws of any jurisdiction other than those of the State
          of [Delaware] to apply.
        </TextParagraph>
        <Label>22. DISPUTE RESOLUTION</Label>
        <TextParagraph>
          <Text sx={{ fontWeight: 700 }}>Timing of Claims</Text>
        </TextParagraph>
        <TextParagraph>
          Any cause of action or claim you may have with respect to the Site and/or the Services
          must be commenced within one (1) year after the claim or cause of action arises.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ fontWeight: 700 }}>Arbitration and Venue</Text>
        </TextParagraph>
        <TextParagraph>
          Any dispute relating in any way to your use of the Site and/or the Services shall be
          submitted to confidential arbitration in Los Angeles, California, except that, to the
          extent you have in any manner violated or threatened to violate our intellectual property
          rights, we may seek injunctive or other appropriate relief in any state or federal court,
          and you consent to jurisdiction and venue in such courts. Arbitration under these Terms
          shall be conducted under the rules then prevailing of the American Arbitration Association
          in accordance with its Commercial Arbitration Rules and before a single arbitrator.
        </TextParagraph>
        <TextParagraph>
          Ultimately, the selected arbitrator must have expertise in the subject matter of the
          dispute. The expenses of the arbitration charged by the arbitrator shall be borne by the
          non-prevailing party or otherwise as appropriately allocated between the parties to the
          arbitration by the arbitrator in his or her discretion. However, in every other regard,
          except as otherwise set forth in these Terms, each party shall pay for and bear its own
          costs and legal fees, costs, and expenses. The arbitration shall be completed within one
          hundred twenty (120) days of either giving notice or filing a demand to arbitrate with the
          American Arbitration Association (whichever shall first occur).
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ fontWeight: 700 }}>Final Arbitration</Text>
        </TextParagraph>
        <TextParagraph>
          The arbitrator’s award shall be binding and may be entered as a judgment in any court of
          competent jurisdiction. The testimony, evidence, ruling, and all documentation regarding
          any arbitration shall be considered confidential information. Neither party may use,
          disclose, or divulge any such information unless otherwise required by law.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ fontWeight: 700 }}>Class Action Waiver</Text>
        </TextParagraph>
        <TextParagraph>
          To the fullest extent permitted by applicable law, no arbitration under these Terms shall
          be joined to an arbitration involving any other party subject to these Terms, whether
          through class arbitration proceedings or otherwise. You agree to an arbitration on an
          individual basis. IN ANY DISPUTE, NEITHER YOU NOR WE WILL BE ENTITLED TO JOIN OR
          CONSOLIDATE CLAIMS BY OR AGAINST OTHER USERS IN COURT OR IN ARBITRATION OR OTHERWISE
          PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER, OR IN A PRIVATE ATTORNEY
          GENERAL CAPACITY. The arbitral tribunal may not consolidate more than one (1) person’s
          claims, and may not otherwise preside over any form of a representative or class
          proceeding. The arbitral tribunal has no power to consider the enforceability of this
          class arbitration waiver and any challenge to the class arbitration waiver may only be
          raised in a court of competent jurisdiction.
        </TextParagraph>
        <Label bold>23. MISCELLANEOUS</Label>
        <TextParagraph>
          The division of these Terms into sections and the headings of the various sections in
          these Terms are for convenience of reference only and shall not affect the construction or
          interpretation of these Terms. You acknowledge and agree that any principle of
          construction or rule of law that provides that an agreement shall be construed against the
          drafter of the agreement in the event of any inconsistency or ambiguity in such agreement
          shall not apply to these Terms. Our failure to insist upon or enforce strict performance
          of any provision of these Terms shall not be construed as a waiver of any provision or
          right. Neither the course of conduct between the parties nor trade practice shall act to
          modify any of these Terms. We may assign our rights and duties under these Terms to any
          party at any time without notice to you and without your express consent. You will not
          assign any of your rights or delegate any of your obligations under these Terms without
          our prior written consent. Any purported assignment or delegation in violation of this
          Section is null and void. No assignment or delegation relieves you of any of your
          obligations under these Terms. There shall be no third-party beneficiaries to these Terms.
          Any provision of these Terms that contemplates performance or observance subsequent to any
          expiration or termination of these Terms, or which is otherwise necessary to interpret the
          respective rights and obligations of the parties hereunder, shall survive any expiration
          or termination of these Terms and continue in full force and effect. If any provision of
          these Terms shall be held unlawful, void, or for any reason unenforceable, then that
          provision shall be deemed severable from these Terms and shall not affect the validity and
          enforceability of any remaining provisions. These Terms, together with our Terms of Sale
          and Privacy Policy, and all other documents incorporated herein by reference, constitute
          the entire agreement between the parties pertaining to the subject matter hereof and
          supersede any agreements previously existing between the parties with respect to such
          subject matter.{' '}
        </TextParagraph>
        <Label bold>24. NOTICE FOR CALIFORNIA USERS</Label>
        <TextParagraph>
          Under California Civil Code Section 1789.3, California users of the Site are entitled to
          the following specific consumer rights notice: The Complaint Assistance Unit of the
          Division of Consumer Services of the California Department of Consumer Affairs may be
          contacted in writing at 1625 North Market Blvd., Suite N. 112, Sacramento, CA 95834, or by
          telephone at (916) 445-1254 or (800) 952-5210.{' '}
        </TextParagraph>
        <Label bold>25. QUESTIONS</Label>
        <TextParagraph>
          If you have any questions or comments regarding these Terms, our Terms of Sale, our
          Privacy Policy, the Site, or the Services, please feel free to contact us by e-mail at
          nft@nomadblvd.io.
        </TextParagraph>
      </Container>
      <Wrapper>
        <FooterSection />
      </Wrapper>
    </>
  )
}
export { ServiceTerms }
