import { Link as ScrollLink } from 'react-scroll'
import styled, { css } from 'styled-components'
import { Box, Image, Label } from 'theme-ui'

type ItemProps = {
  isOpen?: boolean
  hasHover?: boolean
  theme?: any
}

export const Navbar = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 3;
    overflow-x: visible;

    .header-logo {
      width: clamp(13rem, 14vw, 17rem);
      height: auto;
      &.black path {
        fill: #000;
      }
    }
  `}
`

export const Menu = styled.button`
  width: fit-content;
  height: fit-content;
  padding: 0.25rem;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  .close {
    width: clamp(2.5rem, 4vw, 4.5rem);
    margin: 3vh 0 0;
    transition: 350ms ease;
  }

  .menu-icon.black path {
    stroke: #000;
  }

  &:hover {
    .close {
      fill: #fff;
      path {
        stroke: #000;
      }
    }
  }
`

export const MenuWrapper = styled(Box)<{ isOpen?: any }>`
  width: 35vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -35vw;
  background-color: #000;
  z-index: 10;
  transition: right 500ms ease-out;

  @media screen and (max-width: 64em) {
    width: 100vw;
    right: -100vw;
    padding-bottom: 3rem;
    padding-top: 2rem;
  }

  @media screen and (max-width: 52rem) and (orientation: landscape) {
    padding: 1rem 0;
    label {
      font-size: 1.25rem;
    }

    .link {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }

    .close {
      height: fit-content;
    }
  }

  ${({ isOpen }) => css`
    pointer-events: ${isOpen ? 'all' : 'none'};
    right: ${isOpen ? '0' : '-35vw'};
    @media screen and (max-width: 64em) {
      right: ${isOpen ? '0' : '-100vw'};
      padding: 0.5rem;
    }
  `}
`

export const SocialsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4vh 11vw 4vh;

  label {
    width: unset;
  }
`

export const MenuLink = styled(Label)<ItemProps>`
  ${({ hasHover }) => css`
    font-family: 'Brice H2';
    font-weight: 200;
    font-size: clamp(2.5rem, 3.75vw, 4.5rem);
    color: #fff;
    line-height: 1;
    transition: transform 500ms ease;
    cursor: pointer !important;

    @media screen and (max-width: 96rem) {
      font-size: clamp(1.4rem, 1.5vw, 4.5rem);
    }

    @media screen and (max-width: 26rem) {
      font-size: clamp(1.25rem, 3.75vw, 4.5rem);
    }

    @media screen and (max-width: 24rem) {
      font-size: clamp(1.5rem, 3.75vw, 4.5rem);
    }

    @media screen and (max-width: 20rem) {
      font-size: 0.59rem;
    }

    @media screen and (max-height: 49rem) {
      font-size: clamp(1rem, 2.25vh, 3rem);
    }

    ${hasHover &&
    css`
      &:hover {
        transform: scale(1.05);
      }
    `}
  `};
`

export const Item = styled(Box)<ItemProps>`
  list-style: none;
  cursor: pointer;
  text-transform: uppercase;
`

export const ScrollLinkWrapper = styled(ScrollLink)<{ isOpen?: any }>`
  display: flex;
  padding: 1rem 2.5rem;

  &.active,
  &:hover {
    border-bottom: 1px solid #f6f7f1;
  }

  a {
    text-decoration: none;
    -webkit-text-decoration-line: none;
  }
`

export const IconItem = styled(Image)`
  width: 1.375rem;
  cursor: pointer;
  transition: transform 350ms ease;
  &:hover {
    transform: scale(1.1);
    filter: invert(47%) sepia(29%) saturate(3456%) hue-rotate(344deg) brightness(93%) contrast(89%);
  }
`

export const User = styled.img`
  cursor: pointer;
  margin-right: 2.813rem;
  width: 4.063rem;
  @media screen and (max-width: 64em) {
    margin-right: 1rem;
  }
`

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  max-width: 2.5rem;
  max-height: 2.5rem;
  align-items: center;
  margin: 0.5rem;
`

export const MintButtonWrapper = styled.div`
  display: none;
  @media screen and (max-width: 64em) {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
`
