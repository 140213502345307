import { makeStyles, Snackbar } from '@material-ui/core'
import { AlertTitle } from '@material-ui/lab'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { Box } from '@theme-ui/components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Label } from 'theme-ui'
import useContract from '../../../hooks/useContract'
import UseWeb3Modal from '../../../hooks/UseWeb3Modal'
import { CURRENT_PASS } from '../../../utils'
import { Button as MintButton } from '../../common'
import Modal from '../../common/Modal/Modal'
import { TransactionProgress } from '../../TransactionProgress'
import { Mint } from './Mint'
import * as S from './MintSectionStyles'
import NoMural from './NoMural'
import Succes from './Succes'

const useStyles = makeStyles({
  root: {
    width: '65%',
  },
  alert: {
    width: '75%',
  },
})

const Alert = React.forwardRef((props: AlertProps, ref) => {
  return (
    <MuiAlert
      elevation={6}
      ref={ref as React.RefObject<HTMLDivElement>}
      variant="filled"
      {...props}
    >
      {props.children}
    </MuiAlert>
  )
})

type MintSectionProps = {
  buttonRef?: any
  onCloseMenu?: any
}
const Wrapper = styled(Box)`
  align-items: flex-end;
  display: flex;
`
export const Link = styled.span`
  text-decoration: underline;
  text-decoration-color: #000;
  cursor: pointer;
`

export const TextLabel = styled(Label)`
  font-size: 2.25rem;
  margin: 3.125rem 0 1.625rem;

  @media screen and (max-width: 20rem) {
    font-size: 1.25rem;
    margin: 1.625rem;
    line-height: 4.438rem;

    .subtitle {
      font-size: 1rem;
    }
  }
`

export const TextSubtitle = styled(Label)`
  font-size: 1.25rem;

  @media screen and (max-width: 20rem) {
    font-size: 0.725rem;
  }
`

const MintSection = ({ onCloseMenu, buttonRef }: MintSectionProps) => {
  const {
    user,
    mintToken,
    availablePass,
    passPrice,
    transactionCompleted,
    setTransactionCompleted,
    loadingTransaction,
    error,
    setError,
  } = useContract()
  const classes = useStyles()

  const [quantity, setQuantity] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const { connect } = UseWeb3Modal()
  const TOTAL_AVAILABLE_PASS = useMemo(() => {
    return availablePass ? availablePass[CURRENT_PASS].availablePass : 0
  }, [availablePass])
  const TOTAL_MURALS = availablePass ? availablePass[CURRENT_PASS].murals : 0
  const TOTAL_PASS_MINTED = availablePass ? availablePass[CURRENT_PASS].passMinted : 0
  const IS_POSSIBLE_MINT = availablePass
    ? availablePass[CURRENT_PASS].availablePass && availablePass[CURRENT_PASS].isOnList
    : false
  const IS_IN_WHITELIST = availablePass ? availablePass[CURRENT_PASS].isOnList : false
  const IS_CONFIRMED = availablePass ? availablePass[CURRENT_PASS].isConfirmed : false

  const handlePurchase = (event: any) => {
    event.preventDefault()
    setDisabled(true)
    mintToken!(quantity).then(({ error }: any) => (error ? setDisabled(false) : null))
  }

  const hideModal = () => {
    setOpenModal(preState => !preState)
    setQuantity(1)
    setTransactionCompleted!(false)
  }

  const handleConnect = useCallback(async () => {
    try {
      setOpenModal(preState => !preState)
      await connect!()
    } catch (e) {
      // TODO handle errors
      console.log('error', e)
    }
  }, [connect])

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return
    }
    setError!(undefined)
  }

  useEffect(() => {
    if (!loadingTransaction) setDisabled(false)
    else if (loadingTransaction) setDisabled(true)
  }, [loadingTransaction])

  useEffect(() => {
    setQuantity(TOTAL_AVAILABLE_PASS)
  }, [TOTAL_AVAILABLE_PASS])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  return (
    <Box>
      <Wrapper sx={{ justifyContent: 'center', zIndex: 100 }} ref={buttonRef}>
        <Box
          sx={{
            px: ['1rem', '2rem', '2rem', '18rem'],
            display: 'flex',
            justifyContent: 'center',
            margin: '0 0 2rem',
          }}
        >
          <MintButton
            primary
            label="MINT NOW"
            sx={{ height: '3.875rem' }}
            onClick={handleOpenModal}
            backgroundColor="#F6F7F1"
            color={'#000'}
          />
        </Box>
      </Wrapper>
      <Modal active={openModal} hideModal={hideModal} footer={<TransactionProgress />}>
        <Box>
          {user && IS_POSSIBLE_MINT && IS_CONFIRMED && !transactionCompleted ? (
            <Mint
              TOTAL_MURALS={TOTAL_MURALS}
              TOTAL_PASS_MINTED={TOTAL_PASS_MINTED}
              quantity={quantity}
              setQuantity={setQuantity}
              TOTAL_AVAILABLE_PASS={TOTAL_AVAILABLE_PASS}
              handlePurchase={handlePurchase}
              passPrice={passPrice}
              disabled={disabled}
              pass={CURRENT_PASS}
            />
          ) : user && transactionCompleted ? (
            <Succes />
          ) : (
            <>
              {availablePass && user && (!IS_IN_WHITELIST || !IS_CONFIRMED) ? (
                <NoMural
                  message={
                    !IS_IN_WHITELIST
                      ? 'You are not on the Pre-Sale List'
                      : !IS_CONFIRMED
                      ? 'You are confirmed for the sale starting Saturday at 12pm PST'
                      : "You don't have passes available to mint"
                  }
                />
              ) : (
                <>
                  <Box sx={{ display: 'block', textAlign: 'center' }}>
                    <TextLabel
                      sx={{
                        justifyContent: 'center',
                        fontFamily: 'Brice H3',
                        lineHeight: '7.438rem',
                        color: '#E1602F',
                        textTransform: 'uppercase',
                      }}
                    >
                      Whoops!
                    </TextLabel>
                    <TextSubtitle
                      sx={{
                        justifyContent: 'center',
                        color: '#000',
                        marginBottom: ['1rem'],
                        fontFamily: 'Prayuth Light',
                      }}
                      className="subtitle"
                    >
                      {!user
                        ? 'You need to connect your ETH wallet first!'
                        : "You don't have passes available to mint"}
                    </TextSubtitle>
                    {!user && <S.ConnectWallet onClick={handleConnect}>Connect</S.ConnectWallet>}
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Modal>{' '}
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={10000}
        onClose={handleClose}
        className={classes.root}
      >
        <Alert onClose={handleClose} severity="error" className={classes.alert}>
          <AlertTitle>Error</AlertTitle>
          {error?.reason || error?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default MintSection
