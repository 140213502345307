import { useState, useRef, useEffect } from 'react'
import { Flex, Grid, Box, Paragraph, Image, Link } from '@theme-ui/components'
import styled from 'styled-components'
import nomad from '../../../assets/nomad/GivingBack/nomad.svg'
import icon from '../../../assets/nomad/GivingBack/Icon.svg'
import oneMind from '../../../assets/nomad/GivingBack/one-mind.svg'
import texture from '../../../assets/nomad/texture.png'
import { useOnScreen } from '../../../hooks/UseOnScreen'

const Container = styled.section`
  width: 100%;
  height: 100vh;
  border-top: 1px solid #000;
  background-color: #18262b;
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (max-width: 64em) {
    height: 100%;
  }
`

export const Wrapper = styled.div`
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  align-items: center;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 64em) {
    padding: 5%;
    height: 100%;
  }
`

function checkVisible(elm: any) {
  var rect = elm.getBoundingClientRect()
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
}

const GivingBack = () => {
  const [isOpen, setIsOpen] = useState(false)
  const givingBackRef = useRef<HTMLDivElement>(null)
  let isGivingBackVisible = useOnScreen(givingBackRef, '0px', 0.11)

  useEffect(() => {
    const colorChange = document.querySelectorAll<HTMLElement>('.color-change')
    const getTeam = document.getElementById('TEAM')

    if (isGivingBackVisible) {
      colorChange.forEach(function (el) {
        el.classList.remove('black')
      })
    }
    if (!checkVisible(getTeam)) {
      colorChange.forEach(function (el) {
        el.classList.add('black')
      })
    }
  }, [isGivingBackVisible])

  return (
    <Container id="GIVING BACK" ref={givingBackRef}>
      <Wrapper style={{ zIndex: 2, backgroundImage: `url(${texture})` }}>
        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
          <Flex sx={{ flexDirection: 'column' }}>
            <Grid gap={[3, 2]} columns={[1, 3]} sx={{ padding: ['2rem 5rem', '0'] }}>
              <Box sx={{ textAlign: 'center', alignSelf: 'center' }}>
                <Image src={nomad} sx={{ width: ['13.4rem', '21rem'] }} />
              </Box>
              <Box sx={{ textAlign: 'center', alignSelf: 'center' }}>
                <Image src={icon} sx={{ maxWidth: ['2.3rem', '4rem'] }} />
              </Box>
              <Box sx={{ textAlign: 'center', alignSelf: 'center' }}>
                <Image src={oneMind} sx={{ maxWidth: ['8.3rem', '10rem'] }} />
              </Box>
            </Grid>
            <Box sx={{ marginTop: ['0.5rem', '1.5rem'] }}>
              <Paragraph
                variant="block"
                sx={{
                  marginBottom: ['1rem', '2rem'],
                  marginTop: ['1rem', null],
                  color: '#fff',
                  textAlign: 'left',
                }}
              >
                We see art as a crucial component of our mental well being which is why a portion of
                proceeds from sales of Nomad Blvd works will benefit One Mind, an organization that
                accelerates collaborative research and advocacy to enable all individuals facing
                brain health challenges to build healthy, productive lives.
              </Paragraph>
              <Paragraph variant="block" sx={{ color: '#fff', marginBottom: 2, fontWeight: 700 }}>
                What will proceeds that benefit One Mind go towards?{' '}
              </Paragraph>
              <Paragraph
                variant="block"
                sx={{
                  display: [`${isOpen ? 'flex' : 'none'}`, 'flex'],
                  marginBottom: ['1rem', null],
                  color: '#fff',
                  textAlign: ['justify', 'left'],
                }}
              >
                5% of proceeds from Nomad Blvd will support One Mind’s patient-centric research and
                services program portfolio that is creating a wellspring of hope for the millions of
                people worldwide who face a brain health challenge. Together, we will power
                collaborative research, treatments, and progress that are as transformative — and
                transparent — as the blockchain itself.
              </Paragraph>
              <Link
                variant="block"
                onClick={() => setIsOpen(prevState => !prevState)}
                sx={{
                  display: ['flex', 'none'],
                  my: '1rem',
                  textDecoration: 'underline',
                  color: '#fff',
                  marginTop: '1rem',
                }}
              >
                {isOpen ? 'Read Less' : 'Read More'}
              </Link>
            </Box>
          </Flex>
        </div>
      </Wrapper>
    </Container>
  )
}
export default GivingBack
