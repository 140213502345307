import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useContract from '../../hooks/useContract'
import { MyNomads } from './components/MyNomads'
import { Container } from './Exchange.styles'

const Nomads = () => {
  const { user, isAuthenticated } = useContract()
  const history = useHistory()

  const [fullH, setFullH] = useState(false)

  useEffect(() => {
    if (!isAuthenticated || !user) history.push('/')
  }, [history, isAuthenticated, user])
  return (
    <Container fullH={fullH}>
      <MyNomads setFullH={setFullH} user={user} />
    </Container>
  )
}
export { Nomads }
