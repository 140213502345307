import styled, { css } from 'styled-components'

export const Container = styled.section<{ padding?: any }>`
  ${({ padding }) => css`
    padding: ${padding ? padding[0] : null};
    z-index: 0;
    background-size: cover;
    maxwidth: 100%;
    position: relative;
    height: 100%;
    justify-content: center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: -1;
    }
    @media only screen and (min-width: 61em) {
      display: flex;
      height: 100vh;
      align-items: center;
      padding: ${padding ? padding[1] : null};
    }
    @media only screen and (max-width: 40em) {
      height: 100%;
    }
  `}
`
