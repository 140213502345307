import styled, { css } from 'styled-components'
import { Image } from 'theme-ui'

export const MarqueeWrapper = styled.div<{ marqueeAnimation?: string }>`
  background: #f6f7f1;
  text-align: center;
  height: 100%;
  width: 100%;
  border-left: 1px solid #000;

  @keyframes marqueeTop {
    0% {
      top: 0;
    }
    100% {
      top: -100%;
    }
  }

  @keyframes marqueeBottom {
    0% {
      top: -100%;
    }
    100% {
      top: 0;
    }
  }

  @keyframes marqueeLeft {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }

  @keyframes marqueeRight {
    0% {
      right: 100%;
    }
    100% {
      right: -100%;
    }
  }
`
export const Container = styled.div`
  overflow: hidden;
  margin: 0 auto !important;
  height: 100%;
  text-align: center;
`
export const MArqueeBlock = styled.div<{ marqueeAnimation?: string }>`
  ${({ marqueeAnimation }) => css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    padding: ${marqueeAnimation === 'marqueeLeft' || marqueeAnimation === 'marqueeRight'
      ? '0px '
      : '30px '}0;
    float: left;
  `}
`
export const MarqueeInner = styled.div<{ marqueeAnimation?: string; seconds?: string }>`
  ${({ marqueeAnimation, seconds }) => css`
    display: ${marqueeAnimation === 'marqueeLeft' || marqueeAnimation === 'marqueeRight'
      ? 'flex'
      : 'block'};
    height: ${marqueeAnimation === 'marqueeLeft' || marqueeAnimation === 'marqueeRight'
      ? '100%'
      : '200%'};
    width: 100%;
    position: absolute;
    animation: ${`${marqueeAnimation} ${seconds}`} linear infinite;
    @media only screen and (min-width: 40em) {
      :hover {
        animation-play-state: paused;
      }
    }
  `}
`
export const MarqueeItem = styled.div<{ marqueeAnimation?: string }>`
  ${({ marqueeAnimation }) => css`
    width: 100%;
    align-self: center;
    max-height: ${marqueeAnimation === 'marqueeLeft' || marqueeAnimation === 'marqueeRight'
      ? '25rem'
      : '40rem'};
    display: block;
    transition: all 0.2s ease-out;
    position: relative;
    padding: 2rem;
    border-bottom: ${marqueeAnimation === 'marqueeLeft' || marqueeAnimation === 'marqueeRight'
      ? 'none'
      : '1px solid #000000'};
    border-left: ${marqueeAnimation === 'marqueeLeft' || marqueeAnimation === 'marqueeRight'
      ? '1px solid #000000'
      : 'none'};
    height: ${marqueeAnimation === 'marqueeLeft' || marqueeAnimation === 'marqueeRight'
      ? '100%'
      : 'auto'};
  `}
  @media only screen and (min-width: 40em) {
    :hover .after {
      display: flex !important;
      opacity: 1;
    }
  }
`
export const ImageOverlay = styled.div<{ active?: boolean }>`
  color: #fff;
  display: none !important;
  flex-direction: column;
  align-items: center;
  place-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.6);
  // border-radius: 1.55rem;
  ${({ active }) =>
    active &&
    css`
      @media screen and (max-width: 64em) {
        display: flex !important;
        opacity: 1;
      }
    `}
`
export const IconItem = styled(Image)`
  width: 1.375rem;
  cursor: pointer;
  margin: 0 0.625rem !important;
  &:hover {
    filter: invert(57%) sepia(95%) saturate(3526%) hue-rotate(343deg) brightness(93%) contrast(89%);
  }
`
