export const fetcherETHUSD = async (url: string) => {
  try {
    const {
      result: { ethusd },
    } = await (await fetch(url)).json()
    return ethusd
  } catch (e) {
    console.log(e)
  }
}

export const fetcherMetadata = async (url: string) => {
  try {
    return await (await fetch(url)).json()
  } catch (e: any) {
    return { error: e.message }
  }
}
