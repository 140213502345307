const {
  REACT_APP_SERVICE_URL,
  REACT_APP_PROXY_URL,
  REACT_APP_CURRENT_PASS,
  REACT_APP_OPENSEA_URL,
  REACT_APP_FORMSPREE,
  REACT_APP_ETH_NETWORK,
  REACT_APP_URL_ADDRESS,
  REACT_APP_TOKEN_ID,
  REACT_APP_INFURA_ID,
  REACT_APP_MINT_PROCESS,
  REACT_APP_METADATA_URL,
  REACT_APP_NFT_URL,
  REACT_APP_FOUNDER_URL
} = process.env

export const ETHSCAN_API = `${REACT_APP_SERVICE_URL}/ethusd`
export const METADATA_API = REACT_APP_SERVICE_URL || ''
export const PROXY_API = REACT_APP_PROXY_URL
export const CURRENT_PASS = REACT_APP_CURRENT_PASS || 'gold'
export const OPENSEA_URL = REACT_APP_OPENSEA_URL
export const ETH_NETWORK = REACT_APP_ETH_NETWORK || '4'
export const MINT_PROCESS = REACT_APP_MINT_PROCESS
export * from './formatPriceEth'
export * from './toShort'
export const URL_ADDRESS = REACT_APP_URL_ADDRESS
export const TOKEN_ID = REACT_APP_TOKEN_ID
export const INFURA_ID = REACT_APP_INFURA_ID
export const FORMSPREE = `${REACT_APP_FORMSPREE}`
export const METADATA_URL = REACT_APP_METADATA_URL
export const NFT_URL = REACT_APP_NFT_URL
export const FOUNDER_URL = REACT_APP_FOUNDER_URL
