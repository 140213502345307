import styled from 'styled-components'
import { Box } from 'theme-ui'

export const TopContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 750ms ease;
  pointer-events: none;

  @media screen and (max-width: 64em) {
    pointer-events: all;
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5.5vw 8vw 0;
  position: absolute;
  top: 0;
  left: 0;

  h1 {
    margin-left: auto;
    font-size: clamp(3rem, 15vw, 18rem);
    line-height: 0.9;
    overflow-y: hidden;
    span {
      display: inline-block;
    }
  }

  h1:nth-child(2) {
    margin-right: 13vw;
  }

  h1:nth-child(3) {
    width: 100%;
  }

  svg {
    margin: 0 auto;
  }

  a {
    cursor: pointer;
  }

  strong {
    font-family: 'Prayuth';
    font-weight: 700;
  }
  .am {
    display: none;
  }

  @media screen and (max-width: 64rem) {
    height: fit-content;
    justify-content: unset;
    bottom: 0%;
    padding-bottom: 3vw;
    margin: auto 0;

    .am {
      display: block;
    }

    .pM {
      display: block;
    }
  }

  @media screen and (max-width: 48rem) {
    padding-bottom: 0;
    bottom: 10%;
  }

  @media screen and (max-width: 40rem) {
    margin: 35vw 0 0;
  }

  @media screen and (max-width: 27rem) {
    margin: 50vw 0 0;
  }

  @media screen and (max-width: 26rem) {
    margin: 30vw 0 0;
  }

  @media screen and (min-width: 24.6rem) and (max-width: 24.9rem) {
    margin: 30vw 0 0;
  }

  @media screen and (max-width: 24.5rem) {
    margin-top: 31vw;
    position: relative;
  }

  @media screen and (max-width: 23.5rem) {
    margin: 25vw 0 0;
    #collection-text {
      display: none;
    }
  }

  @media screen and (max-width: 20rem) {
    margin: 9vw 0 0;
    #collection-text {
      font-size: small;
    }
  }

  @media screen and (min-width: 160rem) {
    padding: 5.5vw 14vw 0;

    h1:nth-child(2) {
      margin-right: 10vw;
    }
  }

  @media screen and (min-width: 65rem) and (max-width: 79.9rem) and (max-height: 44rem) {
    h1:nth-child(1){
      margin-top: 0 !important;
    }
  }
`

export const ImageContainer = styled.div`
  width: 73%;
  height: 100vh;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:nth-child(3) {
    transform: translateX(-116%);
  }

  div:nth-child(1) {
    transform: translateX(115.7%);
  }

  div:nth-child(4) {
    display: none;
  }

  @media screen and (max-width: 66em) {
    width: 100%;
    justify-content: center;

    div:nth-child(3) {
      transform: translate(-100%, -5%);
    }

    div:nth-child(1) {
      transform: translate(100%, -5%);
    }

    div:not(:nth-child(4)) {
      display: none;
    }

    div:nth-child(4) {
      display: block;
      opacity: 1;
    }
  }
`

// export const BottomContainer = styled.div`
//   width: 100%;
//   height: 100vh;
//   overflow: hidden;
//   padding-bottom: 4vh;
//   position: absolute;
//   top: 0;
//   left: 0;
//   background-color: #000;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;

//   p {
//     width: 100%;
//     max-width: 75%;
//     margin: auto auto 3rem;
//     text-align: center;
//     span {
//       opacity: 0;
//     }
//   }

//   a {
//     cursor: pointer;
//   }

//   @media screen and (max-width: 64em) {
//     display: none;
//   }
// `

export const ImagesContainer = styled.div`
  width: 100%;
  max-width: 74rem;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

export const ImgCont = styled.div`
  min-width: 22vw;
  min-height: 50vh;
  width: 22vw;
  height: 50vh;
  overflow: hidden;
  clip-path: inset(0 28% 0 28%);
  transition: clip-path 500ms ease;
  opacity: 0;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media screen and (max-width: 66rem) {
    min-width: 56vw;
    min-height: 74vw;
    width: 56vw;
    height: 74vw;
    transform: translateY(-25%);
  }

  @media screen and (max-width: 49rem) {
    transform: translateY(-14%);
  }

  @media screen and (max-width: 40rem) {
    min-width: 72vw;
    min-height: 94vw;
    width: 72vw;
    height: 94vw;
    transform: translateY(-25%);
  }

  @media screen and (min-width: 66rem) and (max-height: 35rem) {
    min-height: 30rem;
    height: 30rem;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 65%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0 2rem;
  padding: 0 13%;
  pointer-events: all;

  @media screen and (min-height: 61rem) {
    top: 70%;
  }

  @media screen and (max-width: 64rem) {
    top: 65%;
  }

  @media screen and (max-width: 48rem) {
    top: 65%;
  }
  @media screen and (max-width: 40rem) {
    top: 60%;
  }
  @media screen and (max-width: 27rem) {
    top: 48%;
  }

  @media screen and (max-width: 24.5rem) {
    top: 0%;
    position: relative;
  }

  @media screen and (max-width: 23.5rem) {
    #collection-text {
      font-size: large;
    }
  }
  @media screen and (max-width: 20rem) {
    top: 0;
    #collection-text {
      font-size: small;
    }
  }

  @media screen and (max-width: 80rem) and (max-height: 44rem) {
    p {
      font-size: small;
    }
  }
`

export const WrapperContent = styled.div`
  displey: block;

  @media screen and (max-width: 64rem) {
    display: flex;
    flex-direction: column;
    position: relative;
    transform: translate(0%, -65%);
    width: 100%;
    height: 100%;
  }
`

export const Container = styled(Box)`
  @media screen and (max-width: 24.5rem) and (max-height: 42.5rem) {
    min-height: 155vh!important;
  }

  @media screen and (max-width: 20rem) and (max-height: 30rem) {
    min-height: 150vh!important;
  }

  @media screen and (max-width: 64rem) and (min-width: 51rem) {
    min-height: 135vh!important;
  }

  @media screen and (max-width: 52em) and (min-width: 40rem) {
    min-height: 145vh!important;
  }

  @media screen and (min-width: 66rem) and (max-height: 35rem) {
    min-height: 46rem !important;
  }
`
