import { Flex, Link, Paragraph, Text } from '@theme-ui/components'
import { useState } from 'react'
import styled from 'styled-components'
import texture from '../../../assets/nomad/texture.png'
import TheNomads from '../../../assets/nomad/TheNomads/nomads.png'

const Container = styled.section`
  width: 100%;
  background-color: #18262b;
  display: flex;
  align-items: center;
  background-image: url(${TheNomads});
  background-size: contain;
  background-position: center 120%;
  background-repeat: no-repeat;
  position: relative;

  .fade {
    width: 100%;
  }
  @media screen and (max-width: 64em) {
    background-image: none;
  }
  @media screen and (max-width: 40rem) {
    #abt-more {
      display: none;
    }
  }
`

export const Wrapper = styled.div`
  padding: 6rem 15% 28%;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 64em) {
    padding: 5rem 5% 5rem;
    height: 100%;
    background-image: none;
    z-index: 0;
    padding-bottom: 17rem;
  }
`

const Nomads = () => {
  const [isOpenReadMore, setIsOpenReadMore] = useState(false)

  return (
    <Container id="THE NOMADS">
      <Wrapper style={{ zIndex: 2, backgroundImage: `url(${texture})` }}>
        <div
          className="fade"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
          style={{ marginBottom: '5rem' }}
        >
          <Flex sx={{ flexDirection: 'column', zIndex: 2 }}>
            <Text variant="blvd" sx={{ fontFamily: 'Brice H3', marginBottom: '2rem' }}>
              MEET THE NOMADS
            </Text>

            <Paragraph
              variant="block"
              sx={{
                marginBottom: ['2rem'],
                marginTop: ['1rem', '2rem'],
                display: 'block',
                color: '#FFF',
              }}
            >
              The 3,500 Nomad NFTs are based on Isabelle’s famous Isabelle Gorilla character, which
              she first painted in 2011. The Nomad represents the uncensored human — who we are
              beneath the images we project. In a culture defined by the media’s constructed ideals
              of beauty and celebrity, Isabelle’s Gorillas stand for authenticity and
              self-acceptance. They let it all hang out and, despite their large, hairy bodies and
              pot bellies, they’re quietly self-confident and unfazed.
              <span id="abt-more">
                They are Nomads, untethered from the expectations of society. As the Metaverse
                presents new challenges to identity, status, and success, the Nomads are a mirror.
                They are us and we are them. They invite us to not take anything too seriously, to
                laugh at ourselves, and embrace the absurdities of life. They are here to remind us
                of what’s important, our IRL imperfections, and our essential human nature.
              </span>
              <br id="abt-more" />
              <span id="abt-more">
                <br />
                The BLVD is where you’ll find members-only benefits, giveaways, GMs and WAGMIs,
                events, interviews and candid conversations with fine artists and collectors, and a
                system for Nomad Blvd NFT holders to collaborate on the future of our community and
                brand. We invite you to join us and stay a while – we think you’ll like it here.
              </span>
              {isOpenReadMore && (
                <>
                  <br />
                  <span>
                    <br />
                    The BLVD is where you’ll find members-only benefits, giveaways, GMs and WAGMIs,
                    events, interviews and candid conversations with fine artists and collectors,
                    and a system for Nomad Blvd NFT holders to collaborate on the future of our
                    community and brand. We invite you to join us and stay a while – we think you’ll
                    like it here.
                  </span>
                </>
              )}
              <br />
              <Link
                variant="block"
                onClick={() => setIsOpenReadMore(prevState => !prevState)}
                sx={{
                  display: ['inline', 'none'],
                  textDecoration: 'underline',
                  color: '#FFF',
                }}
              >
                {isOpenReadMore ? 'Read Less' : 'Read More'}
              </Link>
            </Paragraph>
          </Flex>
          {/* <ImgContainer>
          <img src={TheNomads} alt="Nomad Boulevard" />
        </ImgContainer> */}
        </div>
      </Wrapper>
      {/* <TextureImage src={texture} /> */}
    </Container>
  )
}
export default Nomads
