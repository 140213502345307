import { IContractState, initialState } from './ContractState'
import {
  RESET_ALL,
  SET_ACTIVATING_CONNECTOR,
  SET_AVAILABLE_PASS,
  SET_CONTRACT,
  SET_ERROR,
  SET_ETH_PRICE,
  SET_LOADING_TRANSACTION,
  SET_TOKENS_ON_SALE,
  SET_TRANSACTION,
  SET_TRANSACTION_COMPLETED,
  SET_USER,
} from './ContractTypes'
interface IAction {
  type: string
  payload: any
}

const reducer = (state: IContractState = initialState, action: IAction): IContractState => {
  switch (action.type) {
    case SET_CONTRACT:
    case SET_USER:
    case SET_AVAILABLE_PASS:
    case SET_TRANSACTION:
    case SET_TOKENS_ON_SALE:
    case SET_ETH_PRICE:
    case SET_ACTIVATING_CONNECTOR:
    case SET_TRANSACTION_COMPLETED:
    case SET_LOADING_TRANSACTION:
    case SET_ERROR:
      return { ...state, ...action.payload }
    case RESET_ALL:
      return initialState
    default:
      return state
  }
}

export default reducer
