import axios from 'axios'
import { BigNumber, Contract, Event, utils } from 'ethers'
import { ReactElement, useCallback, useReducer, useState } from 'react'
import { TokenProps } from '../../components'
import NFTT from '../../contracts/NFTT.json'
import UseWeb3Modal from '../../hooks/UseWeb3Modal'
import { PROXY_API, TOKEN_ID, METADATA_URL } from '../../utils'
import StateContext from './ContractContext'
import ContractReducer from './ContractReducer'
import { initialState } from './ContractState'
import {
  RESET_ALL,
  SET_ACTIVATING_CONNECTOR,
  SET_AVAILABLE_PASS,
  SET_CONTRACT,
  SET_ERROR,
  SET_ETH_PRICE,
  SET_LOADING_TRANSACTION,
  SET_TOKENS_ON_SALE,
  SET_TRANSACTION,
  SET_TRANSACTION_COMPLETED,
  SET_USER,
} from './ContractTypes'

type IProvider = {
  children: JSX.Element | JSX.Element[]
}
const StateProvider = ({ children }: IProvider): ReactElement => {
  const [
    {
      contract,
      passPrice,
      isAuthenticated,
      contractDetails,
      user,
      tokensOnSale,
      ethPrice,
      activatingConnector,
      transaction,
      availablePass,
      transactionCompleted,
      loadingTransaction,
      error,
    },
    dispatch,
  ] = useReducer(ContractReducer, initialState)

  const { signer, address } = UseWeb3Modal()

  const setContract = useCallback(
    async (chainId: number) => {
      try {
        if (!signer) throw new Error('No Signer Found')

        const networkid = (id: number) => {
          switch (id) {
            case 1337:
              return 5777
            default:
              return id
          }
        }
        const deployedNetwork =
          NFTT.networks[String(networkid(chainId)) as keyof typeof NFTT.networks]
        console.log(`Deployed network: `, String(networkid(chainId)))

        if (!deployedNetwork) {
          throw new Error('The network you selected is no supported yet.')
        }

        const { address } = deployedNetwork
        console.log(`Contract Address Here: `, address)

        const contract = new Contract(address, NFTT.abi, signer)

        const name = 'NOMAD'
        const symbol = 'NMD'

        const price = 0

        dispatch({
          type: SET_CONTRACT,
          payload: { passPrice: price, contract, contractDetails: { name, symbol, address } },
        })
        return contract
      } catch (e) {
        console.log(e)
        return null
      }
    },
    [signer]
  )

  const getUserTokensAndRewards = useCallback(
    async (currentContract: Contract = contract as Contract) => {
      try {
        console.log(`Signer: `, signer)
        console.log(`Contract: `, currentContract)

        if (!signer) throw new Error('No Signer Found')
        if (!currentContract) throw new Error('No contract found')
        if (!user?.address && !address) throw new Error('No user found')

        const userAddress = user?.address || address

        const results = await currentContract.getTokenIndexes(userAddress)
        // const rewards = await currentContract.getRewardContractBalance(userAddress, 0)

        //Leer de variable de ambiente
        const baseUri = METADATA_URL

        console.log(`URI tokens:`, baseUri)
        // console.log(`rewards tokens:`, rewards)

        const ownedTokens: Map<string, TokenProps> = new Map()
        await Promise.all(
          results.map(async (tokenId: BigNumber) => {
            const id = tokenId.toString()
            const uri = `${baseUri}${id}.json`
            const price = BigNumber.from('0xff')
            const name = `Nomad ${id}`

            const res = await axios.get(`${uri}`)
            if (res && res.data && res.status === 200) {
              return ownedTokens.set(uri, {
                id,
                uri,
                price,
                name,
                label: res?.data?.name,
                src: res?.data?.image,
              })
            }
          })
        )

        console.log(ownedTokens)

        return Array.from(ownedTokens).map(([_, token]) => token)
      } catch (e) {
        console.log(e)
        return []
      }
    },
    [address, contract, user, signer]
  )

  const setUser = useCallback(
    async (currentContract: Contract = contract as Contract) => {
      try {
        if (!signer) throw new Error('No Signer Found')
        if (!currentContract) throw new Error('No contract found')
        if (!user && !address) throw new Error('No user found')

        // console.log(`USER: `, user)
        // console.log(`USER address: `, address)
        let myFounderTokens: any = 0
        // console.log(`Calling ${PROXY_API}/pass/${user?.address || address}`)
        const res = await axios.get(`${PROXY_API}/pass/${user?.address || address}`)
        // console.log(res.data.data)
        if (res && res.status === 200 && res.data.error === false) {
          const { founderTokens } = res.data.data
          console.log(founderTokens)
          myFounderTokens = founderTokens
          dispatch({ type: SET_AVAILABLE_PASS, payload: { availablePass: res.data.data } })
          console.log(`Updating available pass data`)
        }

        const balance = await currentContract.balanceOf(user?.address || address)
        console.log(`Balance: `, balance.toNumber())

        const ownedTokens = await getUserTokensAndRewards(currentContract)
        console.log(balance)
        dispatch({
          type: SET_USER,
          payload: {
            isAuthenticated: true,
            user: { address: address || user?.address || '', balance, ownedTokens, myFounderTokens },
          },
        })
      } catch (e) {
        console.log(e)
      }
    },
    [user, signer, address, contract, getUserTokensAndRewards]
  )

  const mintToken = useCallback(
    async (quantity: number): Promise<any> => {
      try {
        if (!contract) throw new Error('No contract found')

        console.log(`Calling: ${PROXY_API}/signature/${user?.address}/${quantity}`)

        const res = await axios.get(`${PROXY_API}/signature/${user?.address}/${quantity}`)

        console.log(
          `Quantity: ${quantity}, User Addr: ${user?.address}, Signature: `,
          res?.data || {}
        )

        if (res && res.status === 200 && res.data.error === false) {
          const passId = res.data.data.passId
          const amount = res.data.data.amount
          const nonce = res.data.data.nonce
          const signature = res.data.data.signature
          const total = passPrice!.mul(quantity)

          console.log(`Signature OK`)
          const tx = await contract.mintPass(passId, amount, nonce, signature, { value: total })
          console.log(`Transaction: `, tx)

          dispatch({ type: SET_TRANSACTION, payload: { transaction: tx } })
          return { error: false }
        } else {
          console.log(`Error calling signature endpoint`)
          console.log(res)
          return { error: true }
        }
      } catch (e: any) {
        console.log('on mint', { e })
        dispatch({ type: SET_ERROR, payload: { error: { ...e } } })
        return { error: true }
      }
    },
    [contract, passPrice, user]
  )

  const redeemPasses = useCallback(
    async (founderPassQty: number, collectorPassQty: number): Promise<any> => {
      try {
        if (!contract) throw new Error('No contract found')

        console.log(`founderPassQty: `, founderPassQty, `collectorPassQty`, collectorPassQty)

        const tx = await contract.redeem(founderPassQty, collectorPassQty)
        console.log(`Transaction: `, tx)

        dispatch({ type: SET_TRANSACTION, payload: { transaction: tx } })
        return { error: false }
      } catch (e: any) {
        console.log('ON EXCHANGE', { e })
        dispatch({ type: SET_ERROR, payload: { error: { ...e } } })
        return { error: true }
      }
    },
    [contract]
  )

  const updateTokensOnSale = useCallback(async () => {
    try {
      if (!contract) throw new Error('No contract found')
      dispatch({ type: SET_TOKENS_ON_SALE, payload: { tokensOnSale: [] as TokenProps[] } })
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }, [contract])

  const setTokenSale = useCallback(
    async (id: string, price: BigNumber, onSale: boolean = false) => {
      try {
        if (!contract) throw new Error('No contract found')
        if (!user) throw new Error('No user found')

        const tx = await contract.setTokenSale(id, onSale, price, { from: user.address })
        dispatch({ type: SET_TRANSACTION, payload: { transaction: tx } })
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    [contract, user]
  )

  const transferToken = useCallback(
    async (id: string, to: string) => {
      try {
        if (!contract) throw new Error('No contract found')
        if (!user) throw new Error('No user found')

        const tx = await contract['safeTransferFrom(address,address,uint256)'](
          user.address,
          to,
          id,
          {
            from: user.address,
          }
        )
        dispatch({ type: SET_TRANSACTION, payload: { transaction: tx } })
      } catch (e) {
        console.log(e)
      }
    },
    [contract, user]
  )

  const setEthPrice = useCallback((ethPrice: string) => {
    dispatch({ type: SET_ETH_PRICE, payload: { ethPrice } })
  }, [])

  const setActivatingConnector = useCallback((activatingConnector: any) => {
    dispatch({ type: SET_ACTIVATING_CONNECTOR, payload: { activatingConnector } })
  }, [])

  const setTransactionCompleted = useCallback((transactionCompleted: boolean) => {
    dispatch({ type: SET_TRANSACTION_COMPLETED, payload: { transactionCompleted } })
  }, [])

  const setTransaction = useCallback((transaction: any) => {
    dispatch({ type: SET_TRANSACTION, payload: { transaction } })
  }, [])

  const setLoadingTransaction = useCallback((loadingTransaction: boolean) => {
    dispatch({ type: SET_LOADING_TRANSACTION, payload: { loadingTransaction } })
  }, [])

  const setError = useCallback((error: any) => {
    dispatch({ type: SET_ERROR, payload: { error } })
  }, [])

  const resetAll = useCallback(() => {
    dispatch({ type: RESET_ALL, payload: {} })
  }, [])

  return (
    <StateContext.Provider
      value={{
        contract,
        passPrice,
        isAuthenticated,
        contractDetails,
        user,
        tokensOnSale,
        ethPrice,
        activatingConnector,
        transaction,
        availablePass,
        transactionCompleted,
        loadingTransaction,
        error,
        setEthPrice,
        setActivatingConnector,
        setTransactionCompleted,
        setContract,
        setUser,
        transferToken,
        mintToken,
        updateTokensOnSale,
        setTokenSale,
        getUserTokensAndRewards,
        setTransaction,
        setLoadingTransaction,
        setError,
        resetAll,
        redeemPasses,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export default StateProvider
