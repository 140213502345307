import { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'theme-ui'
import { Header, PrivateRoute } from '../components'
import GlobalStyle from '../fonts/fonts'
import { Connect, Marketplace, Profile, Exchange } from './'
import { Nomads } from './Exchange/Nomads'
import { PrivacyTerms } from './Terms/Privacy'
import { SaleTerms } from './Terms/Sale'
import { ServiceTerms } from './Terms/Service'
const Root = () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <GlobalStyle />
      <Header />
      <Connect>
        <Container>
          <Switch>
            <PrivateRoute path="/profile" component={Profile} />
            {/* <Route exact path="/" component={!IS_EXCHANGE ? Marketplace : Exchange} /> */}
            <Route exact path="/terms-of-sale" component={SaleTerms} />
            <Route exact path="/terms-of-service" component={ServiceTerms} />
            <Route exact path="/privacy-policy" component={PrivacyTerms} />
            <Route exact path="/" component={Exchange} />
            <Route exact path="/my-nomads" component={Nomads} />
          </Switch>
        </Container>
      </Connect>
    </Router>
  )
}

export { Root }
