export const NAV_MENU_ITEMS_V2 = [
  {
    label: 'HOME',
  },
  {
    label: 'ISABELLE',
  },
  {
    label: 'THE NOMADS',
  },
  {
    label: 'ARTISTS',
  },
  {
    label: 'BENEFITS',
  },
  {
    label: 'ROADMAP',
  },
  {
    label: 'GIVING BACK',
  },
  {
    label: 'TEAM',
  },
]

export const MENU_IMAGE_ITEMS = [
  {
    alt: 'opensea',
    id: 'opn',
    url: '/static/opensea.svg',
    hoverUrl: '/static/opensea_orange.svg',
    link: 'https://opensea.io/collection/nomadblvd-mintpass',
  },
  {
    alt: 'instagram',
    id: 'ig',
    url: '/static/insta.svg',
    hoverUrl: '/static/ig_orange.svg',
    link: 'https://instagram.com/nomadblvd.eth',
  },
  {
    alt: 'twitter',
    id: 'tw',
    url: '/static/twitter.svg',
    hoverUrl: '/static/tw_orange.svg',
    link: 'https://twitter.com/nomadblvd',
  },
  {
    alt: 'discord',
    id: 'dsc',
    url: '/static/discord.svg',
    hoverUrl: '/static/discord_orange.svg',
    link: 'https://discord.gg/3aBDQUjcgZ',
  },
]

export const ROUTES = {
  home: 'HOME',
  isabelle: 'ISABELLE',
  nomads: 'THE NOMADS',
  boulevard: 'THE BOULEVARD',
  collaborators: 'COLLABORATORS',
  benefits: 'BENEFITS',
  roadmap: 'ROADMAP',
  givingBack: 'GIVING BACK',
  team: 'TEAM',
}
