import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Text } from 'theme-ui'
import useContract from '../../hooks/useContract'

const StyledLinearProgress = withStyles({
  root: {
    height: '1.75rem',
  },
  colorPrimary: {
    backgroundColor: '#E1602F',
  },
  barColorPrimary: {
    backgroundColor: '#F6F7F4',
  },
})(LinearProgress)

const TransactionProgress = () => {
  const {
    setTransaction,
    setUser,
    updateTokensOnSale,
    setTransactionCompleted,
    transaction,
    setLoadingTransaction,
  } = useContract()

  const transactionRef = useRef(transaction)
  const [loading, setLoading] = useState<boolean>(false)

  const update = useCallback(async () => {
    await setUser!()
    setTransaction!(undefined)
    updateTokensOnSale!()
    setLoading(false)
    setTransactionCompleted!(true)
    setLoadingTransaction!(false)
  }, [setTransaction, setUser, updateTokensOnSale, setTransactionCompleted, setLoadingTransaction])

  useEffect(() => {
    const execute = async () => {
      try {
        transactionRef.current = transaction
        if (!transaction) return
        setLoading(true)
        setLoadingTransaction!(true)
        const receipt = await transaction.wait()
        if (receipt.confirmations >= 1) {
          update()
        }
      } catch (e) {
        console.log('transaction', e)
        setLoading(false)
      }
    }
    if (transaction) execute()
  }, [update, transaction, setLoadingTransaction])
  if (!loading) return null

  return (
    <>
      <Text sx={{ padding: ['0.25rem', '1rem'] }}>
        Confirming order. Stay awhile, we think you'll like it here.
      </Text>
      <StyledLinearProgress />
    </>
  )
}

export { TransactionProgress }
