import { useState } from 'react'
//import { useWeb3React } from '@web3-react/core'

//import { injected } from '../connectors'
// import { URI_AVAILABLE } from '@web3-react/walletconnect-connector'

export function useEagerConnect() {
  //const { activate, active, connector } = useWeb3React()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tried, setTried] = useState(false)

  /*
  useEffect(() => {

    if (connector === injected) {
      injected.isAuthorized().then((isAuthorized: boolean) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      })
    }
  }, [activate, connector])

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])
  */

  return tried
}

// type OnChangeProps = {
//   account?: string
//   chainId?: string | number
// }

export function useInactiveListener(suppress: boolean = false) {
  /*
  const { active, error } = useWeb3React()

  useEffect(() => {
    const { ethereum } = window as any

    if (ethereum) ethereum.autoRefreshOnNetworkChange = true

  }, [active, error, suppress])
  */
}
