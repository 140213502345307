import { BigNumber, Contract } from 'ethers'
import { TokenProps } from '../../components/Token'
import { ContractPropsDetails, UserProps } from '../../types'

export interface IContractState {
  isAuthenticated: boolean
  contract?: Contract
  user?: UserProps
  tokensOnSale?: TokenProps[]
  ethPrice?: string
  activatingConnector?: any
  transaction?: any
  availablePass?: any
  passPrice: BigNumber
  transactionCompleted: boolean
  contractDetails?: ContractPropsDetails
  loadingTransaction?: boolean
  error?: any
}

export const initialState: IContractState = {
  isAuthenticated: false,
  contract: undefined,
  user: undefined,
  tokensOnSale: [],
  ethPrice: '0.0',
  activatingConnector: undefined,
  transaction: undefined,
  availablePass: undefined,
  passPrice: BigNumber.from('0'),
  transactionCompleted: false,
  contractDetails: undefined,
  loadingTransaction: false,
  error: undefined,
}
