import { Contract } from 'ethers'
import { FC, useEffect, useState } from 'react'
import useSWR from 'swr'
import { Container, Heading, Text } from 'theme-ui'
import Modal from '../components/common/Modal/Modal'
import useContract from '../hooks/useContract'
import UseWeb3Modal from '../hooks/UseWeb3Modal'
import { useEagerConnect, useInactiveListener } from '../hooks/web3'
import { ETHSCAN_API } from '../utils'
import { fetcherETHUSD } from '../utils/fetchers'

// function getErrorMessage(error: Error) {
//   console.log(error)

//   /*
//   if (error instanceof NoEthereumProviderError) {
//     return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
//   } else if (error instanceof UnsupportedChainIdError) {
//     return "You're connected to an unsupported network. Please connect to Rinkeby network"
//   } else if (
//     error instanceof UserRejectedRequestErrorInjected ||
//     error instanceof UserRejectedRequestErrorWalletConnect
//   ) {
//     return 'Please authorize this website to access your Ethereum account.'
//   } else {
//     console.error(error)
//     return 'An unknown error occurred. Check the console for more details.'
//   } */
// }

const Connect: FC = ({ children }) => {
  const { activatingConnector, setContract, setUser } = useContract()
  const { netId, address, signer } = UseWeb3Modal()
  const [isActive, setActiveState] = useState(false)

  useSWR(ETHSCAN_API, fetcherETHUSD)

  useEffect(() => {
    if (!netId || !signer || !address) return
    const update = async () => {
      try {
        const newContract = await setContract!(netId)
        await setUser!(newContract as Contract)
      } catch (e) {
        console.log(e)
      }
    }

    update()
    // eslint-disable-next-line
  }, [netId, signer, address])

  /*  useEffect(() => {
    if (error) setActiveState(prevState => !prevState)
  }, [error])
  */

  const triedEager = useEagerConnect()
  useInactiveListener(!triedEager || !!activatingConnector)

  const hideModal = () => setActiveState(prevState => !prevState)

  return (
    <>
      <Modal active={isActive} hideModal={hideModal}>
        <Container>
          <Heading
            as="h2"
            sx={{
              color: 'black',
              fontFamily: 'VCR OSD MONO',
              fontSize: '30px',
              paddingBottom: '2rem',
            }}
          >
            ❌ Error
          </Heading>
          {false && (
            <Text sx={{ mt: 3, color: 'black', fontSize: ['18px', '24px'] }}>
              {
                //TODO: check this getErrorMessage(error)
              }
            </Text>
          )}
        </Container>
      </Modal>
      {children}
    </>
  )
}

export { Connect }
