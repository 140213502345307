import { useCallback, useEffect, useState } from 'react'
import useContract from '../../hooks/useContract'
import UseWeb3Modal from '../../hooks/UseWeb3Modal'
import { Collect } from './components/Collect'
import { MyNomads } from './components/MyNomads'
import { Passes } from './components/Passes'
import { Container } from './Exchange.styles'

const Exchange = () => {
  const [ready, setIsReady] = useState(true)
  const [fullH, setFullH] = useState(false)

  const { user, isAuthenticated, availablePass } = useContract()
  const isOnline = isAuthenticated && user

  const { connect } = UseWeb3Modal()

  const handleConnect = useCallback(async () => {
    try {
      await connect!()
    } catch (e) {
      console.log('error', e)
    } finally {
      //console.log('finally')
    }
  }, [connect])

  console.log(user)
  useEffect(() => {
    if (!isOnline) setFullH(false)
  }, [setFullH, isOnline])

  return (
    <Container fullH={fullH} minH>
      {!isOnline ? <Collect handleConnect={handleConnect} /> : <Passes setFullH={setFullH} />}
    </Container>
  )
}
export { Exchange }
