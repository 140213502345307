import { createContext } from 'react'

export interface IContextToast {
  address: any
  setAddress: (address: any) => void
  connect: () => Promise<void>
  signer: any
  setSigner: (signer: any) => void
  provider: any
  netId: any
  disconnectWallet: () => Promise<void>
}

const Web3ModalContext = createContext<Partial<IContextToast>>({})
export default Web3ModalContext
