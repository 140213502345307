/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useEffect, useMemo, useState } from 'react'
import { Divider } from 'theme-ui'
import blackVideoURL from '../../assets/videos/blackPass.mp4'
import goldenVideoURL from '../../assets/videos/goldenPass.mp4'
import FooterSection from '../../components/sectionsV2/FooterSection/FooterSection'
import useContract from '../../hooks/useContract'
import { CURRENT_PASS, OPENSEA_URL } from '../../utils'
import { Wrapper } from '../Terms/TermsStyles'
import PassCard from './PassCard'
import * as S from './Profile.styles'

export type ProfileProps = {}

const blackPassportName = 'Nomad BLVD | Founder Pass'

const golderPassportName = 'Nomad BLVD | Collectors Pass'

const Profile = () => {
  const { user, availablePass, setTransactionCompleted, transactionCompleted } = useContract()
  const [blackPassMinted, setBlackPassMinted] = useState(0)
  // const [goldenVideoURL, setGoldenVideoURL] = useState('')

  const VIDEOS = useMemo(() => {
    if (Object.keys(availablePass).length === 0) return null
    return Object.keys(availablePass).map(
      (pass, i) =>
        availablePass[pass].passMinted > 0 && (
          <div style={{ marginTop: '1rem' }} key={`pass-minted-${i}`}>
            <Card
              key={`pass_${pass}`}
              pass={pass}
              passMinted={availablePass[pass].passMinted}
              name={pass === 'black' ? blackPassportName : golderPassportName}
              video={pass === 'black' ? blackVideoURL : goldenVideoURL}
            />
          </div>
        )
    )
  }, [availablePass])

  const MY_TOKENS = useMemo(
    () =>
      user &&
      user?.ownedTokens.map(({ id, label, src }: any) => (
        <>
          <div style={{ marginTop: '1rem' }} key={`pass-minted-${id}`}>
            <Card
              isToken
              key={`pass_${id}`}
              name={label}
              src={src + '?r=' + Date.now()}
              id={id}
              passMinted={undefined}
            />
          </div>
        </>
      )),
    [user]
  )

  const FOUNDER_TOKENS = useMemo(() => {
    return (
      <div style={{ marginTop: '1rem' }}>
        <Card
          isFounder
          key="founderpass"
          name="Founder Token"
          video="https://nomad.mypinata.cloud/ipfs/QmVPQdbzCdekag411ZJrKHJDX1t18VsCLyQRk2sD3H7Nja"
          passMinted={user?.myFounderTokens}
        />
      </div>
    )
  }, [user])

  useEffect(() => {
    const colorChange = document.querySelectorAll<HTMLElement>('.color-change')
    colorChange.forEach(function (el) {
      el.classList.add('black')
    })
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (availablePass) {
      setBlackPassMinted(availablePass[CURRENT_PASS]?.passMinted)
    }
  }, [availablePass])

  useEffect(() => {
    if (!transactionCompleted) return
    setTransactionCompleted!(false)
  }, [transactionCompleted, setTransactionCompleted])

  return (
    <>
      <S.Container>
        <S.Title>MY NOMADS</S.Title>
        <S.Subtitle>ADDRESS</S.Subtitle>
        <S.Address style={{ marginTop: '0.5rem', marginBottom: 0 }}>{user ? user.address : ''}</S.Address>
        {(!!availablePass.black.passMinted || !!availablePass.gold.passMinted) && (
          <>
            <Divider sx={{ marginTop: '3.375rem', marginBottom: '3.375rem' }} />
            <S.CardContainer>{availablePass && VIDEOS}</S.CardContainer>
          </>
        )}

        {user && user?.ownedTokens.length > 0 && (
          <>
            <Divider sx={{ marginTop: '3.375rem', marginBottom: '3.375rem' }} />
            <S.Address>Woo! Say hello to your nomads!</S.Address>
            <S.CardContainer>{MY_TOKENS}</S.CardContainer>
          </>
        )}

        {user && user?.myFounderTokens > 0 && (
          <>
            <Divider sx={{ marginTop: '3.375rem', marginBottom: '3.375rem' }} />
            <S.Address>YOU ALSO GOT SOMETHING SPECIAL! </S.Address>
            <S.CardContainer>{FOUNDER_TOKENS}</S.CardContainer>
          </>
        )}
        <Divider sx={{ marginTop: '15.5rem', marginBottom: 0 }} />
      </S.Container>
      <Wrapper>
        <FooterSection color={'#171A1D'} />
      </Wrapper>
    </>
  )
}

export { Profile }

const Card = memo(({ passMinted, pass, name, video, isToken, src, id, isFounder }: any) => {
  console.log(passMinted, pass, name, video, isToken, src, id, isFounder)
  return (
    <PassCard
      isToken={isToken}
      isFounder={isFounder}
      key={`pass_${pass}`}
      quantity={passMinted}
      name={name}
      video={video}
      openseaURL={OPENSEA_URL}
      src={src}
      id={id}
      type={pass}
    />
  )
})
