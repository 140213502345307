import { Box } from '@theme-ui/components'
import styled from 'styled-components'

export const Container = styled(Box)`
  height: 100%;
  padding: 3rem;
  background-color: #000;
  z-index: 0;

  @media only screen and (min-width: 61em) {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    text-align: center;
    width: 100%;
  }
`

export const CardWrapper = styled(Box)`
  transition: 0.5s ease;
  .tw {
    display: none;
  }

  &:hover {
    .tw {
      display: block;
    }
    .sw {
      background-color: #00000090;
    }
  }
`

export const ImageWrapper = styled(Box)`
  width: 10rem;
  position: relative;
`
export const SocialsWrapper = styled(Box)`
  width: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 500ms ease;

  .tw:not(:last-child) {
    margin-right: 1rem;
  }
`
