import { useCallback } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import useContract from '../../../hooks/useContract'
import UseWeb3Modal from '../../../hooks/UseWeb3Modal'
import { CollectButton } from '../Exchange.styles'

const NotFound = () => {
  const { user, isAuthenticated, resetAll } = useContract()
  const { disconnectWallet } = UseWeb3Modal()

  const isOnline = isAuthenticated && user

  const handleDisconnect = useCallback(async () => {
    if (isOnline) {
      await resetAll!()
      return await disconnectWallet!()
    }
  }, [isOnline, disconnectWallet, resetAll])

  return (
    <Flex
      sx={{
        paddingX: ['1rem', '20rem'],
        paddingY: '5.5rem',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Text variant="passTitle">We don’t see any mint passes in this wallet</Text>
      <Text
        variant="passTitle"
        sx={{
          marginTop: '1rem',
          fontSize: 'clamp(0.725rem, 1.1vw, 1.125rem)',
          whiteSpace: 'nowrap',
        }}
      >
        Are you sure you connected the right wallet?{' '}
      </Text>
      <Box sx={{ width: '100%', marginTop: '1rem', paddingX: '1rem' }}>
        <CollectButton sx={{ width: '100%', maxWidth: '100%' }} onClick={handleDisconnect}>
          disconnect
        </CollectButton>
        <CollectButton
          sx={{ width: '100%', maxWidth: '100%', backgroundColor: '#91BBBE!important' }}
          onClick={() => {}}
        >
          BUY A PASS
        </CollectButton>
      </Box>
    </Flex>
  )
}

export { NotFound }
