import React, { Fragment } from 'react'
import Close from '../../../assets/nomad/home/close.svg'
import { StyledImage } from '../Accordion/AccordionStyles-v3'
import {
  ModalBlock,
  ModalBody,
  ModalClose,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from './ModalStyles'

type ModalProps = {
  active?: boolean
  hideModal?: (() => undefined) | any
  children?: JSX.Element | any
  footer?: JSX.Element | any
}

const Modal = ({ active, hideModal, children, footer }: ModalProps) => {
  return (
    <Fragment>
      {active && (
        <ModalBlock>
          <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
          <ModalContainer>
            <ModalHeader>
              <ModalClose onClick={() => hideModal()}>
                <StyledImage src={Close} />
              </ModalClose>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
          </ModalContainer>
        </ModalBlock>
      )}
    </Fragment>
  )
}
export default Modal
