import { BigNumber, Contract } from 'ethers'
import { createContext } from 'react'
import { TokenProps } from '../../components/Token'
import { ContractPropsDetails, UserProps } from '../../types'

export interface IContextState {
  isAuthenticated: boolean
  contract?: Contract
  contractDetails?: ContractPropsDetails
  user?: UserProps
  tokensOnSale?: TokenProps[]
  ethPrice?: string
  activatingConnector?: any
  transaction?: any
  library?: any
  availablePass?: any
  passPrice: BigNumber
  transactionCompleted: boolean
  signer?: any
  loadingTransaction?: boolean
  error?: any

  setContract(chainId: number): Promise<Contract | null>
  setEthPrice(ethPrice: string): void
  setActivatingConnector(activatingConnector: any): void
  setTransaction(transaction: any): void
  mintToken(quantity: number): Promise<any>
  setUser(contract?: Contract): Promise<void>
  updateTokensOnSale(): Promise<boolean>
  setTokenSale(id: string, price: BigNumber, onSale: boolean): Promise<boolean>
  transferToken(id: string, to: string): void
  setTransactionCompleted(transactionCompleted: boolean): void
  getUserTokensAndRewards(contract?: Contract): Promise<any>
  setLoadingTransaction(loadingTransaction?: boolean): void
  setError(error?: any): void
  resetAll(): void
  redeemPasses(founderPassQty: number, collectorPassQty: number): Promise<any>
}

const StateContext = createContext<Partial<IContextState>>({})
export default StateContext
