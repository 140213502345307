import { Flex, Text } from '@theme-ui/components'
import styled from 'styled-components'
import AccordionV3 from '../../common/Accordion/Accordion-v3'
import { BENEFITS_ITEMS } from '../../../utils/constants/benefits'
const Container = styled.section`
  height: 100%;
  background: #c9d5d3;
  font-family: 'Brice H3';
  color: #000;
  padding: 10rem 18rem;
  @media screen and (max-width: 64em) {
    padding: 8% 5%;
  }
`

const Title = styled(Text)``
const Benefits = () => {
  return (
    <Container id="BENEFITS">
      <Flex
        sx={{ flexDirection: 'column' }}
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        <Title sx={{ marginBottom: '2.25rem', fontSize: '2.25rem' }}>BENEFITS</Title>
        <AccordionV3
          accordionContent={BENEFITS_ITEMS}
          sx={{ flexDirection: 'column', width: '100%' }}
          itemWidth="100%"
        />
      </Flex>
    </Container>
  )
}
export default Benefits
