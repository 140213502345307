import { Box, Text } from '@theme-ui/components'
import styled from 'styled-components'

export const Container = styled.section`
  border-top: 1px solid #000;
  background-color: #f6f7f1;

  .slick-slider {
    height: 100%;
  }
  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }

  .slick-slide {
    display: flex;
    align-items: center;
    height: 100%;
    place-content: center;
    padding: 1rem;
    border-left: 1px solid #000000;
    div {
      display: flex;
      height: inherit;
    }
  }
`

export const TextBox = styled(Box)`
  max-width: 25rem;
  padding: 3rem;
  @media screen and (max-width: 64em) {
    padding: 5rem 5% 5rem;
  }
`

export const ImagesBox = styled(Box)`
  max-height: 100%;
  display: flex;
  width: 100%;
  position: relative;
`

export const Title = styled(Text)`
  @media screen and (max-width: 64em) {
    display: flex;
    max-width: 12rem;
  }
`
