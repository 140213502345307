import { Paragraph, Text } from '@theme-ui/components'
import styled, { css } from 'styled-components'

export const Container = styled.section`
  height: 100%;
  background: #c9d5d3;
  display: flex;
  padding: 10rem 15%;
  padding-bottom: 0;
  flex-direction: column;
  @media screen and (max-width: 64em) {
    padding-left: 5%;
    padding-right: 5%;
  }
  body {
    background: #c9d5d3;
  }

  @media screen and (min-width: 815px) {
    padding: 10rem 15%;
  }
`
export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  background: #c9d5d3;
  height: 5rem;
  @media screen and (max-width: 64em) {
    height: 14rem;
  }
  div {
    border: none;
  }
`

export const Title = styled(Text)`
  font-family: Brice H2;
  font-size: 4.728rem;
  color: #171a1d;
  margin-bottom: 1.25rem !important;
`
export const TextParagraph = styled(Paragraph)`
  color: #171a1d !important;
  font-family: Prayuth Regular !important;
  font-size: 1.25rem;
  margin-top: 1rem !important;
`
export const Label = styled(Text)<{ bold?: boolean }>`
  ${({ bold }) => css`
    color: #171a1d;
    font-family: Prayuth Regular !important;
    font-size: 1.25rem;
    font-weight: ${bold ? '700' : '400'};
    margin-top: 1rem !important;
  `}
`
