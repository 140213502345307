import { createGlobalStyle } from 'styled-components'

import Prayuth from './Prayuth-Medium.ttf'
import PrayuthBlack from './Prayuth-Black.ttf'
import PrayuthRegular from './Prayuth-Regular.ttf'
import PrayuthLight from './Prayuth-Light.ttf'
import PrayuthBold from './Prayuth-Bold.ttf'
import Brice from './Brice-Regular.ttf'
import BriceH1 from './BriceH1.ttf'
import BriceH2 from './BriceH2.ttf'
import BriceH3 from './BriceH3.ttf'
import BriceExtraLight from './Brice-ExtraLight.ttf'

export default createGlobalStyle`
    @font-face {
        font-family: 'Prayuth Light';
        src: url(${PrayuthLight}) format("truetype"),
        url(${PrayuthLight}) format("truetype");
        font-style: normal;
        font-weight: 300;
        font-stretch: normal;
        unicode-range: U+000D-FB04;
        font-display: swap;
    }

    @font-face {
        font-family: 'Prayuth Regular';
        src:  url(${PrayuthRegular})  format("truetype"),
        url(${PrayuthRegular})  format("truetype");
        font-style: normal;
        font-weight: 400;
        font-stretch: normal;
        unicode-range: U+000D-FB04;
        font-display: swap;
    }

    @font-face {
        font-family: 'Prayuth';
        src:   url(${Prayuth})  format("truetype"),
        url(${Prayuth})  format("truetype");
        font-style: normal;
        font-weight: 500;
        font-stretch: normal;
        unicode-range: U+000D-FB04;
        font-display: swap;
    }

    @font-face {
        font-family: 'Prayuth';
        src: url(${PrayuthBold}) format("truetype"),
        url(${PrayuthBold})  format("truetype");
        font-style: normal;
        font-weight: 700;
        font-stretch: normal;
        unicode-range: U+000D-FB04;
        font-display: swap;
    }

    @font-face {
        font-family: 'Prayuth';
        src: url(${PrayuthBlack}) format("truetype"),
        url(${PrayuthBlack})  format("truetype");
        font-style: normal;
        font-weight: 900;
        font-stretch: normal;
        unicode-range: U+000D-FB04;
        font-display: swap;
    }

    @font-face {
        font-family: 'Brice';
        url(${Brice})  format("truetype");
        src: url(${Brice}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Brice ExtraLight';
        url(${BriceExtraLight})  format("truetype");
        src: url(${BriceExtraLight}) format('truetype');
        font-weight: 200;
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Brice H1';
        url(${BriceH1})  format("truetype");
        src: url(${BriceH1}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Brice H2';
        url(${BriceH2})  format("truetype");
        src: url(${BriceH2}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Brice H3';
        url(${BriceH3})  format("truetype");
        src: url(${BriceH3}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: block;
    }

    @media screen and (max-width: 64rem) {
        html, body {
            overflow-x: hidden;
        }
    }
`
