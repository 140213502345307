import { Box, Flex, Link, Paragraph, Text, Image } from '@theme-ui/components'
import { useEffect, useRef, useState } from 'react'
import { useOnScreen } from '../../../hooks/UseOnScreen'
import { Button } from '../../common'
import { Marquee, MarqueeContainer, SpanWrapper, Wrapper, ImgContainer } from './BoulevardStyles'
import Isabelle from '../../../assets/nomad/boulevard/isabelle.png'

function checkVisible(elm: any) {
  var rect = elm.getBoundingClientRect()
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
}

const Boulevard = () => {
  const [isOpenReadMore, setIsOpenReadMore] = useState(false)
  const blvdRef = useRef<HTMLDivElement>(null)
  let isBLVDVisible = useOnScreen(blvdRef, '0px', 0.11)

  useEffect(() => {
    const colorChange = document.querySelectorAll<HTMLElement>('.color-change')
    const getHome = document.getElementById('HOME')
    if (isBLVDVisible) {
      colorChange.forEach(function (el) {
        el.classList.add('black')
      })
    } else if (checkVisible(getHome) && !isBLVDVisible) {
      colorChange.forEach(function (el) {
        el.classList.remove('black')
      })
    }
  }, [isBLVDVisible])

  const LABELS_INFO = [
    `WE THINK YOU'LL LIKE IT HERE`,
    'WELCOME TO THE BLVD',
    `WE THINK YOU'LL LIKE IT HERE`,
    'WELCOME TO THE BLVD',
    `WE THINK YOU'LL LIKE IT HERE`,
    'WELCOME TO THE BLVD',
    `WE THINK YOU'LL LIKE IT HERE`,
    'WELCOME TO THE BLVD',
    `WE THINK YOU'LL LIKE IT HERE`,
    'WELCOME TO THE BLVD',
  ]
  return (
    <MarqueeContainer className="BLVD" id="ISABELLE">
      <Flex
        sx={{
          position: 'relative',
          width: '100%',
          height: '5.143rem',
          borderBottom: ' 1px solid #000000',
          minHeight: '5.143rem',
        }}
      >
        <Marquee sx={{ color: '#000' }}>
          <Wrapper>
            {LABELS_INFO.map((label, i) => (
              <SpanWrapper key={`${label}-${i}`}>
                <span>{label}</span>
                <span> - </span>
              </SpanWrapper>
            ))}
          </Wrapper>
        </Marquee>
      </Flex>
      <Flex
        sx={{
          position: 'relative',
          width: '100%',
          alignItems: 'center',
          my: ['3rem', '7rem'],
          px: ['1rem', '2rem', '2rem', '4rem', '12rem'],
          flexDirection: ['column', 'column', 'column', 'row', 'row'],
        }}
      >
        <Box
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <ImgContainer sx={{ width: ['100%', '100%', '100%', '35vw', '26vw'] }}>
            <Image
              sx={{ width: ['100%', '100%', '100%', '80%', '100%'] }}
              src={Isabelle}
              alt="Isabelle Lago | Nomad Boulevard"
            />
          </ImgContainer>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          data-aos="fade-up"
          // data-aos-offset="150"
          data-aos-anchor-placement="top-bottom"
        >
          <Text variant="blvd" sx={{ fontFamily: 'Brice H3', marginTop: '2rem' }}>
            ABOUT THE ARTIST
          </Text>
          <Paragraph
            variant="block"
            sx={{ marginBottom: ['2rem'], marginTop: ['1rem', '2rem'], display: 'block' }}
          >
            Isabelle Lago is a renowned painter and muralist, based in Los Angeles. A fine art
            graduate from the University of Southern California, Isabelle found her groove in the
            grassroots art crawl scene and exhibitions of Venice Beach, which is where her iconic
            Isabelle Gorilla character first emerged.{' '}
            <span id="abt-more">
              Today, her murals can be found in city streets, tech start-ups, and private homes in
              Los Angeles and beyond, and her paintings and prints have been purchased by thousands
              of collectors around the world. Isabelle says her art is a reflection of her
              personality and a rebellion against the societal constructs of fame, success and
              status. Her genesis NFT collection, featuring 10 paintings, sold out fast, which
              served as the impetus for Nomad Blvd.
            </span>
            {isOpenReadMore && (
              <span>
                Today, her murals can be found in city streets, tech start-ups, and private homes in
                Los Angeles and beyond, and her paintings and prints have been purchased by
                thousands of collectors around the world. Isabelle says her art is a reflection of
                her personality and a rebellion against the societal constructs of fame, success and
                status. Her genesis NFT collection, featuring 10 paintings, sold out fast, which
                served as the impetus for Nomad Blvd.
              </span>
            )}
            <Link
              variant="block"
              onClick={() => setIsOpenReadMore(prevState => !prevState)}
              sx={{
                display: ['inline', 'none'],
                textDecoration: 'underline',
              }}
            >
              {isOpenReadMore ? 'Read Less' : 'Read More'}
            </Link>
          </Paragraph>
          <Button
            primary
            label="JOIN OUR DISCORD"
            onClick={() => window.open('https://discord.gg/3aBDQUjcgZ', '_blank')}
            backgroundColor="#000"
            color={'#F6F7F1'}
          />
          <div ref={blvdRef}>
            {/* <Button
              primary
              label="JOIN OUR DISCORD"
              onClick={() => window.open('https://discord.gg/3aBDQUjcgZ', '_blank')}
              backgroundColor="#000"
              color={'#F6F7F1'}
            /> */}
          </div>
        </Box>
      </Flex>
    </MarqueeContainer>
  )
}

export default Boulevard
