import { SxProp, Theme } from 'theme-ui'

const defaultButton = {
  border: 0,
  fontFamily: 'body',
  fontWeight: 'normal',
  lineHeight: 'body',
  fontSize: [1, 2],
  paddingX: 3,
  paddingY: 2,
  outline: 'none',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  transition: 'all 0.1s ease-out',
  '&:hover': {
    opacity: 0.9,
  },
} as SxProp

export const Palette = {
  text: '#FFF',
  white: '#fff',
  primary: '#111',
  secondary: '#333',
  gray: '#F7F9FA',
  lightGray: '#e0e0e0',
  midGray: '#222',
  lightBlue: '#EFF7FF',
  navy: '#e0e0e0',
  green: 'rgb(99, 255, 203)',
  amber: '#D58408',
  lightGreen: '#AEC',
  muted: '#999',
  transparent: 'transparent',
  white20: 'rgba(255, 255, 255, .2)',
  black66: 'rgba(0, 0, 0, .66)',
  dark: '#161616',
  coolYellow: '#faff20',
  black: '#000',
  red: '#FA301B',
  orangeFlame: '#E1602F',
}

export type PaletteKeys = keyof typeof Palette

export const Sizes = {
  header: 60,
  container: 1020,
  button: [100, 250],
  logo: 60,
  sidemenu: 240,
  chart: 950,
}

export default {
  breakpoints: ['40em', '48em', '64em', '80em', '96em', '160em'],
  space: [0, 4, 10, 16, 18, 20, 30, 42, 56, 78, 125, 150],
  fontSizes: [10, 12, 16, 22, 26, 36, 50, 75, 96],
  fonts: {
    body: 'Prayuth, Brice, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading: 'inherit',
  },

  container: {
    display: 'contents',
  },
  sizes: {
    ...Sizes,
  },
  cards: {
    nft: {
      bg: '#f0f0f0',
      border: '2px solid #fff',
      borderColor: 'muted',
      textAlign: 'left',
      position: 'relative',
    },
    transaction: {
      bg: '#72D2AF',
      position: 'fixed',
      top: 50,
      right: 20,
      p: 4,
      borderRadius: 10,
    },
  },
  links: {
    nav: {
      color: 'white',
      cursor: 'pointer',
      transition: 'all .2s ease-out',
      fontSize: '0.938rem',
      '&:hover': {
        opacity: 0.8,
      },
    },
    openSea: {
      color: 'green',
      fontSize: 1,
      opacity: 0.5,
      '&:after': {
        content: `url("data:image/svg+xml,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.262 0l2.529 2.524L0 6.314 1.685 8l3.79-3.79L8 6.732V0z' fill='rgb(99, 255, 203)' fill-rule='nonzero' /%3E%3C/svg%3E")`,
        ml: 1,
        filter: 'brightness(0.5)',
      },
      transition: 'all .2s ease-out',
      '&:hover': {
        opacity: 1,
        color: '#2081e2',
      },
    },
    owner: {
      color: '#161616',
      fontSize: 1,
      opacity: 0.5,
      transition: 'all .2s ease-out',
      fontFamily: 'VCR OSD MONO',
      '&:hover': {
        opacity: 1,
        color: '#161616',
      },
      '&:after': {
        content: `url("data:image/svg+xml,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.262 0l2.529 2.524L0 6.314 1.685 8l3.79-3.79L8 6.732V0z' fill='white' fill-rule='nonzero' /%3E%3C/svg%3E")`,
        ml: 1,
      },
    },
    block: {
      fontFamily: 'Prayuth Regular',
      fontSize: '1.25rem',
      color: '#000',
      letterSpacing: '0.8px',
      display: 'flex',
    },
  },
  buttons: {
    primary: {
      ...defaultButton,
      fontWeight: 'bold',
      minWidth: 'button',
      bg: 'primary',
      color: 'white',
      textAlign: 'center',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },

    secondary: {
      cursor: 'pointer',
      variant: 'buttons.primary',
      bg: 'secondary',
      fontWeight: 'body',
    },
    tertiary: {
      variant: 'buttons.secondary',
      color: 'white',
      bg: 'primary',
    },
    quartiary: {
      variant: 'buttons.tertiary',
      bg: 'green',
      color: 'black',
      minWidth: 'auto',
      py: 2,
    },
    filter: {
      variant: 'buttons.tertiary',
      bg: 'lightGreen',
      color: 'black',
      minWidth: 'auto',
      py: 1,
      fontSize: 1,
      '&:disabled': {
        pointerEvents: 'none',
        opacity: 0.5,
      },
    },
    connect: {
      variant: 'buttons.tertiary',
      py: 4,
      px: 5,
    },
    nav: {
      fontFamily: 'monospace',
      fontWeight: 'bold',
      bg: 'coolYellow',
      color: 'black',
      fontSize: 22,
      textTransform: 'uppercase',
      py: 25,
      px: 25,
      '&:hover': {
        opacity: 0.9,
        color: 'black',
      },
    },
  },
  fontWeights: {
    heading: 300,
    body: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.333333333,
    heading: 1.066666667,
    subheading: 2,
  },
  colors: {
    ...Palette,
  },
  divider: {
    nft: {
      color: 'muted',
      height: 1,
      my: 2,
    },
  },
  forms: {
    input: {
      paddingX: 2,
      paddingY: 2,
      borderRadius: '5px',
      bg: 'white',
      color: 'primary',
    },
  },
  text: {
    body: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: 1,
    },
    heading: {
      py: 0,
    },
    bold: {
      fontWeight: 'bold',
    },
    paragraph: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 2,
      fontSize: 2,
      letterSpacing: 0.44,
      color: 'white',
    },
    h1: {
      fontFamily: 'Brice H1',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 6,
      letterSpacing: -0.44,
    },
    h2: {
      fontFamily: 'Brice',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
      letterSpacing: -0.44,
    },
    h3: {
      fontFamily: 'heading',
      lineHeight: 'subheading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      fontFamily: 'heading',
      lineHeight: 'subheading',
      fontWeight: 'bold',
      fontSize: [1, 2],
    },
    h5: {
      fontFamily: 'heading',
      fontWeight: 'bold',
      lineHeight: 1.6,
      fontSize: 0,
      letterSpacing: 1.5,
    },
    blvd: {
      fontFamily: 'Brice H3',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: ['2.25rem', '2.25rem', '2.25rem', '2.25rem', '2.25rem', '3.25rem'],
      letterSpacing: -0.44,
      color: 'orangeFlame',
    },
    block: {
      fontFamily: 'Prayuth Regular',
      fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem', '2.25rem'],
      color: '#000',
      letterSpacing: '0.8px',
      display: 'flex',
    },
    blockLight: {
      fontFamily: 'Prayuth Light',
      fontWeight: 300,
      fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem', '2.25rem'],
      color: '#FFF',
      letterSpacing: '0.8px',
      display: 'block',
      textTransform: 'uppercase',
    },
    passTitle: {
      fontFamily: 'Brice H3',
      fontSize: 'clamp(1.5rem, 2.05vw, 2.25rem)',
      color: '#000',
      marginBottom: '1.375rem',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      maxWidth: '100%',
    },
    a: {
      color: 'primary',
      '&:hover': {
        color: 'inherit',
      },
    },
    hr: {
      height: 1,
      opacity: 0.5,
      bg: 'black',
      color: 'black',
      my: 6,
    },
  },
  images: {
    avatar: {
      backgroundColor: '#A974FF!important',
    },
  },
} as Theme
