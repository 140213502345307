import styled from 'styled-components'

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 20;
  transition: opacity 750ms ease;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
`
