import styled from 'styled-components'
import { Box } from 'theme-ui'

export const Container = styled(Box)`
  width: 100%;
  padding: 13vw 8vw 8vw;
  border-top-left-radius: 50vw;
  border-top-right-radius: 50vw;
  background-color: #91BBBE;

  svg {
    width: 100%;
    height: auto;
  }

  .vertical {
    display: none;
  }
  @media screen and (max-width: 63rem) {
    padding: 13vw 0 0;
    .square {
      display: none;
    }
    .vertical {
      display: block;
    }
  }
`

export const InfoContainer = styled(Box)`
  width: 100%;
  padding: 4rem 0 0;
  display: flex;
  color: #000;
  @media screen and (max-width: 63rem) {
    padding: 0 5vw 4vw;
    flex-direction: column;
    transform: translateY(-11%);
  }
  @media screen and (max-width: 28rem) {
    transform: translateY(-3%);
  }
`