import styled, { css } from 'styled-components'
import { Box, Image } from '@theme-ui/components'

export const AccordionContainer = styled(Box)<{ isRoadmap?: boolean | any }>`
  max-width: 100%;
  margin: 2rem auto;

  ${({ isRoadmap }) => css`
    ${isRoadmap &&
    css`
      .accordion-item {
        border-radius: 20px;
        background-color: #000;
      }
    `}
  `}
`

export const TitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 2rem !important;
`

export const ContentBox = styled(Box)`
  padding: 0.781rem;
  text-align: left;
`
export const ArrowBox = styled(Box)`
  display: flex;
  margin: 1.25rem !important;
  max-width: 1.3rem;
  width: 100%;
`
export const StyledImage = styled(Image)`
  min-width: 1.3rem !important;
  max-width: 1.3rem !important;
`
export const AccordionItem = styled.div`
  background-color: #000;
  border-radius: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid black;
    background-color: #000;
    border-radius: 20px;
  }
`

export const AccordionTitle = styled.h3`
  margin: 0;
  padding: 0.3rem;
  cursor: pointer;
  background-color: #000;
`

export const AccordionBody = styled.div<{ active?: any; bodyHeight?: any }>`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s;

  ${({ active, bodyHeight }: any) =>
    active &&
    css`
      height: ${bodyHeight}px;
    `}
`
export const AccordionContent = styled.div`
  margin: 0;
  padding: 0 1rem 1rem;
  height: auto;
`
