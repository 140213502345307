import { useEffect, useState, useCallback, useRef } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { blackVideoURL, goldenVideoURL } from '../../../utils/constants/Passes'
import {
  PassesContainer,
  CollectButton,
  LoadWrapper,
  FindingLabel,
  LinkLabel,
} from '../Exchange.styles'
import { NotFound } from './NotFound'
import { PassItem } from './PassItem'
import useContract from '../../../hooks/useContract'
import { useHistory } from 'react-router-dom'

const FindingBLVD = ({ setFullH }: any) => {
  useEffect(() => setFullH(true), [setFullH])
  return (
    <LoadWrapper>
      <div className="wrapper-video">
        <video autoPlay loop muted playsInline id="video-id" className="video">
          <source
            src="https://cdn.kingtide.dev/nomadblvd/segway_animation_fixed.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <FindingLabel variant="passTitle" sx={{ color: '#F6F7F4', textAlign: 'center' }}>
        FINDING THE BOULEVARD<span>.</span>
        <span>.</span>
        <span>.</span>
      </FindingLabel>
    </LoadWrapper>
  )
}
const Passes = ({ setFullH }: any) => {
  const {
    user,
    availablePass,
    transaction,
    setUser,
    setTransactionCompleted,
    redeemPasses,
    setLoadingTransaction,
    updateTokensOnSale,
    setTransaction,
  } = useContract()
  const transactionRef = useRef(transaction)
  const history = useHistory()

  const [founded, setFounded] = useState(false)
  const [passes, setPasses] = useState({ black: 0, gold: 0 })
  const [founderPassQty, setFounderPassQty] = useState(0)
  const [collectorPassQty, setCollectorPassQty] = useState(0)

  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => setFullH(true), [setFullH])

  useEffect(() => {
    const { black, gold } = availablePass
    setPasses({ black: black.passMinted, gold: gold.passMinted })
    if (passes.black > 0 || passes.gold > 0) {
      setFounded(true)
    }
  }, [availablePass, passes.black, passes.gold])

  const update = useCallback(async () => {
    history.push('/my-nomads')
    await setUser!()
    setTransaction!(undefined)
    updateTokensOnSale!()
    setLoading(false)
    setTransactionCompleted!(true)
    setLoadingTransaction!(false)
  }, [
    history,
    setTransaction,
    setUser,
    updateTokensOnSale,
    setTransactionCompleted,
    setLoadingTransaction,
  ])

  useEffect(() => {
    const execute = async () => {
      try {
        transactionRef.current = transaction
        if (!transaction) return
        setLoading(true)
        setLoadingTransaction!(true)
        const receipt = await transaction.wait()
        if (receipt.confirmations >= 1) {
          update()
        }
      } catch (e) {
        console.log('transaction', e)
        setLoading(false)
      }
    }
    if (transaction) execute()
  }, [update, transaction, setLoadingTransaction])

  const handleExchangePass = useCallback(() => {
    redeemPasses!(founderPassQty, collectorPassQty)
  }, [founderPassQty, collectorPassQty, redeemPasses])

  return (
    <PassesContainer isLoading={isLoading}>
      {!founded && !isLoading ? (
        <NotFound />
      ) : isLoading ? (
        <FindingBLVD setFullH={setFullH} />
      ) : (
        <Flex
          sx={{
            paddingX: ['1rem', '5rem'],
            paddingY: '2.5rem',
            flexDirection: 'column',
            width: '100%',
            zIndex: 1,
          }}
        >
          <Text variant="passTitle">SELECT PASS to begin exchange</Text>
          <Text
            variant="passTitle"
            sx={{
              fontSize: 'clamp(0.725rem, 1.1vw, 1.125rem)',
            }}
          >
            by exchanging multiple nomads at once you will save on gas
          </Text>
          {passes.black > 0 && (
            <PassItem
              id="founder"
              video={blackVideoURL}
              title="FOUNDER PASS"
              total={passes.black}
              setFounderPassQty={setFounderPassQty}
            />
          )}
          {passes.gold > 0 && (
            <PassItem
              id="collector"
              video={goldenVideoURL}
              title="COLLECTOR PASS"
              total={passes.gold}
              setCollectorPassQty={setCollectorPassQty}
            />
          )}
          <Box sx={{ width: '100%', marginTop: '1rem', textAlign: 'center' }}>
            <CollectButton
              disabled={!founderPassQty && !collectorPassQty}
              sx={{ width: '100%', maxWidth: '100%' }}
              onClick={() => handleExchangePass()}
            >
              exchange for nomad
            </CollectButton>
            {user?.ownedTokens?.length ? (
              <LinkLabel onClick={() => history.push('/my-nomads')}>My Nomads</LinkLabel>
            ) : null}
          </Box>
        </Flex>
      )}
    </PassesContainer>
  )
}
export { Passes }
