// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useCallback, useState } from 'react'
import ReactPlayer from 'react-player'
import styled, { css } from 'styled-components'
import { Box, Text } from 'theme-ui'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import { OPENSEA_URL, NFT_URL, FOUNDER_URL } from '../../utils'
import { ON_ERROR_IMAGES } from '../../utils/constants/Passes'
import * as S from './Profile.styles'

export const Icon = styled(CloseIcon)<{ open: boolean }>`
  ${({ open }) => css`
    transform: ${!open ? 'rotate(45deg)' : 'rotate(0deg)'};
  `}
  cursor: pointer;
`
function PassCard({ quantity, name, video, type, isToken, src, id, isFounder }: any) {
  const [open, setOpen] = useState(true)
  const [onVideoError, setVideoError]: any = useState(false)

  const handleDetectError = useCallback(() => setVideoError(true), [setVideoError])
  return (
    <S.Card open={open}>
      <Text style={{ color: '#000000' }}>{name}</Text>
      <div>
        {!isToken ? (
          <>
            {!onVideoError ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                id="video-id"
                className="react-player"
                width="100%"
                height="100%"
                onError={({ type }) => (type === 'error' ? handleDetectError() : null)}
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              // <ReactPlayer
              //   loop
              //   playing
              //   url={video}
              //   onError={({ type }) => (type === 'error' ? handleDetectError() : null)}
              // />
              <img alt={id} className="react-player" src={ON_ERROR_IMAGES[id]} />
            )}
          </>
        ) : (
          <img style={{ maxWidth: '100%' }} alt={name} src={src} />
        )}
      </div>
      {quantity ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#000',
          }}
        >
          <Text sx={{ fontFamily: 'Prayuth Regular' }}>Quantity: x{quantity}</Text>
        </Box>
      ) : null}

      <S.ActionButton
        onClick={() =>
          window.open(
            isToken ? `${NFT_URL}/${id}` : isFounder ? FOUNDER_URL : OPENSEA_URL,
            '_blank'
          )
        }
      >
        VIEW ON OPENSEA
      </S.ActionButton>
    </S.Card>
  )
}

export default PassCard
