import ReactPlayer from 'react-player'
import styled, { css } from 'styled-components'
import { Box, Button, Flex, Input, Text } from 'theme-ui'
import TheNomads from '../../assets/nomad/TheNomads/nomads.png'

export const Container = styled.section<{ fullH?: any; minH?: any }>`
  ${({ fullH }) => css`
    height: ${fullH ? '100%' : '110vh'};
    width: 100%;
    background-color: #18262b;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    @media screen and (max-width: 64em) {
      height: ${fullH ? '100%' : '100vh'};
      min-height: 100vh;
    }
    @media screen and (max-width: 20em) {
      height: ${fullH ? '100%' : '150vh'};
      min-height: 100vh;
    }

    @media screen and (max-width: 215em) {
      height: ${fullH ? '100%' : '100vh'};
    }
  `}
`

export const Wrapper = styled(Flex)`
  padding: 5rem 1rem !important;
  text-align: center;
  z-index: 1;
  transform: translate(0px, -3.5vw);

  @media screen and (max-width: 64em) {
    padding-top: 10rem !important;
  }
  @media screen and (max-width: 20em) {
    padding-top: 0rem !important;
  }
  @media screen and (min-width: 215rem) {
    height: 100%;
    justify-content: start;
    transform: translate(0px, 0px);
  }
`

export const CollectButton = styled(Button)`
  width: 100%;
  max-width: 28.938rem;
  font-family: Brice H3 !important;
  font-weight: normal !important;
  font-size: 2.25rem !important;
  background-color: #eb602d !important;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  border-radius: 0 !important;
  padding: 1.8rem !important;
  margin: 2.188rem 0 !important;
  cursor: pointer;

  &:disabled {
    background-color: #c9d5d3 !important;
  }
  @media screen and (max-width: 64em) {
    max-width: 20.938rem;
    font-size: 1.425rem !important;
    padding: 1.25rem !important;
  }
`
export const ImageBox = styled(Box)`
  background-image: url(${TheNomads});
  background-size: cover;
  background-position: center 18rem;
  background-repeat: no-repeat;
  height: 37rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
  @media screen and (max-width: 160rem) {
    height: 90%;
  }
  @media screen and (max-width: 64em) {
    height: 19rem;
    background-position: center 5rem;
  }
  @media screen and (min-width: 160rem) {
    background-position: center 0rem;
    height: 48rem;
  }
  @media screen and (min-width: 215rem) {
    height: 60rem;
  }
`

export const PassesContainer = styled(Box)<{ isLoading?: any }>`
  background-color: #f6f7f4;
  display: flex;
  max-height: 62rem;
  height: 100%;
  margin: 14rem 7.5rem !important;
  @media screen and (max-width: 64em) {
    margin: 12rem 0.75rem !important;
    ${({ isLoading }) => css`
      ${isLoading &&
      css`
        max-width: 75vw;
      `}
    `}
  }

  ${({ isLoading }) => css`
    ${isLoading &&
    css`
      border: 25px solid #91bbbe;
      display: flex;
      max-width: 60vw;
    `}
  `}
`

export const PassText = styled(Flex)`
  height: 100%;
  min-width: 22rem !important;
  @media screen and (max-width: 75em) {
    min-width: 15rem !important;
  }
`

export const PassInput = styled.input`
  height: 100%;
  max-width: 10rem !important;
  max-height: 8.563rem !important;
  min-width: auto !important;
  padding: 0 !important;
  border: none !important;
  border-radius: none !important;
  font-family: 'BRICE H3';
  font-size: 2.25rem;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  &:focus-visible {
    border: none !important;
    outline: none;
  }
  @media screen and (max-width: 75em) {
    max-width: 8rem !important;
    max-height: 7.3rem !important;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 64em) {
    max-width: 5rem !important;
    max-height: 4.3rem !important;
    font-size: 1.5rem;
  }
`
export const CounterButton = styled(Button)`
  background-color: transparent !important;
  color: #000000 !important;
  font-family: Brice H3 !important;
  width: 1.563rem !important;
  height: 1.563rem !important;
  font-size: 2.25rem !important;
  font-weight: normal !important;
  padding: 0 !important;
  margin: 0rem 1.5rem !important;
  @media screen and (max-width: 64em) {
    margin: 0rem !important;
  }
`

export const FounderBox = styled(Box)`
  display: flex !important;
  flex-direction: row;
  min-width: fit-content !important;
  .react-player {
    min-height: 14rem;
    max-width: 14rem;
  }
  @media screen and (max-width: 75em) {
    .react-player {
      width: 12rem !important;
      min-height: 12rem !important;
    }
  }

  @media screen and (max-width: 64em) {
    flex-direction: column;
    .react-player {
      width: 9rem !important;
      min-height: 9rem !important;
    }
  }
`
export const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
`
export const LoadWrapper = styled.div`
  display: flex;
  .wrapper-video {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .video {
    width: 100%;
    color: #000;
    object-fit: cover;
  }

  @media screen and (max-width: 64em) {
    height: 75vh;
    img#nomad {
      object-fit: cover;
      object-position: 25% bottom;
    }
  }
`

export const FindingLabel = styled(Text)`
  position: absolute;
  top: 10rem;

  @keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
      opacity: 0.2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
      opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
      opacity: 0.2;
    }
  }

  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
  @media screen and (max-width: 64em) {
    font-size: 1rem !important;
  }
`

export const NomadBox = styled(Box)`
  min-height: 100vh;
  height: 100%;
  margin: 10rem 0 !important;
  @media screen and (max-width: 64em) {
    padding: 1.5rem;
  }
`

export const LinkLabel = styled.span<{ color?: any }>`
  ${({ color }) => css`
    color: ${color ? color : '#9e9e9e'};
  `}
  font-size: 1.5rem;
  // color: #9e9e9e;
  &:hover {
    color: #91bbbe;
    cursor: pointer;
  }
`

export const ProfileButton = styled(Button)`
  width: 300px;
  max-width: 300px !important;
  margin: 0 auto !important;
  background-color: #eb602d !important;
  font-family: Brice H3 !important;
  font-weight: normal !important;
  border-radius: 0 !important;
  padding: 1rem !important;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

export const TokenVideo = styled.div`
  width: 300px;
  height: auto;

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
`
