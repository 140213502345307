import { utils } from 'ethers'
import React from 'react'
import styled from 'styled-components'
import { ActionButton } from '../../common/Button/ButtonStyles'
import * as S from './MintSectionStyles'

export const Link = styled.span`
  text-decoration: underline;
  text-decoration-color: #000;
  cursor: pointer;
`
export const ActionMint = ({
  quantity,
  setQuantity,
  TOTAL_AVAILABLE_PASS,
  handlePurchase,
  passPrice,
  disabled,
}: any) => {
  return (
    <>
      <S.MintSection>
        <S.ActionWrapper>
          <ActionButton
            onClick={() =>
              quantity > 1 && setQuantity((currentStatus: number) => currentStatus - 1)
            }
          >
            <svg
              width="33"
              height="3"
              viewBox="0 0 33 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1.5"
                y1="1.5"
                x2="31.5"
                y2="1.5"
                stroke="#F6F7F4"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </ActionButton>
          <S.Amount>{quantity}</S.Amount>
          <ActionButton
            onClick={() =>
              quantity < TOTAL_AVAILABLE_PASS &&
              setQuantity((currentStatus: number) => currentStatus + 1)
            }
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.097 14.6626L17.097 1.82671C17.0728 1.51406 16.9315 1.22206 16.7014 1.00904C16.4712 0.796032 16.1692 0.677701 15.8556 0.677701C15.542 0.677702 15.24 0.79603 15.0099 1.00904C14.7797 1.22205 14.6385 1.51406 14.6143 1.82671L14.6143 14.6626L1.76956 14.6538C1.45691 14.678 1.16491 14.8193 0.951897 15.0494C0.738885 15.2795 0.620556 15.5816 0.620556 15.8952C0.620556 16.2087 0.738885 16.5108 0.951896 16.7409C1.16491 16.971 1.45691 17.1123 1.76956 17.1365L14.6143 17.1453L14.6055 29.99C14.5922 30.1611 14.6145 30.3331 14.6709 30.4951C14.7273 30.6572 14.8166 30.8059 14.9331 30.9318C15.0497 31.0577 15.191 31.1582 15.3482 31.2269C15.5055 31.2956 15.6752 31.3311 15.8468 31.3311C16.0184 31.3311 16.1882 31.2956 16.3454 31.2269C16.5026 31.1582 16.644 31.0577 16.7605 30.9318C16.8771 30.8059 16.9664 30.6572 17.0227 30.4951C17.0791 30.3331 17.1014 30.1611 17.0882 29.99L17.097 17.1453L29.9329 17.1453C30.2455 17.1211 30.5375 16.9798 30.7505 16.7497C30.9636 16.5196 31.0819 16.2175 31.0819 15.904C31.0819 15.5904 30.9635 15.2883 30.7505 15.0582C30.5375 14.8281 30.2455 14.6868 29.9329 14.6626L17.097 14.6626Z"
                fill="#F6F7F4"
              />
            </svg>
          </ActionButton>
        </S.ActionWrapper>
        <S.MintLabel onClick={handlePurchase} disabled={disabled}>
          Mint Now
        </S.MintLabel>
        <S.TotalETH>Total: {utils.formatEther(passPrice.mul(quantity))}ETH</S.TotalETH>
      </S.MintSection>
    </>
  )
}
