import styled, { css } from 'styled-components'
import { Box, Image, Text } from '@theme-ui/components'

export const AccordionContainer = styled(Box)`
  max-width: 100%;
  width: 100%;
  display: flex;
  margin: 0 0 4rem;
  justify-content: space-between;

  @media screen and (max-width: 63rem) {
    flex-direction: column;
  }
`

export const AccordionItem = styled.div<{ itemWidth?: any; isBordered?: any }>`
  ${({ itemWidth, isBordered }) => css`
    width: ${itemWidth?.length ? itemWidth : '45%'};
    margin: 0 0 4rem;
    border-bottom: ${isBordered ? '1px solid #000000' : 'none'};

    h3 {
      font-family: ${itemWidth?.length ? 'Brice H2' : 'Brice H3'};
    }
    @media screen and (max-width: 63rem) {
      width: 100%;
    }
  `}
`

export const TitleBox = styled(Box)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

export const ArrowBox = styled(Box)<{ centerMode?: boolean }>`
  ${({ centerMode }) => css`
    width: 100%;
    max-width: 2.5rem;
    display: flex;
    align-self: ${!centerMode ? 'center' : 'flex-end'};

    // margin-bottom: 0.25rem !important;
    // transform: translateY(-50%);

    .close {
      transition: 350ms ease;
    }

    &:hover {
      .close {
        fill: #000;
        path {
          stroke: ${!centerMode ? '#c9d5d3' : '#91BBBE'};
        }
      }
    }
  `}
`

export const StyledImage = styled(Image)`
  min-width: 2.5rem !important;
  max-width: 2.5rem !important;
`

export const AccordionBody = styled.div<{ active?: any; bodyHeight?: any }>`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s;

  ${({ active, bodyHeight }: any) =>
    active &&
    css`
      height: ${bodyHeight}px;
    `}
`

export const AccordionContent = styled.div`
  margin: 0;
  padding: 0 0rem 1rem;
  height: auto;
`

export const List = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  list-style: none;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
`
export const ListItem = styled.li`
  margin-bottom: 1rem;
  font-family: 'Prayuth Regular';
  font-size: clamp(0.75rem, 1vw, 1.25rem);
  font-weight: 400;
  line-height: 142%;
  color: #000;
  &:before {
    content: '•';
    position: absolute;
    margin-left: -1em;
  }
`

export const TextItem = styled(Text)`
  margin-bottom: 1rem;
  font-family: 'Prayuth Regular';
  font-size: clamp(1rem, 0.8vw, 1rem);
  font-weight: 400;
  line-height: 142%;
  color: #000;
`
