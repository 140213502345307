import React from 'react'
import styled from 'styled-components'
import { Box, Label } from 'theme-ui'
import { ActionMint } from './ActionMint'

export const Link = styled.span`
  text-decoration: underline;
  text-decoration-color: #000;
  cursor: pointer;
`

export const Mint = ({
  TOTAL_MURALS,
  TOTAL_PASS_MINTED,
  quantity,
  setQuantity,
  TOTAL_AVAILABLE_PASS,
  handlePurchase,
  passPrice,
  disabled,
  pass,
}: any) => {
  return (
    <>
      <Box sx={{ display: 'block' }}>
        <Label
          sx={{
            justifyContent: 'center',
            fontSize: '2.25rem',
            fontFamily: 'Brice H3',
            lineHeight: '2.313rem',
            letterSpacing: '0.72px',
            color: '#E1602F',
            margin: ['2.125rem 0 1.625rem'],
          }}
        >
          NOMAD BLVD
        </Label>
        <Label sx={{ justifyContent: 'center', color: '#000', marginBottom: ['1rem'] }}>
          COLLECTOR MINT PASS <br />
        </Label>
        {TOTAL_MURALS > 0 && pass === 'black' ? (
          <>
            <Label sx={{ justifyContent: 'center', color: '#000', marginBottom: ['1rem'] }}>
              {`Yow own ${TOTAL_MURALS} mural${
                TOTAL_MURALS > 1 ? 's' : ''
              } and ${TOTAL_PASS_MINTED} nomads.`}
              You can mint up to 5 nomads per mural.
            </Label>
            <ActionMint
              quantity={quantity}
              setQuantity={setQuantity}
              TOTAL_AVAILABLE_PASS={TOTAL_AVAILABLE_PASS}
              handlePurchase={handlePurchase}
              passPrice={passPrice}
              disabled={disabled}
            />
          </>
        ) : pass === 'gold' ? (
          <>
            <Label sx={{ justifyContent: 'center', color: '#000', marginBottom: ['1rem'] }}>
              You can mint up to {TOTAL_AVAILABLE_PASS} per wallet.
            </Label>
            <ActionMint
              quantity={quantity}
              setQuantity={setQuantity}
              TOTAL_AVAILABLE_PASS={TOTAL_AVAILABLE_PASS}
              handlePurchase={handlePurchase}
              passPrice={passPrice}
              disabled={disabled}
            />
          </>
        ) : (
          <>
            <Label sx={{ justifyContent: 'center', color: '#000', marginBottom: ['1rem'] }}>
              You don't own any mural.
            </Label>
            <Label sx={{ justifyContent: 'center', color: '#000', marginBottom: ['3.625rem'] }}>
              You can <Link onClick={() => window.open('#', '_blank')}>grab one here.</Link>
            </Label>
          </>
        )}
      </Box>
    </>
  )
}
