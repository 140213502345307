import styled, { css } from 'styled-components'

export const Container = styled.section`
  height: 100%;
  min-height: 100vh;
  background: #c9d5d3;
  display: flex;
  padding: 10rem 15%;
  padding-bottom: 0;
  flex-direction: column;
  @media screen and (max-width: 64em) {
    padding-left: 5%;
    padding-right: 5%;
  }
  body {
    background: #c9d5d3;
  }

  @media screen and (min-width: 815px) {
    padding: 10rem 15%;
  }
`

export const CardContainer = styled.div`
  display: grid;
  grid-gap: 2.3rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`

export const Title = styled.h1`
  font-family: Brice H2;
  font-style: normal;
  font-weight: normal;
  font-size: 4.728rem;
  line-height: 119px;
  /* identical to box height, or 157% */

  text-align: left;
  letter-spacing: -2.56481px;

  color: #000000;
  margin: 0;

  @media screen and (max-width: 20rem) {
    font-size: 2.728rem;
  }
`
export const Subtitle = styled.h2`
  font-family: Brice H2;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25rem;
  /* identical to box height, or 329% */

  text-align: left;
  letter-spacing: -0.564806px;

  color: #000000;
  margin: 0;

  @media screen and (max-width: 20rem) {
    font-size: 2rem;
  }
`
export const Address = styled.p`
  font-family: Prayuth Regular;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(1rem, 1.5vw, 1.25rem);
  margin-bottom: 3.375rem;
  margin-top: 0;
  padding: 0 0.5rem;
  /* identical to box height, or 186% */

  text-align: left;
  text-transform: uppercase;

  color: #000000;
  word-break: break-all;
`
export const Card = styled.div<{ open: boolean }>`
  ${({ open }) => css``}
  display: grid;
  grid-template-rows: auto;
  row-gap: 1rem;
  max-width: 24rem;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(174, 174, 174, 0.25);
  padding: 2.375rem 2.15rem 2.625rem;
`
export const ActionButton = styled.button`
  display: block;
  width: 100%;
  height: 3.875rem;
  background: #000;
  border: none;
  cursor: pointer;
  font-family: 'Prayuth Regular' !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;

  text-align: center;
  letter-spacing: 0.4px;

  color: #ffffff;
`
export const PassTitle = styled.p`
  font-family: Prayuth Regular;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  margin-bottom: 1.75rem;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  /* identical to box height, or 186% */

  text-align: left;
  text-transform: uppercase;

  color: #000000;
  word-break: break-all;
`
