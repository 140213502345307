import { Text } from 'theme-ui'
import { CollectButton, ImageBox, Wrapper } from '../Exchange.styles'

const Collect = ({ handleConnect }: any) => {
  return (
    <>
      <Wrapper
        sx={{
          width: '100%',
          justifyContent: 'start',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Text
          sx={{
            fontFamily: 'Brice H3',
            fontSize: 'clamp(1.5rem, 2.05vw, 2.25rem)',
            color: '#91BBBE',
            marginBottom: '1.375rem',
          }}
        >
          Welcome to the boulevard
        </Text>
        <Text
          sx={{ fontFamily: 'Brice H2', fontSize: 'clamp(2.25rem, 3.525vw, 4rem)', color: '#fff' }}
        >
          Get your nomad, stay awhile
        </Text>
        {/* <Text
          sx={{ fontFamily: 'Prayuth Light', fontSize: 'clamp(1.25rem, 1.25vw, 3rem)', color: '#fff', marginTop:'1.5rem' }}
        >
          Please contact nomad@nomadblvd.io with any questions
        </Text> */}
        <CollectButton onClick={handleConnect}>CONNECT WALLET</CollectButton>
      </Wrapper>
      <ImageBox />
    </>
  )
}
export { Collect }
