export const SET_CONTRACT = '@contract/set'
export const SET_AVAILABLE_PASS = '@available_pass/set'
export const SET_USER = '@user/set'
export const SET_TRANSACTION = '@transaction/set'
export const SET_TOKENS_ON_SALE = '@tokensOnSale/set'
export const SET_ETH_PRICE = '@eth_price/set'
export const SET_ACTIVATING_CONNECTOR = '@activating_connector/set'
export const SET_TRANSACTION_COMPLETED = '@transaction_completed/set'
export const SET_LOADING_TRANSACTION = '@loading_transaction/set'
export const SET_ERROR = '@error/set'
export const RESET_ALL = '@reset/all'
