import { Box, Heading, Paragraph, Text } from '@theme-ui/components'
import { gsap } from 'gsap'
import { useEffect, useCallback, useRef, useState } from 'react'
import CountUp from 'react-countup'
import { Link } from 'react-scroll'
import { ReactComponent as Arrow } from '../../../assets/nomad/home/v2/arrow_down.svg'
import Gorilla from '../../../assets/nomad/home/v3/1.jpg'
import Gorilla10 from '../../../assets/nomad/home/v3/10.jpg'
import Gorilla11 from '../../../assets/nomad/home/v3/11.jpg'
import Gorilla12 from '../../../assets/nomad/home/v3/12.jpg'
import Gorilla2 from '../../../assets/nomad/home/v3/2.jpg'
import Gorilla3 from '../../../assets/nomad/home/v3/3.jpg'
import Gorilla4 from '../../../assets/nomad/home/v3/4.jpg'
import Gorilla5 from '../../../assets/nomad/home/v3/5.jpg'
import Gorilla6 from '../../../assets/nomad/home/v3/6.jpg'
import Gorilla7 from '../../../assets/nomad/home/v3/7.jpg'
import Gorilla8 from '../../../assets/nomad/home/v3/8.jpg'
import Gorilla9 from '../../../assets/nomad/home/v3/9.jpg'
import GorillaExt1 from '../../../assets/nomad/home/v3/ext1.jpg'
import GorillaExt2 from '../../../assets/nomad/home/v3/ext2.jpg'
import GorillaExt3 from '../../../assets/nomad/home/v3/ext3.jpg'
import GorillaExt4 from '../../../assets/nomad/home/v3/ext4.jpg'
import GorillaExt5 from '../../../assets/nomad/home/v3/ext5.jpg'
import GorillaExt6 from '../../../assets/nomad/home/v3/ext6.jpg'
import { useOnScreen } from '../../../hooks/UseOnScreen'
import { MINT_PROCESS, CURRENT_PASS, PROXY_API } from '../../../utils'
import LoadingOverlay from '../../common/LoadingOverlay/LoadingOverlay'
import MintSection from '../MintSection/MintSection'
import axios from 'axios'
import {
  ImageContainer,
  ImgCont,
  InfoContainer,
  TitleContainer,
  TopContainer,
  WrapperContent,
  Container,
} from './HomeStyles'

const SHOW_MINT = MINT_PROCESS === 'true'
console.log(`Mint enable: `, SHOW_MINT)

const HomeSection = () => {
  const [goldLimitReached, setGoldLimitReached] = useState(false)
  const [publicSaleStarted, setPublicSaleStarted] = useState(false)
  const [soldOutReached, setSoldOutReached] = useState(false)

  const [loaded, setLoaded] = useState(false)
  const [delay, setDelay] = useState(0)
  const homeRef = useRef<HTMLDivElement>(null)
  let isHomeVisible = useOnScreen(homeRef)

  gsap.config({
    nullTargetWarn: false,
  })

  let tl = gsap.timeline()

  const img1Ref = useRef(null)
  const img2Ref = useRef(null)
  const img3Ref = useRef(null)
  const imgMRef = useRef(null)
  const title1Ref = useRef(null)
  const title2Ref = useRef(null)
  const title3Ref = useRef(null)
  const textRef = useRef(null)
  const mintButton = useRef(null)

  const g1Ref = useRef(null)
  const g2Ref = useRef(null)
  const g3Ref = useRef(null)
  const g4Ref = useRef(null)

  const gExt1Ref = useRef(null)
  const gExt2Ref = useRef(null)
  const gExt3Ref = useRef(null)
  const gExt4Ref = useRef(null)
  const gExt5Ref = useRef(null)
  const gExt6Ref = useRef(null)

  const g5Ref = useRef(null)
  const g6Ref = useRef(null)
  const g7Ref = useRef(null)
  const g8Ref = useRef(null)
  const g9Ref = useRef(null)
  const g10Ref = useRef(null)
  const g11Ref = useRef(null)
  const g12Ref = useRef(null)
  const gM1Ref = useRef(null)
  const gM2Ref = useRef(null)
  const gM3Ref = useRef(null)
  const gM4Ref = useRef(null)
  const gM5Ref = useRef(null)
  const gM6Ref = useRef(null)

  const tlCard1 = gsap
    .timeline({ paused: true, repeat: -1 })
    .fromTo(g1Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g3Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g4Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g2Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gExt1Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gExt2Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .to(g1Ref.current, { visibility: 'visible' }, '<')

  const tlCard2 = gsap
    .timeline({ paused: true, repeat: -1 })
    .fromTo(gExt3Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g5Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gExt4Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g6Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g7Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g8Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .to(gExt3Ref.current, { visibility: 'visible' }, '<')

  const tlCard3 = gsap
    .timeline({ paused: true, repeat: -1 })
    .fromTo(g9Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g10Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g11Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(g12Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gExt5Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gExt6Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .to(g9Ref.current, { visibility: 'visible' }, '<')

  const tlCardM = gsap
    .timeline({ paused: true, repeat: -1, delay: 0.75 })
    .fromTo(gM1Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gM2Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gM3Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gM4Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gM5Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .fromTo(gM6Ref.current, { visibility: 'visible' }, { visibility: 'hidden', duration: 1 })
    .to(gM1Ref.current, { visibility: 'visible' }, '<')

  useEffect(() => {
    const colorChange = document.querySelectorAll<HTMLElement>('.color-change')

    if (isHomeVisible) {
      colorChange.forEach(function (el) {
        el.classList.remove('black')
      })
    } else {
      colorChange.forEach(function (el) {
        el.classList.add('black')
      })
    }
  }, [isHomeVisible])

  useEffect(() => {
    const mobile = window.matchMedia('(max-width: 40rem)').matches
    if (window.matchMedia('(min-width: 66rem)').matches) {
      tl.to([img1Ref.current, img2Ref.current, img3Ref.current], {
        clipPath: 'inset(0 0 0 0)',
        opacity: 1,
        delay: delay === 0.3 ? 0.3 : 4,
      })
        .to(img1Ref.current, {
          rotate: 6,
          translateX: '140%',
          translateY: '-5%',
          scale: 1.07,
          delay: 0.1,
        })
        .to(
          img2Ref.current,
          { rotate: 3.6, translateX: '10%', translateY: '2%', scale: 1.07, delay: 0.1 },
          '<'
        )
        .fromTo(
          [title1Ref.current, title2Ref.current, title3Ref.current],
          {
            y: 500,
            ease: 'power1.out',
            skewY: 10,
            delay: 1,
            duration: 6,
            stagger: {
              amount: 0.045,
            },
          },
          {
            y: 0,
            skewY: 0,
          }
        )
        .to([title1Ref.current, title2Ref.current, title3Ref.current], {
          y: -500,
          ease: 'power1.out',
          skewY: 10,
          delay: 1.5,
          duration: 2.75,
          stagger: {
            amount: 0.014,
          },
        })
        .to(
          [img1Ref.current, img2Ref.current, img3Ref.current],
          { transform: 'translateY(-22%)', delay: 0.6 },
          '<'
        )
        .fromTo(
          textRef.current,
          {
            y: 200,
            ease: 'power4.out',
            duration: 1.5,
          },
          {
            y: 0,
          },
          '<'
        )
        .to(textRef.current, { opacity: 1, duration: 2.5 }, '<')
        .fromTo(
          mintButton.current,
          {
            y: 200,
            ease: 'power4.out',
            duration: 1.5,
          },
          {
            y: 0,
          },
          '<'
        )
        .add(tlCard1.play())
        .add(tlCard2.play(), '<')
        .add(tlCard3.play(), '<')
    } else {
      tl.to(imgMRef.current, {
        clipPath: 'inset(0 0 0 0)',
        opacity: 1,
        delay: delay === 0.3 ? 0.3 : 4,
      })
        .fromTo(
          [title1Ref.current, title2Ref.current, title3Ref.current],
          {
            y: mobile ? 90 : 220,
            ease: 'power1.out',
            skewY: 10,
            delay: 0.5,
            duration: 5,
            stagger: {
              amount: 0.014,
            },
          },
          {
            y: 0,
            skewY: 0,
          },
          '<'
        )
        .add(tlCardM.play())
    }

    return () => {
      tl.kill()
    }
  }, [delay])

  useEffect(() => {
    if (sessionStorage.getItem('loaded')) {
      setLoaded(true)
      setDelay(0.3)
    } else {
      setDelay(2.8)
      setTimeout(() => {
        sessionStorage.setItem('loaded', JSON.stringify(true))
      }, 3000)
    }
  }, [])

  const getMintingStatus = useCallback(async () => {
    console.log(`Calling ${PROXY_API}/stats`)
    
    const res = await axios.get(`${PROXY_API}/stats`)

    if(res && res.data && res.data.data){
      const availablePass = res.data.data 

      const TOTAL_LIMIT_GOLD  = availablePass ? availablePass[CURRENT_PASS].totalLimitGold : 0
      const TOTAL_GOLD_MINTED = availablePass ? availablePass[CURRENT_PASS].totalGoldMinted : 0
      const MAX_ITEMS = availablePass ? availablePass[CURRENT_PASS].maxGoldItems : 3500

      setPublicSaleStarted(availablePass && availablePass[CURRENT_PASS].publicSaleStarted )
      setGoldLimitReached(TOTAL_GOLD_MINTED > 0 && TOTAL_GOLD_MINTED >= TOTAL_LIMIT_GOLD)
      setSoldOutReached(TOTAL_GOLD_MINTED === MAX_ITEMS)

      console.log(`Limit gold: `, TOTAL_LIMIT_GOLD, `Minted gold: `, 
      TOTAL_GOLD_MINTED, `Pending gold: `,(TOTAL_LIMIT_GOLD-TOTAL_GOLD_MINTED), `Max total: `, MAX_ITEMS)
    }
  }, [])  

  useEffect(() => {   
    getMintingStatus()
  }, [getMintingStatus])

  return (
    <Container
      ref={homeRef}
      id="HOME"
      sx={{
        backgroundColor: '#000',
        position: 'relative',
        width: '100%',
        minHeight: ['140vh', '130vh', '130vh', '100vh'],
        height: '100%',
        zIndex: 0,
      }}
    >
      {!loaded && (
        <LoadingOverlay>
          <CountUp start={0} end={100} duration={3} delay={0}>
            {({ countUpRef }) => (
              <Text sx={{ fontFamily: 'Brice', fontSize: [8] }}>
                <span ref={countUpRef} />%
              </Text>
            )}
          </CountUp>
        </LoadingOverlay>
      )}

      <TopContainer>
        <ImageContainer>
          <ImgCont ref={img1Ref}>
            <img ref={gExt2Ref} src={GorillaExt2} alt="Nomad Boulevard" />
            <img ref={gExt1Ref} src={GorillaExt1} alt="Nomad Boulevard" />
            <img ref={g2Ref} src={Gorilla2} alt="Nomad Boulevard" />
            <img ref={g4Ref} src={Gorilla4} alt="Nomad Boulevard" />
            <img ref={g3Ref} src={Gorilla3} alt="Nomad Boulevard" />
            <img ref={g1Ref} src={Gorilla} alt="Nomad Boulevard" />
          </ImgCont>
          <ImgCont ref={img2Ref}>
            <img ref={g8Ref} src={Gorilla8} alt="Nomad Boulevard" />
            <img ref={g7Ref} src={Gorilla7} alt="Nomad Boulevard" />
            <img ref={g6Ref} src={Gorilla6} alt="Nomad Boulevard" />
            <img ref={gExt4Ref} src={GorillaExt4} alt="Nomad Boulevard" />
            <img ref={g5Ref} src={Gorilla5} alt="Nomad Boulevard" />
            <img ref={gExt3Ref} src={GorillaExt3} alt="Nomad Boulevard" />
          </ImgCont>
          <ImgCont ref={img3Ref}>
            <img ref={gExt6Ref} src={GorillaExt6} alt="Nomad Boulevard" />
            <img ref={gExt5Ref} src={GorillaExt5} alt="Nomad Boulevard" />
            <img ref={g12Ref} src={Gorilla12} alt="Nomad Boulevard" />
            <img ref={g11Ref} src={Gorilla11} alt="Nomad Boulevard" />
            <img ref={g10Ref} src={Gorilla10} alt="Nomad Boulevard" />
            <img ref={g9Ref} src={Gorilla9} alt="Nomad Boulevard" />
          </ImgCont>
          <ImgCont ref={imgMRef}>
            <img ref={gM6Ref} src={GorillaExt2} alt="Nomad Boulevard" />
            <img ref={gM5Ref} src={GorillaExt1} alt="Nomad Boulevard" />
            <img ref={gM4Ref} src={Gorilla8} alt="Nomad Boulevard" />
            <img ref={gM3Ref} src={Gorilla7} alt="Nomad Boulevard" />
            <img ref={gM2Ref} src={Gorilla2} alt="Nomad Boulevard" />
            <img ref={gM1Ref} src={Gorilla4} alt="Nomad Boulevard" />
          </ImgCont>
        </ImageContainer>
        <WrapperContent>
          <TitleContainer>
            <Heading as="h1" variant="h1" sx={{ marginTop: ['60%', '50%', '40%', 0, 0] }}>
              <span ref={title1Ref}>Welcome</span>
            </Heading>
            <Heading as="h1" variant="h1">
              <span ref={title2Ref}>to the</span>
            </Heading>
            <Heading as="h1" variant="h1">
              <span ref={title3Ref}>Boulevard</span>
            </Heading>
          </TitleContainer>
          <InfoContainer>
            <Paragraph
              variant="block"
              sx={{
                minWidth: ['100%', '100%', '100%', '100%', '68rem'],
                color: '#FFF',
                overflow: 'hidden',
                margin: ['1rem 0 2rem', '3.5rem 0', '3.5rem 0', '2rem 0'],
                textAlign: ['left', 'left', 'left', 'center', 'center'],
              }}
            >
              <span id="collection-text" ref={textRef}>
                A collection of 3,500 Nomads created by and based on the iconic works of artist
                Isabelle Lago, including special edition pieces by 15 acclaimed artists. Your Nomad
                NFT doubles as membership to the BLVD, a place where artists, collectors and
                enthusiasts converge and collaborate on the future of the community and brand.
              </span>
            </Paragraph>
            { soldOutReached ? (
              <Box sx={{ display: ['block'], color: 'white', overflow: 'hidden', 
                  fontSize: '30px', 
                  margin: ['1rem 0 2rem', '3.5rem 0', '3.5rem 0', '2rem 0'] }}>
                SOLD &nbsp; OUT               
              </Box>
            ) : (
              <Box sx={{ display: ['block'], color: 'white', overflow: 'hidden' }}>
                <MintSection buttonRef={mintButton} />
              </Box>
            )}
            <Link to="BLVD" smooth={true} offset={0} duration={750}>
              <Arrow className="am" />
            </Link>
          </InfoContainer>
        </WrapperContent>
      </TopContainer>
    </Container>
  )
}

export default HomeSection
