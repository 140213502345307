import { Text } from '@theme-ui/components'
import { useEffect } from 'react'
import FooterSection from '../../components/sectionsV2/FooterSection/FooterSection'
import { Container, Title, Label, TextParagraph, Wrapper } from './TermsStyles'

const SaleTerms = () => {
  useEffect(() => {
    const colorChange = document.querySelectorAll<HTMLElement>('.color-change')
    colorChange.forEach(function (el) {
      el.classList.add('black')
    })
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Container>
        <Title>TERMS OF SALE</Title>
        <Label>Last Modified: December 8th, 2021</Label>
        <TextParagraph>
          These Terms of Sale, which are incorporated into and a part of the Terms of Service,
          govern all sales and purchases of NFTs via the Site (the “Terms of Sale”). Capitalized
          terms used but not defined herein shall have the meanings given to them in the Terms of
          Service. Please read these Terms of Sale carefully before selling or purchasing any NFTs
          via the Site.
        </TextParagraph>
        <Label bold>1. SELLERS</Label>
        <TextParagraph>
          Copyright Ownership. You represent and warrant that you are the sole owner of, including
          all copyrights in and to, the digital asset to be linked to or associated with the
          applicable NFT, including the underlying material (i.e., artwork, text, music, game,
          video, computer code, digitized version, etc.) from which the digital asset was created,
          or which the digital asset embodies, reproduces, relates to or is derived from, in whole
          or in part (collectively, with all such underlying material, the “Linked Digital Asset”),
          or that you have otherwise obtained all rights, consents and clearances necessary to sell
          and distribute the Linked Digital Asset as incorporated in the applicable NFT, in
          accordance with the terms and conditions herein. Additionally, you represent and warrant
          that you are the sole owner of, including all copyrights in and to, the appliable NFT, if
          any, linked with the Linked Digital Asset, or that you have otherwise obtained all rights,
          consents and clearances necessary to grant us a license to sell and distribute the
          applicable NFT in accordance with the terms and conditions herein. Displaying, copying,
          modifying, distributing or creating derivative works of a third-party work (including an
          NFT) without the prior written consent of the copyright owner, including the creation of a
          digital file or digital copy, uploading of an unauthorized digital file, or any derivative
          work of any of the forgoing, is copyright infringement.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Copyright Ownership.</Text> You represent and
          warrant that you are the sole owner of, including all copyrights in and to, the digital
          asset to be linked to or associated with the applicable NFT, including the underlying
          material (i.e., artwork, text, music, game, video, computer code, digitized version, etc.)
          from which the digital asset was created, or which the digital asset embodies, reproduces,
          relates to or is derived from, in whole or in part (collectively, with all such underlying
          material, the “Linked Digital Asset”), or that you have otherwise obtained all rights,
          consents and clearances necessary to sell and distribute the Linked Digital Asset as
          incorporated in the applicable NFT, in accordance with the terms and conditions herein.
          Additionally, you represent and warrant that you are the sole owner of, including all
          copyrights in and to, the appliable NFT, if any, linked with the Linked Digital Asset, or
          that you have otherwise obtained all rights, consents and clearances necessary to grant us
          a license to sell and distribute the applicable NFT in accordance with the terms and
          conditions herein. Displaying, copying, modifying, distributing or creating derivative
          works of a third-party work (including an NFT) without the prior written consent of the
          copyright owner, including the creation of a digital file or digital copy, uploading of an
          unauthorized digital file, or any derivative work of any of the forgoing, is copyright
          infringement.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Right of Publicity.</Text> You represent and
          warrant that, if not your own likeness, you have the written consent of any identifiable
          person appearing in the Linked Digital Asset from which the applicable NFT was created to
          use their name or likeness in such NFT. The use of any third party’s name or likeness for
          commercial purposes without their written consent may violate their right of publicity.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Third-Party Trademarks.</Text> You represent
          and warrant that no third-party trademarks are incorporated into the Linked Digital Asset
          or NFT that could cause a likelihood of confusion on the part of the general public as to:
          (a) the source of origin of the Linked Digital Asset or NFT, or (b) any association,
          affiliation or sponsorship between the trademark owner, you or us. Causing a likelihood of
          confusion on the part of the general public as to the source of origin of a good or
          service or any association, affiliation or sponsorship when none exists, violates the
          trademark owner’s rights.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Copyright Management Information. </Text>You
          represent and warrant that you have not removed or bypassed any anti-circumvention
          devices/protections, removed or obscured any copyright management information from the
          Linked Digital Asset or any part thereof in creating or displaying the NFT.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Ownership.</Text> You represent and warrant
          that you directly or through your licensors are the sole owner of the Linked Digital Asset
          and the NFT being offered for sale and no third party has any claim to such Linked Digital
          Asset or NFT, nor does the consent of any third-party need be obtained to offer the NFT
          for sale or subsequent resale(s) as linked to the Linked Digital Asset.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Inappropriate Material.</Text> You represent
          and warrant that the Linked Digital Asset and the NFT does not contain information or
          material that is malicious, libelous, false, inaccurate, abusive, threatening, obscene,
          defamatory, or racially, sexually, religiously or otherwise objectionable or offensive.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Moral Rights.</Text> You represent and warrant
          that no “moral rights” are being violated by the creation and/or sale of the NFT and
          associated Linked Digital Asset. You hereby irrevocably waive your moral rights to the
          extent permitted by applicable law as necessary for us to operate the Site and conduct the
          Services including as provided for and consistent with the Seller’s License below. Various
          countries have “moral rights” or “droit moral” protecting the integrity of an image or the
          creating artist’s reputation and paternity rights.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Exceptions.</Text> If any of these
          representations are not accurate and you believe that nevertheless you have the right to
          create and sell the NFT, you agree to notify us in writing, in advance of posting or any
          attempting any transaction on the Site, of your reasons and we will review your request
          and in our sole discretion determine if you may sell the NFT on our Site. You will not
          post or offer any NFT or Linked Digital Asset for sale that does not comply with our Terms
          of Service and these Terms of Sale without our prior written consent.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Proof of Provenance.</Text> We have the right
          to require you to provide us with written documentation (i.e., proof of provenance
          (“POP”)) to support the representations and warranties made above. Upon our request, you
          agree to provide us with such written documentation.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Seller’s License.</Text> You grant us a
          perpetual, worldwide, exclusive, irrevocable, transferable, royalty-free license to the
          Linked Digital Asset and any associated materials or content provided to us in connection
          with the Linked Digital Asset to publicly display, copy, distribute and create derivatives
          of it solely for the purposes of creating and selling the NFT and for displaying or
          promoting the sale of the NFT on our Site and for advertising of our Site and Services,
          unless other rights are separately negotiated in a written agreement that is signed by us
          and you. In the case of an NFT owned by you, you hereby grant us a perpetual, worldwide,
          exclusive, irrevocable, transferable, royalty-free license to the NFT to distribute it
          solely for the purpose of displaying, promoting and selling it in connection with the
          Linked Digital Asset on our Site and for advertising of our Site and Services. All other
          rights in and to the Linked Digital Asset are reserved to you.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Bidding.</Text> You agree not to bid on or
          purchase your own offering nor may you have anyone bid on or purchase your offering on
          your behalf.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Exclusive Agency.</Text> You agree that while
          your NFT and associated Linked Digital Asset is being offered on our Site you will not
          offer it on any other site. You agree that once you have posted the NFT and associated
          Linked Digital Asset or otherwise made it available for sale on our Site, you will not
          withdraw it for any reason. Withdrawal or posting on another site will result in you owing
          us an amount equal to the commission we would have received had the NFT been sold on our
          Site, plus any and all costs and expenses (including reasonable attorney fees) incurred by
          us related thereto. In the event of such withdrawal or posting, you shall promptly pay
          such amounts to us.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Display.</Text> You acknowledge and agree that
          we have the complete unfettered right to determine the layout and design of our Site and
          any auction including, without limitation, the manner in which your NFT will be displayed.
        </TextParagraph>
        <Label bold>2. BUYERS</Label>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Purchases.</Text> When you buy an NFT on the
          Site, you will own the NFT and have the right to sell or trade it, subject to any terms
          applicable to such sale or trade.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Buyer’s License.</Text> While you own the NFT
          to which it is linked, you will have a worldwide, perpetual, exclusive, transferable
          license to publicly display the Linked Digital Asset as a whole for your own personal,
          non-commercial purposes, but only in connection with and linked to the associated NFT or
          such other rights separately negotiated in a written agreement that is signed by us and
          you. You will have no other rights in and to the Linked Digital Asset, including no rights
          to copy, prepare derivative works of or distribute the Linked Digital Asset in any way in
          whole or in part. You further acknowledge and agree that purchasing an NFT does not convey
          any right to any physical embodiment of the Linked Digital Asset, any underlying materials
          in or to such Linked Digital Asset or any intellectual property rights in any of the
          foregoing, including copyrights, trademark rights or rights of publicity (e.g., name
          and/or likeness of the artist or anyone appearing in the Linked Digital Asset), unless
          such right is EXPRESSLY granted as a condition of purchase of the applicable NFT by the
          holder of such right.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Limitations.</Text> You agree that you may not
          remove any anti-circumvention devices associated with the Linked Digital Asset or NFT,
          which might include limitations on downloads. You agree not to copy or modify the Linked
          Digital Asset or NFT. You will not remove any watermark, or copyright management
          information such as the name of the artist, copyright owner, copyright notice, watermarks
          or other proprietary notices of the Linked Digital Asset or NFT.
        </TextParagraph>
        <TextParagraph>
          You further agree that terms, conditions and limitations regarding the NFT and associated
          Linked Digital Asset, including its future use, disposition and sharing of related
          revenues, may be incorporated in a “smart contract” or other associated agreement and
          agree to abide by those terms, conditions and limitations.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>KYC Requirements.</Text> In order to make
          certain bids and purchases, we have the right to require you to satisfy our applicable
          cryptocurrency and fiat KYC requirements, including the right to require you to provide us
          or our KYC processors with information and documentation to satisfy such requirements. In
          the event we forward your information and documentation to a third party KYC processor,
          such actions shall be completed with and subject to their terms. We are not responsible
          for any acts or omissions by any third party providers to our Site or Services, including
          any KYC processors.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>DISCLAIMERS.</Text> THE NFT AND LINKED DIGITAL
          ASSET THAT YOU ARE OBTAINING HAS BEEN PROVIDED BY THE SELLER AFTER THE SELLER HAS AGREED
          TO THE REPRESENTATIONS AND WARRANTIES IN SECTION 1 ABOVE AS PART OF THESE TERMS OF SALE.
          NONE OF US OR OUR AFFILIATES IS MAKING ANY REPRESENTATION OR WARRANTY OF ANY KIND (WHETHER
          EXPRESS OR IMPLIED) WITH RESPECT TO THE NFT OR ASSOCIATED LINKED DIGITAL ASSET.
          ACCORDINGLY, YOU HEREBY ACKNOWLEDGE AND AGREE THAT AS BETWEEN YOU AND US AND OUR
          AFFILIATES, AND THEIR DIRECTORS, OFFICERS, SHAREHOLDERS, MEMBERS, EMPLOYEES AND AGENTS,
          YOU ARE OBTAINING THE NFT AND LINKED DIGITAL ASSET “AS IS” AND ON AN “AS AVAILABLE” BASIS.
          WE ARE SPECIFICALLY DISCLAIMING ALL IMPLIED WARRANTIES AS TO THE NFTS AND ASSOCIATED
          LINKED DIGITAL ASSETS YOU OBTAIN ON OUR SITE, INCLUDING ANY IMPLIED WARRANTIES AS TO
          TITLE, MERCHANTABILITY OR FITNESS FOR PURPOSE. WE DO NOT REPRESENT OR WARRANT THAT ANY NFT
          OR ASSOCIATED LINKED DIGITAL ASSET IS FREE OF CLAIMS OR OWNED BY THE APPLICABLE SELLER.
          NOR DO WE REPRESENT OR WARRANT THAT THE APPLICABLE SELLER HAS THE RIGHT TO SELL THE NFT,
          NOR THAT THE SITE IS RELIABLE, ERROR-FREE, FREE OF DEFECTS, WILL MEET YOUR NEED OR THAT
          ANY DEFECTS CAN OR WILL BE CORRECTED. YOU ACKNOWLEDGE AND AGREE THAT WE DO NOT GRANT ANY
          COPYRIGHTS OR INTELLECTUAL PROPERTY RIGHTS TO YOU IN THE NFT AND THE LINKED DIGITAL ASSET.
          RATHER, ALL RIGHTS IN AND TO THE NFT AND LINKED DIGITAL ASSET THAT YOU ARE RECEIVING ARE
          PROVIDED BY THE APPLICABLE SELLER WHO HAS AGREED TO SECTION 1 ABOVE AS PART OF THESE TERMS
          OF SALE.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Risks.</Text> You understand and agree that
          that there are risks related to purchasing and owning or selling NFTs. You expressly
          acknowledge and assume all risks for the purchase by you of any NFTs.
        </TextParagraph>
        <TextParagraph>
          You represent that you have an understanding of the functionality, usage, storage,
          transmission mechanisms of token storage mechanisms, block chain technology and
          blockchain-based software sufficient for you to understand the nature and risk associated
          with these transactions and the preservation of NFTs.
        </TextParagraph>
        <TextParagraph>
          You further understand that an NFT can be destroyed after you purchase it (“Burning”) and
          cancelled from the blockchain. You agree that we have no liability to you if an NFT is
          Burned or otherwise inaccessible or not resalable.{' '}
        </TextParagraph>
        <Label bold>3. TRANSACTION AND AUCTION TERMS</Label>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Costs of Using the Site.</Text> The Site is
          currently free to use for all users. However, all transaction fees associated with your
          blockchain interactions (e.g., “gas fees”) are paid by you. On all successful sales of
          NFTs, we take a 10% commission (unless otherwise stated at the time of sale), such
          commission to be deducted from the sale proceeds.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Types of Sales. </Text>Sales of NFTs via the
          Site may be offered at a fixed price or done via auction (subject to certain auction terms
          applicable to such sale). Sales may be subject to minimum sales prices and subject to
          minimum bid amounts. Certain sales may provide for payment in fiat currency, Ether, and/or
          both. NFTs offered on the Site are offered subject to availability.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Prices.</Text> All prices posted on the Site,
          auction terms, and/or the cancellation of any auction, are subject to change without
          notice. We strive to display accurate price information; however, we may, on occasion,
          make inadvertent typographical errors, inaccuracies, or omissions related to pricing and
          availability. We reserve the right to correct any errors, inaccuracies, or omissions at
          any time, and to cancel any bids and/or purchases arising from such occurrences or
          otherwise.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Deposits.</Text> We may require you to submit
          deposits (either to our fiat or Ether account, as applicable) in connection with your bids
          and/or purchases on the Site. In the event of an unsuccessful bid and/or purchase, such
          deposit shall be returned to you. In the event of a successful bid and/or purchase, such
          deposit shall be credited against the purchase price of the applicable NFT.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Payment Terms.</Text> Terms of payment are
          within our sole discretion. Bids and purchases can be made via credit card or using Ether
          via the MetaMask digital wallet. By entering into any transaction on the Site, you
          represent, warrant and covenant that all information you provide is true, correct, and
          complete (including, without limitation, your credit card information, billing address
          and/or wallet address); that any transactions submitted by you are authorized; that
          charges incurred by you will be honored by your credit card company or by your wallet
          address; that you will pay charges incurred by you at the posted prices, including all
          applicable taxes and blockchain transaction fees, if any, regardless of the amount quoted
          on the Site at the time of your purchase; and that you are the legal holder of any credit
          card or payment account or digital wallet used to enter into any transaction on the Site.
        </TextParagraph>
        <TextParagraph>
          If, in our sole discretion, we determine that: (i) your means of payment is not valid,
          (ii) a transaction is not authorized, (iii) your means of payment cannot be processed or
          verified at the time of any charge, (iv) you have otherwise used the Site to enter into an
          improper transaction, or (v) you have not provided appropriate POP or financial disclosure
          documentation, we reserve the right to immediately terminate any pending transactions,
          suspend your access to the Site, and terminate all of our obligations hereunder.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>All Sales are Final.</Text>
          <Text sx={{ fontWeight: '700' }}> ALL SALES ARE FINAL.</Text>
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Payment Processing.</Text> You acknowledge that
          the payment processing services for an NFT purchased on the Site are provided by
          third-party payment processors or digital wallets such as [MetaMask]. We have no
          responsibility or liability for such third-party payment processors or digital wallets and
          any applicable complaints, claims, concerns, or questions should be directed to the
          applicable payment processor or digital wallet.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Unclaimed Property.</Text> You acknowledge that
          the payment processing services for an NFT purchased on the Site are provided by
          third-party payment processors or digital wallets such as [MetaMask]. We have no
          responsibility or liability for such third-party payment processors or digital wallets and
          any applicable complaints, claims, concerns, or questions should be directed to the
          applicable payment processor or digital wallet.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Disclaimers.</Text> You acknowledge and agree
          that we are a passive conduit providing a platform for third parties to sell and buy NFTs.
          We do not represent either the seller or the buyer in any transaction and are not
          responsible for your actions or the seller’s or buyer’s actions. We are not responsible
          for any misconduct by the seller or buyer, including, without limitation, non-payment,
          breach of representations or warranties, licenses and/or copyright infringement. Any
          disputes between the buyer and seller shall be their sole responsibility to resolve and
          they shall not make us a party to any dispute. If we are named as a party to any lawsuit
          or arbitration in connection with any such dispute, the party naming us a party shall be
          responsible for reimbursement of all of our internal and external costs, damages and
          expenses (including attorneys’ fees) arising from or related to such naming. You agree we
          are not liable to you for any damages, cost or expenses resulting from any disputes
          between the buyer and the seller.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Taxes.</Text> You agree that you are solely
          responsible for determining what, if any, taxes apply to your sale/purchase transactions
          and to withhold, collect, report and remit the correct amounts of taxes to the appropriate
          taxing authorities (i.e. sales/use tax, VAT, duties, tariffs, etc.). The use of crypto
          currencies in the purchase of an NFT may trigger income tax obligations for you. If there
          are any, their nature, payment and liabilities are yours alone to determine and pay.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Termination.</Text> We have the right to remove
          any material posted to our Site and to terminate any sale or auction in our sole
          discretion for any reason, including, without limitation, if we believe it is
          questionable, have concerns as to the authenticity of the NFT or your right to sell the
          NFT, or if the sale of the NFT may be illegal, in violation of any federal, state, local
          or governmental law or regulation, can lead to potential legal liability for us, you or
          the seller and/or buyer, or if we feel that providing a platform for the NFT’s sale may in
          any way be detrimental to us or our reputation. You agree we are not liable to you for any
          damages, cost or expenses resulting from the cancelation of the sale of the NFT for any
          reason.
        </TextParagraph>
        <TextParagraph>
          <Text sx={{ textDecoration: 'underline' }}>Special Remedies.</Text> Subject to any
          technical limitations, in the event that we determine:
        </TextParagraph>
        <TextParagraph>
          <li>
            that an NFT was transferred or issued incorrectly, we will use commercially reasonable
            efforts to cancel the incorrectly transferred NFT and mint a new one to be issued to the
            proper transferee;
          </li>
          <li>
            hat an auction was incorrectly run, we will use commercially reasonable efforts to rerun
            the auction; or
          </li>
          <li>
            that royalties have been incorrectly calculated, we will use commercially reasonable
            efforts to recalculate the royalties.
          </li>
        </TextParagraph>
        <TextParagraph>
          You shall not seek to hold us liable for any failure to complete any of the above special
          remedies and we shall not be liable in all cases be subject to the rights and protections
          afforded to us under these Terms of Sale.{' '}
        </TextParagraph>
        <Label bold>4. QUESTIONS</Label>
        <TextParagraph>
          If you have any questions or comments regarding these Terms of Sale, please feel free to
          contact us by e-mail at nft@nomadblvd.io.
        </TextParagraph>
      </Container>
      <Wrapper>
        <FooterSection />
      </Wrapper>
    </>
  )
}
export { SaleTerms }
// {
//   /* <Label bold>3. TRANSACTION AND AUCTION TERMS</Label> */
// }
