import { useEffect } from 'react'
import { Flex, Grid, Image, Text } from 'theme-ui'
import { CollectButton, NomadBox, ProfileButton, TokenVideo } from '../Exchange.styles'
import { TokenProps } from '../../../components'
import { NFT_URL, FOUNDER_URL } from '../../../utils'
import { useHistory } from 'react-router-dom'
// import axios from 'axios'
//import Default from '../../../assets/nomad/default.png'

const MyNomads = ({ setFullH, nomadId, user }: any) => {
  useEffect(() => setFullH(true), [setFullH])
  const history = useHistory()
  // const [imagesMap, setImagesMap] = useState(new Map())
  // const [namesMap, setNamesMap] = useState(new Map())
  // const getImages = useCallback(async () => {
  //   if (tokens) {
  //     tokens?.forEach(async function ({ uri }: TokenProps) {
  //       const res = await axios.get(`${uri}`)

  //       if (res && res.data && res.status === 200) {
  //         setImagesMap(new Map(imagesMap.set(`${res.data.id}`, res.data.image)))
  //         setNamesMap(new Map(namesMap.set(`${res.data.id}`, res.data.name)))
  //       }
  //     })
  //   }
  // }, [tokens, imagesMap, namesMap])

  // useEffect(() => {
  //   getImages()
  // }, [getImages])

  return (
    <NomadBox>
      <Text variant="passTitle" sx={{ display: 'block', textAlign: 'center', color: '#fff' }}>
      WOO! say hello to your nomads!
      </Text>

      <ProfileButton onClick={() => history.push('/profile')}>
        Go to profile
      </ProfileButton>

      <Grid gap={[3, 8]} columns={[2, 3]} sx={{ marginTop: ['2rem'] }}>
        {user?.ownedTokens &&
          user?.ownedTokens.map(({ id, src, label }: TokenProps) => {
            return (
              <Flex key={id} sx={{ flexDirection: 'column', maxWidth: ['11rem', '19rem'], minHeight: ['23rem', '25rem', '27rem', '35rem'], marginTop: ['1rem', 0] }}>
                <Image src={src + '?r=' + Date.now()} />
                <Text
                  variant="passTitle"
                  sx={{
                    fontSize: ['1rem', '1.25rem'],
                    marginTop: '1.375rem',
                    marginBottom: 0,
                    color: '#fff',
                  }}
                >
                  {label}
                </Text>
                <CollectButton
                  onClick={() => window.open(`${NFT_URL}${id}`, '_blank')}
                  sx={{ fontSize: ['0.75rem!important', '1.125rem!important'], margin: 'auto 0 0!important' }}
                >
                  view on opensea
                </CollectButton>
              </Flex>
            )
          })}
      </Grid>

      {user && user.myFounderTokens > 0 && (
        <>
          <Text variant="passTitle" sx={{ display: 'block', textAlign: 'center', color: '#fff', margin: '6rem 2rem' }}>
            YOU ALSO GOT SOMETHING SPECIAL!
          </Text>

          <TokenVideo>
            <video autoPlay playsInline muted loop src="https://nomad.mypinata.cloud/ipfs/QmVPQdbzCdekag411ZJrKHJDX1t18VsCLyQRk2sD3H7Nja"></video>
            <Text
              variant="passTitle"
              sx={{
                fontSize: ['1rem', '1.25rem'],
                marginTop: '1.375rem',
                marginBottom: 0,
                color: '#fff',
              }}
            >
              Founder Token
            </Text>
            <Text
              variant="passTitle"
              sx={{
                fontSize: ['.75rem', '1rem'],
                marginTop: '1rem',
                color: '#fff',
                display: 'block',
              }}
            >
              x{user.myFounderTokens}
            </Text>

            <CollectButton
                  onClick={() => window.open(FOUNDER_URL, '_blank')}
                  sx={{ fontSize: ['0.75rem!important', '1.125rem!important'], margin: 'auto 0 0!important' }}
                >
                  view on opensea
                </CollectButton>
          </TokenVideo>
        </>
      )}

      {/* <Text variant="passTitle" sx={{ display: 'block', textAlign: 'center', color: '#fff', marginTop:'5rem'}}>
        YOU ALSO GOT SOMETHING SPECIAL
      </Text>
      <Grid gap={[2, 8]} columns={[2, 3]} sx={{ marginTop: ['2rem'] }}>
        {tokens &&
          tokens.map(({ id, src, label }: TokenProps) => {
            console.log(src)
            return (
              <Flex key={id} sx={{ flexDirection: 'column', maxWidth: ['11rem', '19rem'] }}>
                <Image src={src} />
                <Text
                  variant="passTitle"
                  sx={{
                    fontSize: ['1.125rem', '1.5rem'],
                    marginTop: '1.375rem',
                    marginBottom: 0,
                    color: '#fff',
                  }}
                >
                  {label}
                </Text>
                <CollectButton sx={{ fontSize: ['0.75rem!important', '1.125rem!important'] }}>
                  view on opensea
                </CollectButton>
              </Flex>
            )
          })}
      </Grid> */}
    </NomadBox>
  )
}
export { MyNomads }
