import { Box, Flex, Image, Paragraph, Text } from '@theme-ui/components'
import styled from 'styled-components'
import TheNomads from '../../../assets/nomad/TheNomads/nomads.png'
import { Button } from '../../common'

const Container = styled.section`
  width: 100%;
  background-color: #c9d5d3;
  padding: 10rem;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 64em) {
    padding: 10rem 5% 5rem;
    height: 100%;
    overflow-x: clip;
    z-index: 10;
  }
`
const ImageBox = styled(Box)`
  display: none;
  @media screen and (max-width: 64em) {
    display: flex;
  }
`
// function checkVisible(elm: any) {
//   var rect = elm.getBoundingClientRect()
//   var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
//   return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
// }

const Collection = () => {
  // const [isOpen, setIsOpen] = useState(false)
  // const givingBackRef = useRef<HTMLDivElement>(null)
  // let isGivingBackVisible = useOnScreen(givingBackRef, '0px', 0.11)

  // useEffect(() => {
  //   const colorChange = document.querySelectorAll<HTMLElement>('.color-change')
  //   const getTeam = document.getElementById('TEAM')

  //   if (isGivingBackVisible) {
  //     colorChange.forEach(function (el) {
  //       el.classList.remove('black')
  //     })
  //   }
  //   if (!checkVisible(getTeam)) {
  //     colorChange.forEach(function (el) {
  //       el.classList.add('black')
  //     })
  //   }
  // }, [isGivingBackVisible])

  return (
    <Container id="COLLECTION">
      <ImageBox
        sx={{
          position: 'absolute',
          top: '-15rem',
          transform: 'scale(1.5)',
        }}
      >
        <Image sx={{ maxWidth: '45rem' }} src={TheNomads} />
      </ImageBox>
      <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
        <Flex sx={{ flexDirection: 'column' }}>
          <Text variant="blvd" sx={{ fontFamily: 'Brice H3', marginBottom: '2rem' }}>
            THE COLLECTION
          </Text>
          <Paragraph
            variant="block"
            sx={{ marginBottom: ['3rem'], marginTop: ['1rem', '2rem'], display: 'block' }}
          >
            Each of the 3,500 Nomads is unique and programmatically generated from 110 hand-painted
            elements, including color, eyes, clothes, hats, eyewear, backgrounds, foregrounds and
            more. Unlike typical generative NFT projects, each Nomad has been carefully customized
            and curated for quality, rarity, and vibe. Nomads have their special quirks, but some
            have rarer traits than others. The NFTs, including the 150 collab artist editions, will
            be randomly assigned and live on the Ethereum Blockchain.
          </Paragraph>
          <Button
            primary
            label="JOIN OUR DISCORD"
            onClick={() => window.open('https://discord.gg/3aBDQUjcgZ', '_blank')}
            backgroundColor="#000"
            color={'#F6F7F1'}
          />
        </Flex>
      </div>
    </Container>
  )
}
export default Collection
