import FOUNDER from '../../assets/nomad/Exchange/founderPass.png'
import COLLECTOR from '../../assets/nomad/Exchange/collectorPass.png'

export const blackVideoURL =
  'https://gateway.pinata.cloud/ipfs/QmVdejSdcPGj4zESGXnrNiE4nG5ikDHKPDzQgBz1pvECrf'
export const blackPassportName = 'Nomad BLVD | Founder Pass'

export const golderPassportName = 'Nomad BLVD | Collectors Pass'
export const goldenVideoURL =
  'https://gateway.pinata.cloud/ipfs/QmdSVv5ocayXGk5mnpPQPAu4NMUCtcMqc7Bdo7GxME7PaB'

export const ON_ERROR_IMAGES: string | any = {
  founder: FOUNDER,
  collector: COLLECTOR,
  black: FOUNDER,
  gold: COLLECTOR,
}
